import React, { useEffect, useMemo, useState } from "react";
import {
  Header,
  TabBar,
  ITabItem,
  Select,
  Card,
  InputText,
  Notifications,
  Icon,
} from "d-react-components";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { FormikProps, useFormik } from "formik";
import { filter, find, isEmpty, isNil, isString, omit, omitBy } from "lodash";
import AdminAuthentication from "./content/UserAuthentication";
import "../../../styles/components/tab.scss";
import {
  useCreateUserByAdminMutation,
  useUpdateUserByAdminMutation,
} from "../../../network/hooks";
import { Path } from "../../common/layout/Paths";
import { IAdminInput } from "../../../interfaces/common";
import { AdminStatus, AdminStatuses } from "../../../constants/admin";
import { mapFileFromServerToClient } from "../../../interfaces/upload";

interface AdminFormProps {
  adminForm?: FormikProps<IAdminInput>;
}

const PersonalInfoTab = ({ adminForm }: AdminFormProps) => {
  const { t } = useTranslation();
  const { values, errors, setFieldValue } = adminForm || {};

  return (
    <Card customHeader={<></>}>
      <div className="grid grid-cols-2 gap-4">
        {/* <div className="col-span-2">
          <AvatarUpload
            onChange={(v) => {
              setFieldValue && setFieldValue("avatar", v);
            }}
            value={values?.avatar}
          />
        </div> */}
        <div className="col-span-1">
          <InputText
            label={t("customer:userName")}
            value={values?.userName}
            error={errors?.userName}
            onChange={(e) =>
              setFieldValue && setFieldValue("userName", e.target.value)
            }
          />
        </div>
        <div className="col-span-1">
          <InputText
            label={t("customer:companyId")}
            value={values?.companyId}
            error={errors?.companyId}
            onChange={(e) =>
              setFieldValue && setFieldValue("companyId", e.target.value)
            }
          />
        </div>
        <div className="col-span-1">
          <InputText
            label={t("customer:fullName")}
            value={values?.fullName}
            error={errors?.fullName}
            onChange={(e) =>
              setFieldValue && setFieldValue("fullName", e.target.value)
            }
          />
        </div>
        <div className="col-span-1">
          <InputText
            label={t("customer:nickName")}
            value={values?.nickName}
            error={errors?.nickName}
            onChange={(e) =>
              setFieldValue && setFieldValue("nickName", e.target.value)
            }
          />
        </div>
      </div>
    </Card>
  );
};

const AdminPassword: React.FC<AdminFormProps> = ({ adminForm }) => {
  const { t } = useTranslation();
  const { values, errors, setFieldValue, handleChange } = adminForm || {};
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  return (
    <Card customHeader={<></>}>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1">
          <InputText
            label={t("customer:newPassword")}
            name="password"
            onChange={handleChange}
            value={values?.password || ""}
            error={errors?.password}
            className="w-100 mr-3 hover-pointer"
            type={showPassword ? "text" : "password"}
            suffix={
              <Icon
                name={showPassword ? "visibility_off" : "visibility"}
                onClick={() => setShowPassword(!showPassword)}
              />
            }
          />
        </div>
        <div className="col-span-1">
          <InputText
            label={t("customer:passwordConfirm")}
            name="passwordConfirm"
            onChange={handleChange}
            value={values?.passwordConfirm || ""}
            error={errors?.passwordConfirm}
            className="w-100 mr-3 hover-pointer"
            type={showPasswordConfirm ? "text" : "password"}
            suffix={
              <Icon
                name={showPassword ? "visibility_off" : "visibility"}
                onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
              />
            }
          />
        </div>
      </div>
    </Card>
  );
};

const AdminSettings: React.FC<AdminFormProps> = ({ adminForm }) => {
  const { t } = useTranslation();
  const { values, errors, setFieldValue, handleChange } = adminForm || {};

  return (
    <Card customHeader={<></>}>
      <div className="grid grid-cols-1 gap-4">
        <div className="col-span-1">
          <Select
            dataSource={AdminStatuses}
            // i18next-extract-disable-next-line
            getLabel={(item) => t(`dynamic:${item.label}`)}
            label={t("user:accountAccess")}
            value={values?.accountAccess}
            error={errors?.accountAccess}
            onChange={(val) => {
              setFieldValue && setFieldValue("accountAccess", val);
            }}
          />
        </div>
      </div>
    </Card>
  );
};

export const UserCreate: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<any>();
  const [createUser] = useCreateUserByAdminMutation();
  const [updateUser] = useUpdateUserByAdminMutation();
  const customerId = null;

  const CustomerSchema = useMemo(
    () =>
      Yup.object().shape({
        userName: Yup.string().required(t(`common:fieldRequired`)),
        fullName: Yup.string().required(t(`common:fieldRequired`)),
        nickName: Yup.string().required(t(`common:fieldRequired`)),
        companyId: Yup.string().required(t(`common:fieldRequired`)),
        password: Yup.string().min(6).required(t(`common:fieldRequired`)),
        passwordConfirm: Yup.string()
          .oneOf([Yup.ref("password"), null], t(`common:passwordMustMatch`))
          .required(t(`common:fieldRequired`)),
        accountAccess: Yup.mixed(),
      }),
    [t]
  );
  const adminForm = useFormik<IAdminInput>({
    initialValues: {
      accountAccess: AdminStatuses[0],
    },
    validationSchema: CustomerSchema,
    onSubmit: () => {
      const payload = {
        ...omit(values, "passwordConfirm"),
        ...(values?.avatar
          ? {
              avatar: isString(values?.avatar)
                ? values?.avatar
                : values?.avatar?.["key"],
            }
          : {}),
        accountAccess: values?.accountAccess?.id === AdminStatus.ENABLED,
      };
      (customerId
        ? updateUser({
            variables: {
              input: payload as any,
            },
          })
        : createUser({
            variables: {
              input: payload as any,
            },
          })
      )
        .then(() => {
          Notifications.showSuccess(
            customerId
              ? t("notification:updateUserSuccessful")
              : t("notification:createUserSuccessful")
          );
          navigate(Path.USERS);
        })
        .catch((err) => {
          Notifications.showError(err);
        });
    },
    enableReinitialize: true,
  });
  const { values, setValues, errors, handleSubmit, setFieldValue } = adminForm;

  const USER_DETAIL_TAB: ITabItem[] = useMemo(
    () => [
      {
        id: "personalInformation",
        label: t("customer:personalInformation") as string,
        component: <PersonalInfoTab adminForm={adminForm} />,
      },
      {
        id: "residencyInformation",
        label: t("user:pasword") as string,
        component: <AdminPassword adminForm={adminForm} />,
      },
      {
        id: "adminSettings",
        label: t("user:settings") as string,
        component: <AdminSettings adminForm={adminForm} />,
      },
      {
        id: "authentication",
        label: t("user:authentication") as string,
        component: <AdminAuthentication adminForm={adminForm} />,
      },
    ],
    [t, adminForm]
  );
  const [selectedTab, setSelectedTab] = useState<ITabItem>(USER_DETAIL_TAB[0]);

  return (
    <>
      <Header
        className="py-3"
        title={t(`user:newUser`)}
        onSave={() => handleSubmit()}
        cancelText={t(`common:back`)}
        saveText={t(`common:save`)}
        onCancel={() => navigate(-1)}
      />
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-1">
          <TabBar
            dataSource={USER_DETAIL_TAB}
            onChange={(tab) => setSelectedTab(tab)}
            value={selectedTab}
            className="overflow-x-scroll my-4 pb-1 flex-column form-tabs"
            // i18next-extract-disable-next-line
            getLabel={(item) => item.label || "N/A"}
            isScroll
            hideScrollBar={false}
            minWidthItem={"100%"}
            classNameItem="justify-content-start w-100 border-b border-solid border-gray-500 text-gray-500"
          />
        </div>
        <div className="col-span-3 py-4">
          {find(USER_DETAIL_TAB, { id: selectedTab.id })?.["component"]}
        </div>
      </div>
    </>
  );
};

export default UserCreate;

import { Button, InputText, Notifications, Select } from "d-react-components";
import { useFormik } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { AdminStatus, AdminStatuses } from "../../../../constants/admin";
import { IAdminInput } from "../../../../interfaces/common";
import { useUpdateUserByAdminMutation } from "../../../../network/hooks";
import { userUserInfo } from "../UserDetail";

export const UserUpdateProfile = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const [{ user, loading }] = userUserInfo();
  const [updateUser] = useUpdateUserByAdminMutation();

  const FormSchema = useMemo(
    () =>
      Yup.object().shape({
        userName: Yup.string().required(t(`common:fieldRequired`)),
        fullName: Yup.string().required(t(`common:fieldRequired`)),
        nickName: Yup.string().required(t(`common:fieldRequired`)).nullable(),
        companyId: Yup.string().required(t(`common:fieldRequired`)).nullable(),
      }),
    [t]
  );

  const { userName, nickName, companyId, accountAccess, fullName } =
    user || {};
  const profileForm = useFormik<IAdminInput>({
    initialValues: {
      userName,
      nickName,
      companyId,
      accountAccess: accountAccess ? AdminStatuses?.[0]?.id : AdminStatuses?.[1]?.id,
      fullName,
      // employeeId,
    },
    validationSchema: FormSchema,
    onSubmit: async () => {
      try {
        updateUser({
          variables: {
            input: {
              id: user?.id as string,
              userName: values.userName,
              nickName: values.nickName,
              fullName: values.fullName,
              companyId: values.companyId,
              accountAccess: values?.accountAccess === AdminStatus.ENABLED,
              // employeeId: values?.employeeId,
            },
          },
        }).then(
          (resp) => {
            Notifications.showSuccess(
              t("notification:userProfileUpdatedSuccessfully")
            );
            onClose && onClose();
          },
          (err) => {
            Notifications.showError(err);
          }
        );
      } catch (err) {
        Notifications.showError(err);
      }
    },
    enableReinitialize: false,
    validateOnChange: false,
  });
  const { values, errors, handleSubmit, setFieldValue } = profileForm;

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1">
          <InputText
            label={t("customer:userName")}
            value={values?.userName}
            error={errors?.userName}
            onChange={(e) =>
              setFieldValue && setFieldValue("userName", e.target.value)
            }
          />
        </div>
        <div className="col-span-1">
          <InputText
            label={t("customer:companyId")}
            value={values?.companyId}
            error={errors?.companyId}
            onChange={(e) =>
              setFieldValue && setFieldValue("companyId", e.target.value)
            }
          />
        </div>
        <div className="col-span-1">
          <InputText
            label={t("customer:fullName")}
            value={values?.fullName}
            error={errors?.fullName}
            onChange={(e) =>
              setFieldValue && setFieldValue("fullName", e.target.value)
            }
          />
        </div>
        <div className="col-span-1">
          <InputText
            label={t("customer:nickName")}
            value={values?.nickName}
            error={errors?.nickName}
            onChange={(e) =>
              setFieldValue && setFieldValue("nickName", e.target.value)
            }
          />
        </div>
        <div className="col-span-1">
          <Select
            dataSource={AdminStatuses}
            // i18next-extract-disable-next-line
            getLabel={(item) => t(`dynamic:${item.label}`)}
            label={t("user:accountAccess")}
            value={values?.accountAccess}
            error={errors?.accountAccess}
            onChange={(val) => {
              setFieldValue && setFieldValue("accountAccess", val);
            }}
          />
        </div>
        {/* <div className="col-span-1">
          <InputText
            label={t("user:employeeId")}
            value={values?.employeeId}
            error={errors?.employeeId}
            onChange={(e) =>
              setFieldValue && setFieldValue("employeeId", e.target.value)
            }
          />
        </div> */}
      </div>
      <div>
        <div className="flex align-items-end justify-content-end">
          <Button
            title={t("common:submit")}
            className="w-full mb-2 text-sm"
            onClick={() => handleSubmit()}
            color="primary"
          >
            {t("common:send")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default UserUpdateProfile;

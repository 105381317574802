import {
  AwesomeTableComponent,
  Button,
  Drawer,
  IColumnsProps,
  Icon,
  InputTextSearch,
  Notifications,
  Progress,
  TabBar,
  TimeUtils,
} from "d-react-components";
import {
  Product,
  ProductPerformanceByWarehouseItem,
  ProductPerformanceItem,
} from "../../interfaces/report";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Switch, Table } from "antd";
import { chain, filter, isNil, isUndefined, omitBy, sumBy } from "lodash";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import {
  PhoneStatus,
  useDetailReportProductPerformanceLazyQuery,
  useDetailReportProductPerformanceOrderLazyQuery,
  useExportReportProductPerformanceLazyQuery,
  useFindProductLazyQuery,
  useReportProductPerformanceLazyQuery,
  useSummaryReportProductPerformanceLazyQuery,
} from "../../network/hooks";

import AppContext from "../common/AppContext/ReportContext";
import { Channel } from "../../interfaces/common";
import { PRODUCT_LAUNCH_AT } from "../../constants/order";
import { REPORT_TYPE } from "../../constants/report";
import ReportContext from "./ReportContext";
import ReportHeader from "../common/report/header/ReportHeader";
import {
  ADMIN_AUTH_KEY,
  SORT_DIRECTION,
  VAT_DIVIDER,
} from "../../constants/common";
import TableSelectAll from "../common/TableSelectAll";
import TableSummary from "../common/Table/TableSummary";
import { convertToLocalTime } from "../../utils/time";
import dayjs from "dayjs";
import { exportToXLS } from "../../utils/file";
import { loadAllPaginatedData } from "../../utils/promises";
import { formatFloatNumber, moneyFormatter } from "../../utils/money";
import { useTranslation } from "react-i18next";
import CurrencyFormat from "react-currency-format";
import { VerifyOTPDrawer } from "../common/VerifyOTPDrawer";
import { useLocalStorage } from "react-use";
import { isGrantedSensitiveData } from "../common/PermissibleComponent";
import { VIEW_REPORT_PRODUCT_PERFORMANCE_PRODUCTION_COST } from "../../interfaces/admin";
import { styled } from "styled-components";

const orderUrl = "https://salesinfinityx.com/admin/short-url/order/";

const calNetProfitPercentage = (item: any) => {
  return (1 - item.productionCost / (item.salePriceNet / VAT_DIVIDER)) * 100;
};

const ProductPerformaceContent = () => {
  const { t } = useTranslation();
  const tableRef = useRef<any>(null);
  const [sortBy, setSortBy] = useState<any>();
  const [getReport] = useReportProductPerformanceLazyQuery();
  const [getReportSummary] = useSummaryReportProductPerformanceLazyQuery();
  const [exportReport, { data, loading, refetch }] =
    useExportReportProductPerformanceLazyQuery();
  const { filterData, channelList, setFilterData } = useContext(AppContext);
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
  const [comprehensiveMode, setComprehensiveMode] = useState(false);
  const [showProductDrawer, setShowProductDrawer] = useState(false);
  const [summaryData, setSummaryData] = useState<any>();
  const [showVerifyDrawer, setShowVerifyDrawer] = useState(false);
  const [adminInfo] = useLocalStorage<any>(ADMIN_AUTH_KEY, {});
  const [viewSensitiveDataVerified, setViewSensitiveDataVerified] = useState(
    adminInfo?.hasVerifyOtp
  );

  const getLaunchAtDate = (launchAt: any) => {
    let monthToSubtract = 0;
    switch (launchAt) {
      case PRODUCT_LAUNCH_AT.LAST_3_MONTHS:
        monthToSubtract = 3;
        break;
      case PRODUCT_LAUNCH_AT.LAST_6_MONTHS:
        monthToSubtract = 6;
        break;
      case PRODUCT_LAUNCH_AT.LAST_9_MONTHS:
        monthToSubtract = 9;
        break;
      case PRODUCT_LAUNCH_AT.LAST_12_MONTHS:
        monthToSubtract = 12;
        break;
    }
    return dayjs().subtract(monthToSubtract, "months").toDate();
  };

  const getPayload = () => {
    return {
      orderSource: filterData?.orderSource,
      channels: filterData?.channels?.length
        ? filterData.channels
        : channelList.map((channel: Channel) => channel.refId),
      start: filterData?.currentRange?.[0].startOf("d").toDate(),
      end: filterData?.currentRange?.[1].endOf("d").toDate(),
      store: filterData.warehouses?.map((item: any) => item.refId),
      category: filterData.categories?.map((item: any) => item.refId),
      brand: filterData.brands?.map((item: any) => item.refId),
      focType: filterData.focType,
      filterProductType: filterData.filterProductType,
      products: filterData?.products
        ? filterData?.products?.map((item: Product) => item.refId)
        : [],
      ...(filterData.launchAt
        ? { launchAt: getLaunchAtDate(filterData.launchAt) }
        : {}),
    };
  };

  const loadTableData = (paging: any, sorter: any) => {
    if (!filterData?.orderSource?.length) {
      return Promise.resolve([]);
    }
    setSortBy(sorter);
    const payload = getPayload();
    return getReport({
      variables: {
        args: {
          ...omitBy(payload, isNil),
          page: paging.pageIndex,
          limit: paging.pageSize,
          ...(sorter?.order
            ? {
                sort: {
                  sortBy: sorter.field,
                  sortDirection: sorter.order === SORT_DIRECTION.ASC ? 1 : -1,
                },
              }
            : {
                sort: {
                  sortBy: "quantity",
                  sortDirection: -1,
                },
              }),
        },
      },
    });
  };

  const onChangeFilter = () => {
    loadSummaryData();
    return tableRef.current && tableRef.current.refresh();
  };

  const loadSummaryData = () => {
    if (!filterData?.orderSource?.length) {
      return Promise.resolve({});
    }
    const payload = getPayload();
    getReportSummary({
      variables: {
        args: {
          ...omitBy(payload, isNil),
        },
      },
    }).then((resp) => {
      setSummaryData({
        ...resp?.data?.summaryReportProductPerformance,
        index: t("sum"),
      });
    });
  };

  const tableColumns: IColumnsProps = useMemo(
    () => [
      {
        title: t("report:no"),
        dataIndex: "index",
        align: "left",
        width: 50,
        render: (value, item, index) => {
          return isUndefined(index) ? value : index + 1;
        },
      },
      {
        title: t("itemName"),
        dataIndex: "productName",
        align: "left",
        render: (value, item) => {
          return item?.product?.name;
        },
        sorter: true,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
      },
      {
        title: t("sku"),
        dataIndex: "product",
        align: "left",
        width: 150,
        render: (value, item) => {
          return item?.product?.sku;
        },
      },
      {
        title: t("saleQuantity"),
        dataIndex: "quantity",
        align: "left",
        width: 100,
        sorter: true,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        defaultSortOrder: SORT_DIRECTION.DESC,
        render: (value, item) => {
          return !isUndefined(value) ? moneyFormatter.format(value || 0) : "";
        },
      },
      {
        title: t("totalSalesAmount"),
        dataIndex: "salePrice",
        align: "left",
        width: 140,
        render: (value, item) => {
          return (
            <CurrencyFormat
              thousandSeparator
              displayType="text"
              decimalScale={0}
              value={value || 0}
            />
          );
        },
        sorter: true,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
      },
      {
        title: t("totalSalesAmountNet"),
        dataIndex: "salePriceNet",
        align: "left",
        width: 140,
        render: (value) => {
          return (
            <CurrencyFormat
              thousandSeparator
              displayType="text"
              decimalScale={0}
              value={value || 0}
            />
          );
        },
        sorter: true,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
      },
      {
        title: t("totalSalesAmountNetExclVAT"),
        dataIndex: "salePriceNet",
        align: "left",
        width: 140,
        render: (value) => {
          return (
            <CurrencyFormat
              thousandSeparator
              displayType="text"
              decimalScale={0}
              value={value ? value / VAT_DIVIDER : 0}
            />
          );
        },
        sorter: true,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
      },
      {
        title: t("revPerUnit"),
        dataIndex: "revPerUnit",
        align: "left",
        width: 140,
        render: (value) => {
          return (
            <CurrencyFormat
              thousandSeparator
              displayType="text"
              decimalScale={0}
              value={value || 0}
            />
          );
        },
        sorter: true,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
      },
      ...(isGrantedSensitiveData(
        VIEW_REPORT_PRODUCT_PERFORMANCE_PRODUCTION_COST
      )
        ? [
            {
              title: t("productionCost"),
              dataIndex: "productionCost",
              width: 150,
              minWidth: 150,
              sorter: true,
              sortDirections: [
                SORT_DIRECTION.ASC,
                SORT_DIRECTION.DESC,
                SORT_DIRECTION.ASC,
              ],
              render: (data: any) =>
                viewSensitiveDataVerified && !isUndefined(data) ? (
                  <CurrencyFormat
                    thousandSeparator
                    displayType="text"
                    value={data || 0}
                    decimalScale={0}
                  />
                ) : (
                  ""
                ),
            },
            // {
            //   title: t("saleExpense"),
            //   width: 90,
            //   minWidth: 90,
            //   dataIndex: "saleExpense",
            //   sorter: true,
            //   sortDirections: [
            //     SORT_DIRECTION.ASC,
            //     SORT_DIRECTION.DESC,
            //     SORT_DIRECTION.ASC,
            //   ],
            //   render: (data: any) =>
            //     viewSensitiveDataVerified && !isUndefined(data) ? (
            //       <CurrencyFormat
            //         thousandSeparator
            //         displayType="text"
            //         decimalScale={0}
            //         value={data || 0}
            //       />
            //     ) : (
            //       ""
            //     ),
            // },
            // {
            //   title: t("adminExpense"),
            //   width: 90,
            //   minWidth: 90,
            //   dataIndex: "adminExpense",
            //   sorter: true,
            //   sortDirections: [
            //     SORT_DIRECTION.ASC,
            //     SORT_DIRECTION.DESC,
            //     SORT_DIRECTION.ASC,
            //   ],
            //   render: (data: any) =>
            //     viewSensitiveDataVerified && !isUndefined(data) ? (
            //       <CurrencyFormat
            //         thousandSeparator
            //         displayType="text"
            //         decimalScale={0}
            //         value={data || 0}
            //       />
            //     ) : (
            //       ""
            //     ),
            // },
            {
              title: t("cm"),
              dataIndex: "netProfit",
              width: 150,
              minWidth: 150,
              sorter: true,
              sortDirections: [
                SORT_DIRECTION.ASC,
                SORT_DIRECTION.DESC,
                SORT_DIRECTION.ASC,
              ],
              render: (data: any, item: any) => {
                const value = data;
                const percentage = calNetProfitPercentage(item);
                return viewSensitiveDataVerified &&
                  !isUndefined(item.productionCost) ? (
                  <>
                    <CurrencyFormat
                      thousandSeparator
                      displayType="text"
                      decimalScale={0}
                      value={value}
                    />{" "}
                    (
                    <CurrencyFormat
                      thousandSeparator
                      displayType="text"
                      decimalScale={2}
                      value={percentage}
                      suffix="%"
                    />
                    )
                  </>
                ) : (
                  ""
                );
              },
            },
          ]
        : []),
    ],
    [t, viewSensitiveDataVerified]
  );

  const summaryCols = useMemo(() => {
    return comprehensiveMode
      ? [{ title: "", index: "id", render: () => "" }, ...tableColumns]
      : tableColumns;
  }, [comprehensiveMode, tableColumns]);

  const handleExportData = () => {
    const payload = getPayload();
    Progress.show(
      {
        method: exportReport,
        params: [
          {
            variables: {
              args: {
                ...payload,
                ...(sortBy?.order
                  ? {
                      sort: {
                        sortBy: sortBy.field,
                        sortDirection:
                          sortBy.order === SORT_DIRECTION.ASC ? 1 : -1,
                      },
                    }
                  : {}),
              },
            },
          },
        ],
      },
      (resp: any) => {
        const reportItems =
          resp?.data?.exportReportProductPerformance?.map((item: any) => ({
            ...item,
            created: convertToLocalTime(item.created),
          })) ?? [];
        const mappedData = reportItems.map(
          (item: ProductPerformanceItem, index: number) => ({
            No: index + 1,
            "Item Name": item?.product?.name,
            SKU: item?.product?.sku,
            "Sale Quantity": item.quantity,
            "Total Sales Amount": formatFloatNumber(item?.salePrice || 0),
            "Total Sales Amount (Net)": formatFloatNumber(
              item.salePriceNet || 0
            ),
            "Total Sales Amount (Net Excl. VAT)": formatFloatNumber(
              item.salePriceNet ? item.salePriceNet / VAT_DIVIDER : 0
            ),
            "Avg. Rev Per Unit (Net)": formatFloatNumber(item.revPerUnit || 0),
            ...(viewSensitiveDataVerified
              ? {
                  "Production Cost": formatFloatNumber(
                    item?.productionCost || 0
                  ),
                  // "Sale Expense": formatFloatNumber(item?.saleExpense || 0),
                  // "Admin Expense": formatFloatNumber(item?.adminExpense || 0),
                  CM: formatFloatNumber(item?.netProfit || 0),
                  "CM (%)": formatFloatNumber(calNetProfitPercentage(item), 2),
                }
              : {}),
          })
        );
        mappedData.push({
          No: "",
          "Item Name": "",
          SKU: "",
          "Sale Quantity": sumBy(mappedData, "Sale Quantity"),
          "Total Sales Amount": sumBy(mappedData, "Total Sales Amount"),
          "Total Sales Amount (Net)": sumBy(
            mappedData,
            "Total Sales Amount (Net)"
          ),
          "Total Sales Amount (Net Excl. VAT)": sumBy(
            mappedData,
            "Total Sales Amount (Net Excl. VAT)"
          ),
          "Avg. Rev Per Unit (Net)": sumBy(
            mappedData,
            "Avg. Rev Per Unit (Net)"
          ),
          ...(viewSensitiveDataVerified
            ? {
                "Production Cost": sumBy(mappedData, "Production Cost"),
                "Sale Expense": sumBy(mappedData, "Sale Expense"),
                "Admin Expense": sumBy(mappedData, "Admin Expense"),
                CM: sumBy(mappedData, "CM"),
                "CM (%)": "",
              }
            : {}),
        });
        exportToXLS(mappedData, "export");
      }
    );
  };

  const renderExpandableProductTable = (
    product: any,
    index: any,
    indent: any,
    expanded: boolean
  ) => {
    return expanded ? (
      <ExpandedRow
        filterData={filterData}
        channelList={channelList}
        product={product}
        summaryData={summaryData}
        viewSensitiveDataVerified={viewSensitiveDataVerified}
      />
    ) : null;
  };

  const expandableTable = {
    expandedRowRender: renderExpandableProductTable,
    rowExpandable: () => true,
    defaultExpandAllRows: false,
    expandedRowKeys,
    onExpandedRowsChange: (items: any) => setExpandedRowKeys(items),
  };

  useEffect(() => {
    setFilterData({
      ...filterData,
      products: [],
    });
  }, [filterData?.launchAt]);

  const onFilteredProducts = (products: any[]) => {
    setShowProductDrawer(false);
    if (!products) {
      return;
    }
    setFilterData({
      ...filterData,
      products: products,
    });
  };

  const checkCanVerify = () => {
    if (
      !isGrantedSensitiveData(VIEW_REPORT_PRODUCT_PERFORMANCE_PRODUCTION_COST)
    ) {
      Notifications.showError(t("notification:noPermission"));
      return;
    }
    if (adminInfo.phoneStatus !== PhoneStatus.Enabled) {
      Notifications.showError(t("notification:phoneNotConfigured"));
      return;
    }
    setShowVerifyDrawer(true);
  };

  return (
    <div>
      <ReportHeader
        onChangeFilter={onChangeFilter}
        handleExportData={handleExportData}
        reportType={REPORT_TYPE.PRODUCT_PERFORMANCE}
        componentAfter={
          <Button onClick={() => setShowProductDrawer(true)} className="mb-3">
            <Icon name="inventory" className="mr-2" />
            {t("searchProduct")} ({filterData?.products?.length})
          </Button>
        }
      />
      <div className="relative">
        <AwesomeTableComponent
          columns={tableColumns}
          ref={tableRef}
          title={() => (
            <div className="flex">
              <span className="flex-1">{t("report:dataTable")}</span>
              <span>
                <Switch
                  checked={comprehensiveMode}
                  onChange={() => setComprehensiveMode(!comprehensiveMode)}
                />{" "}
                {t("comprehensiveMode")}
              </span>
            </div>
          )}
          source={loadTableData}
          expandable={comprehensiveMode ? expandableTable : undefined}
          rowKey={(item) => item?.product?.refId}
          transformer={(res) => {
            const reportItems =
              res?.data?.reportProductPerformance?.data?.map((item: any) => ({
                ...item,
                created: convertToLocalTime(item.created),
              })) ?? [];
            return reportItems;
          }}
          getTotalItems={(response) => {
            return (
              response?.data?.reportProductPerformance?.pagination?.items ?? 0
            );
          }}
          summary={() => (
            <TableSummary data={summaryData} columns={summaryCols} />
          )}
        />
        {!viewSensitiveDataVerified &&
          isGrantedSensitiveData(
            VIEW_REPORT_PRODUCT_PERFORMANCE_PRODUCTION_COST
          ) && (
            <div className="pointer-events-none flex flex-col items-center justify-center absolute bg-gradient-to-b from-transparent from-10% via-white/75 to-transparent to-90% h-[calc(100%-200px)] right-0 top-[100px] w-[300px]">
              <h3 className="text-lg pt-5">{t("user:sensitiveDataAccess")}</h3>
              <p className="text-center text-xs">{t("user:verifyToSee")}</p>
              <Button
                onClick={() => checkCanVerify()}
                className="pointer-events-auto"
              >
                {t("verify")}
              </Button>
            </div>
          )}
      </div>
      {showProductDrawer && (
        <ProductFilterDrawer
          showDrawer={showProductDrawer}
          onClose={onFilteredProducts}
        />
      )}
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string}
      >
        {showVerifyDrawer && (
          <VerifyOTPDrawer
            open={showVerifyDrawer}
            onClose={() => setShowVerifyDrawer(false)}
            onSave={() => {
              setShowVerifyDrawer(false);
              setViewSensitiveDataVerified(true);
              tableRef.current && tableRef.current.refresh();
            }}
          />
        )}
      </GoogleReCaptchaProvider>
    </div>
  );
};

const ExpandedRow = ({
  filterData,
  channelList,
  product,
  summaryData,
  viewSensitiveDataVerified,
}: any) => {
  const [getReportDetail] = useDetailReportProductPerformanceLazyQuery();
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [currentWarehouse, setCurrentWarehouse] = useState<any>();
  const tableRef = useRef<any>(null);
  const { t } = useTranslation();

  const getPayload = () => {
    const payload = {
      orderSource: filterData?.orderSource,
      channels: filterData?.channels?.length
        ? filterData.channels
        : channelList.map((channel: Channel) => channel.refId),
      start: filterData?.currentRange?.[0].startOf("d").toDate(),
      end: filterData?.currentRange?.[1].endOf("d").toDate(),
      store: filterData.warehouses?.map((item: any) => item.refId),
      category: filterData.categories?.map((item: any) => item.refId),
      brand: filterData.brands?.map((item: any) => item.refId),
      focType: filterData.focType,
      products: [product?.product?.refId],
    };
    return omitBy(payload, isNil);
  };

  const loadTableData = (paging: any, sorter: any) => {
    return getReportDetail({
      variables: {
        args: {
          ...getPayload(),
          page: paging.pageIndex,
          limit: paging.pageSize,
          ...(sorter?.order
            ? {
                sort: {
                  sortBy: sorter.field,
                  sortDirection: sorter.order === SORT_DIRECTION.ASC ? 1 : -1,
                },
              }
            : {
                sort: {
                  sortBy: "quantity",
                  sortDirection: -1,
                },
              }),
        },
      },
    });
  };

  const tableColumns: IColumnsProps = useMemo(() => {
    return [
      {
        title: t("warehouse"),
        dataIndex: "store",
        align: "left",
        width: 200,
        render: (data: any) => {
          return data?.name;
        },
      },
      {
        title: t("channel"),
        dataIndex: "channel",
        align: "left",
        width: 200,
        render: (data: any) => {
          return data?.name;
        },
      },
      {
        title: t("saleQuantity"),
        dataIndex: "quantity",
        align: "left",
        width: 100,
        sorter: true,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        defaultSortOrder: SORT_DIRECTION.DESC,
        render: (value, item) => {
          return moneyFormatter.format(value || 0);
        },
      },
      {
        title: t("orderQuantity"),
        dataIndex: "totalOrder",
        align: "left",
        width: 100,
        sorter: true,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        defaultSortOrder: SORT_DIRECTION.DESC,
        render: (value, item) => {
          return (
            <span
              className="text-red-500 cursor-pointer"
              onClick={() => {
                setShowOrderModal(true);
                setCurrentWarehouse(item);
              }}
            >
              {moneyFormatter.format(value || 0)}
            </span>
          );
        },
      },
      {
        title: t("totalSalesAmount"),
        dataIndex: "salePrice",
        align: "left",
        width: 100,
        render: (value, item) => {
          return (
            <CurrencyFormat
              thousandSeparator
              displayType="text"
              decimalScale={0}
              value={value || 0}
            />
          );
        },
        sorter: true,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
      },
      {
        title: t("totalSalesAmountNet"),
        dataIndex: "salePriceNet",
        align: "left",
        width: 100,
        render: (value) => {
          return (
            <CurrencyFormat
              thousandSeparator
              displayType="text"
              decimalScale={0}
              value={value || 0}
            />
          );
        },
        sorter: true,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
      },
      {
        title: t("totalSalesAmountNetExclVAT"),
        dataIndex: "salePriceNet",
        align: "left",
        width: 100,
        render: (value) => {
          return (
            <CurrencyFormat
              thousandSeparator
              displayType="text"
              decimalScale={0}
              value={value ? value / VAT_DIVIDER : 0}
            />
          );
        },
        sorter: true,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
      },
      {
        title: t("revPerUnit"),
        dataIndex: "revPerUnit",
        align: "left",
        width: 100,
        render: (value) => {
          return (
            <CurrencyFormat
              thousandSeparator
              displayType="text"
              decimalScale={0}
              value={value || 0}
            />
          );
        },
        sorter: true,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
      },
      ...(isGrantedSensitiveData(
        VIEW_REPORT_PRODUCT_PERFORMANCE_PRODUCTION_COST
      )
        ? [
            {
              title: t("productionCost"),
              dataIndex: "productionCost",
              width: 100,
              minWidth: 100,
              sorter: true,
              sortDirections: [
                SORT_DIRECTION.ASC,
                SORT_DIRECTION.DESC,
                SORT_DIRECTION.ASC,
              ],
              render: (data: any) =>
                viewSensitiveDataVerified && !isUndefined(data) ? (
                  <CurrencyFormat
                    thousandSeparator
                    displayType="text"
                    decimalScale={0}
                    value={data || 0}
                  />
                ) : (
                  ""
                ),
            },
            // {
            //   title: t("saleExpense"),
            //   width: 90,
            //   minWidth: 90,
            //   dataIndex: "saleExpense",
            //   sorter: true,
            //   sortDirections: [
            //     SORT_DIRECTION.ASC,
            //     SORT_DIRECTION.DESC,
            //     SORT_DIRECTION.ASC,
            //   ],
            //   render: (data: any) =>
            //     viewSensitiveDataVerified && !isUndefined(data) ? (
            //       <CurrencyFormat
            //         thousandSeparator
            //         displayType="text"
            //         decimalScale={0}
            //         value={data || 0}
            //       />
            //     ) : (
            //       ""
            //     ),
            // },
            // {
            //   title: t("adminExpense"),
            //   width: 90,
            //   minWidth: 90,
            //   dataIndex: "adminExpense",
            //   sorter: true,
            //   sortDirections: [
            //     SORT_DIRECTION.ASC,
            //     SORT_DIRECTION.DESC,
            //     SORT_DIRECTION.ASC,
            //   ],
            //   render: (data: any) =>
            //     viewSensitiveDataVerified && !isUndefined(data) ? (
            //       <CurrencyFormat
            //         thousandSeparator
            //         displayType="text"
            //         decimalScale={0}
            //         value={data || 0}
            //       />
            //     ) : (
            //       ""
            //     ),
            // },
            {
              title: t("cm"),
              width: 150,
              minWidth: 150,
              dataIndex: "netProfit",
              sorter: true,
              sortDirections: [
                SORT_DIRECTION.ASC,
                SORT_DIRECTION.DESC,
                SORT_DIRECTION.ASC,
              ],
              render: (data: any, item: any) => {
                const value = data;
                const percentage = calNetProfitPercentage(item);
                return viewSensitiveDataVerified &&
                  !isUndefined(item.productionCost) ? (
                  <>
                    <CurrencyFormat
                      thousandSeparator
                      displayType="text"
                      decimalScale={0}
                      value={value}
                    />{" "}
                    (
                    <CurrencyFormat
                      thousandSeparator
                      displayType="text"
                      decimalScale={2}
                      value={percentage}
                      suffix="%"
                    />
                    )
                  </>
                ) : (
                  ""
                );
              },
            },
          ]
        : []),
    ];
  }, [t, viewSensitiveDataVerified]);

  const exportReport = async () => {
    Progress.show(
      {
        method: loadAllPaginatedData,
        params: [
          getReportDetail,
          "detailReportProductPerformance",
          getPayload(),
        ],
      },
      (resp) => {
        const exportedData: ProductPerformanceByWarehouseItem[] = resp as any[];

        const mappedData = [
          {
            Product: product?.product?.name,
            SKU: product?.product?.sku,
            Warehouse: "",
            Channel: "",
            "Sale Quantity": product.quantity,
            "Order Quantity": "",
            "Total Sales Amount": formatFloatNumber(product?.salePrice || 0),
            "Total Sales Amount (Net)": formatFloatNumber(
              product.salePriceNet || 0
            ),
            "Total Sales Amount (Net Excl. VAT)": formatFloatNumber(
              product.salePriceNet ? product.salePriceNet / VAT_DIVIDER : 0
            ),
            "Avg. Rev Per Unit (Net)": formatFloatNumber(
              product.revPerUnit || 0
            ),
            ...(viewSensitiveDataVerified
              ? {
                  "Production Cost": formatFloatNumber(
                    product?.productionCost || 0
                  ),
                  // "Sale Expense": formatFloatNumber(product?.saleExpense || 0),
                  // "Admin Expense": formatFloatNumber(
                  //   product?.adminExpense || 0
                  // ),
                  CM: formatFloatNumber(product?.netProfit || 0),
                  "CM (%)": formatFloatNumber(
                    calNetProfitPercentage(product),
                    2
                  ),
                }
              : {}),
          },
        ];

        const rowData = chain(exportedData)
          .orderBy("store.name")
          .map((item: ProductPerformanceByWarehouseItem) => ({
            Product: product?.product?.name,
            SKU: product?.product?.sku,
            Warehouse: item?.store?.name,
            Channel: item?.channel?.name,
            "Sale Quantity": item.quantity,
            "Order Quantity": item?.totalOrder,
            "Total Sales Amount": formatFloatNumber(item?.salePrice || 0),
            "Total Sales Amount (Net)": formatFloatNumber(
              item.salePriceNet || 0
            ),
            "Total Sales Amount (Net Excl. VAT)": formatFloatNumber(
              item.salePriceNet ? item.salePriceNet / VAT_DIVIDER : 0
            ),
            "Avg. Rev Per Unit (Net)": formatFloatNumber(item.revPerUnit || 0),
            ...(viewSensitiveDataVerified
              ? {
                  "Production Cost": formatFloatNumber(
                    item?.productionCost || 0
                  ),
                  // "Sale Expense": formatFloatNumber(item?.saleExpense || 0),
                  // "Admin Expense": formatFloatNumber(item?.adminExpense || 0),
                  CM: formatFloatNumber(item?.netProfit || 0),
                  "CM (%)": formatFloatNumber(calNetProfitPercentage(item), 2),
                }
              : {}),
          }))
          .value();
        const exportData = [...mappedData, ...rowData];
        exportToXLS(exportData, "export");
      },
      (err: any) => {
        Notifications.showError(err);
      }
    );
  };

  useEffect(() => {
    tableRef.current && tableRef.current.refresh();
  }, [filterData]);

  return (
    <div className="relative">
      <AwesomeTableComponent
        columns={tableColumns}
        ref={tableRef}
        source={loadTableData}
        transformer={(res) => {
          const reportItems =
            res?.data?.detailReportProductPerformance?.data?.map(
              (item: any) => ({
                ...item,
                created: convertToLocalTime(item.created),
              })
            ) ?? [];
          return reportItems;
        }}
        getTotalItems={(response) => {
          return (
            response?.data?.detailReportProductPerformance?.pagination?.items ??
            0
          );
        }}
      />
      <Button
        className="absolute bottom-2 left-0"
        iconName="cloud_download"
        onClick={exportReport}
      >
        {t("export")}
      </Button>
      <OrderModal
        showDrawer={showOrderModal}
        setShowDrawer={setShowOrderModal}
        getPayload={getPayload}
        currentRow={currentWarehouse}
      />
    </div>
  );
};

const ProductPerformace = () => {
  return (
    <ReportContext>
      <ProductPerformaceContent />
    </ReportContext>
  );
};

const ProductFilterDrawer = ({ showDrawer, onClose }: any) => {
  const { t } = useTranslation();
  const [findProduct] = useFindProductLazyQuery();
  const { filterData, setFilterData } = useContext(AppContext);
  const [allSelectedRows, setAllSelectedRows] = useState<any[]>(
    filterData?.products ?? []
  );
  const [selectedRowKey, setSelectedRowKey] = useState<any[]>(
    filterData?.products?.map((item: any) => item.refId) ?? []
  );
  const searchRef = useRef<string>("");
  const tableRef = useRef<any>(null);
  const [searchValue, setSearchValue] = useState("");

  const tabs = useMemo(
    () => [
      {
        id: "ALL",
        label: t("all"),
      },

      {
        id: "SELECTED",
        label: t("selected"),
      },
    ],
    [t]
  );
  const [tabSelected, setTabSelected] = useState<any>(tabs[0]);

  const columns: IColumnsProps = [
    {
      title: t("name"),
      dataIndex: "name",
      render: (product, item) => product,
    },
    {
      title: t("sku"),
      dataIndex: "sku",
      render: (product, item) => product,
    },
  ];

  const getLaunchAtDate = (launchAt: any) => {
    let monthToSubtract = 0;
    switch (launchAt) {
      case PRODUCT_LAUNCH_AT.LAST_3_MONTHS:
        monthToSubtract = 3;
        break;
      case PRODUCT_LAUNCH_AT.LAST_6_MONTHS:
        monthToSubtract = 6;
        break;
      case PRODUCT_LAUNCH_AT.LAST_9_MONTHS:
        monthToSubtract = 9;
        break;
      case PRODUCT_LAUNCH_AT.LAST_12_MONTHS:
        monthToSubtract = 12;
        break;
    }
    return dayjs().subtract(monthToSubtract, "months").toDate();
  };

  const getCustomParams = () => ({
    ...(filterData?.launchAt
      ? {
          launchAt: getLaunchAtDate(filterData.launchAt),
        }
      : {}),
    ...(searchRef.current
      ? {
          query: searchRef.current,
        }
      : {}),
  });

  const source = (pagingData: any) => {
    return findProduct({
      variables: {
        args: {
          limit: pagingData.pageSize,
          page: pagingData.pageIndex,
          ...getCustomParams(),
        },
      },
      fetchPolicy: "no-cache",
    });
  };

  const onChangeSearch = (text: string) => {
    searchRef.current = text;
  };
  const searchProduct = () => {
    tableRef.current && tableRef.current.refresh();
    setSearchValue(searchRef.current);
  };

  const onSelectAll: () => Promise<string[]> = async () => {
    return new Promise((resolve, reject) => {
      Progress.show(
        {
          method: loadAllPaginatedData,
          params: [findProduct, "findProduct", getCustomParams()],
        },
        (resp) => {
          resolve(resp?.map((row: any) => row));
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  };

  const onChangeSelectedRows = (selected: any[]) => {
    setAllSelectedRows(selected);
    setSelectedRowKey(selected?.map((item) => item.refId));
  };

  const onChangeSelectedRowsFromTable: any = (selectedRowKeys: string[]) => {
    setAllSelectedRows(
      allSelectedRows?.filter((row) => selectedRowKeys.includes(row.refId))
    );
  };

  return (
    <Drawer
      title={t("searchProduct")}
      open={showDrawer}
      onClose={() => onClose()}
      size="auto"
      width="1000px"
      destroyOnClose
    >
      <div className="pb-10">
        <InputTextSearch
          className="mb-3"
          onChange={(event: any) => onChangeSearch(event.target.value)}
          onSubmit={searchProduct}
        />
        <TabBar
          dataSource={tabs}
          onChange={setTabSelected}
          value={tabSelected}
        />
        <TableSelectAll
          className={`${tabSelected.id === "ALL" ? "block" : "hidden"} mt-3`}
          ref={tableRef}
          columns={columns}
          source={source}
          transformer={(res) => res?.data?.findProduct?.data ?? []}
          getTotalItems={(res) => {
            return res?.data?.findProduct?.pagination?.items ?? 0;
          }}
          rowKey={(item) => item.refId}
          onSelectAll={onSelectAll}
          itemKey="refId"
          filterParams={{ searchValue }}
          onSelectRows={onChangeSelectedRows}
          initialSelectedRows={filterData?.products}
        />{" "}
        {tabSelected.id === "SELECTED" && (
          <AwesomeTableComponent
            className={`mt-3`}
            dataSource={allSelectedRows}
            rowKey={(item) => item.refId}
            columns={columns}
            rowSelection={{
              onChange: onChangeSelectedRowsFromTable,
              selectedRowKeys: selectedRowKey,
              preserveSelectedRowKeys: true,
            }}
          />
        )}
      </div>
      <div className="z-10 position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
        <Button
          onClick={() => {
            onClose && onClose(allSelectedRows);
          }}
        >
          {t("common:save")}
        </Button>
      </div>
    </Drawer>
  );
};

export const OrderModal = ({
  showDrawer,
  setShowDrawer,
  getPayload,
  currentRow,
}: any) => {
  const { t } = useTranslation();
  const { filterData } = useContext(AppContext);
  const [getOrders] = useDetailReportProductPerformanceOrderLazyQuery();
  const tableRef = useRef<any>(null);

  const tableColumns: IColumnsProps = useMemo(
    () => [
      {
        title: t("SONo"),
        dataIndex: "order",
        align: "left",
        width: 50,
        render: (value, item, index) => {
          return (
            <a
              target="_blank"
              rel="noreferrer"
              href={`${orderUrl}${value?.orderId}`}
              className="text-blue-500"
            >
              {value?.orderId}
            </a>
          );
        },
      },
      {
        title: t("saleQuantity"),
        dataIndex: "quantity",
        align: "left",
        width: 80,
        render: (value, item, index) => {
          return value;
        },
      },
      {
        title: t("totalSalesAmount"),
        dataIndex: "salePrice",
        align: "left",
        width: 100,
        render: (value) => {
          return (
            <CurrencyFormat
              thousandSeparator
              displayType="text"
              decimalScale={0}
              value={value || 0}
            />
          );
        },
      },
      {
        title: t("totalSalesAmountNet"),
        dataIndex: "salePriceNet",
        align: "left",
        width: 100,
        render: (value) => {
          return (
            <CurrencyFormat
              thousandSeparator
              displayType="text"
              decimalScale={0}
              value={value || 0}
            />
          );
        },
      },
      {
        title: t("totalSalesAmountNetExclVAT"),
        dataIndex: "salePriceNet",
        align: "left",
        width: 100,
        render: (value) => {
          return (
            <CurrencyFormat
              thousandSeparator
              displayType="text"
              decimalScale={0}
              value={value ? value / VAT_DIVIDER : 0}
            />
          );
        },
      },
      {
        title: t("createdAt"),
        dataIndex: "created",
        align: "left",
        width: 120,
        render: (data, item, index) => TimeUtils.convertMiliToDateTime(data),
      },
    ],
    [t]
  );

  const summaryColumns: IColumnsProps = useMemo(
    () => [
      {
        title: t("warehouse"),
        dataIndex: "store",
        align: "left",
        width: 200,
        render: (data: any) => {
          return data?.name;
        },
      },
      {
        title: t("channel"),
        dataIndex: "channel",
        align: "left",
        width: 200,
        render: (data: any) => {
          return data?.name;
        },
      },
      {
        title: t("totalSalesAmount"),
        dataIndex: "salePrice",
        align: "left",
        width: 100,
        render: (value) => {
          return (
            <CurrencyFormat
              thousandSeparator
              displayType="text"
              decimalScale={0}
              value={value || 0}
            />
          );
        },
      },
      {
        title: t("totalSalesAmountNet"),
        dataIndex: "salePriceNet",
        align: "left",
        width: 100,
        render: (value) => {
          return (
            <CurrencyFormat
              thousandSeparator
              displayType="text"
              decimalScale={0}
              value={value || 0}
            />
          );
        },
      },
      {
        title: t("totalSalesAmountNetExclVAT"),
        dataIndex: "salePriceNet",
        align: "left",
        width: 100,
        render: (value) => {
          return (
            <CurrencyFormat
              thousandSeparator
              displayType="text"
              decimalScale={0}
              value={value ? value / VAT_DIVIDER : 0}
            />
          );
        },
      },
    ],
    [t]
  );

  const loadTableData = (paging: any, sorter: any) => {
    if (!filterData?.orderSource?.length) {
      return Promise.resolve([]);
    }
    const payload = getPayload();
    return getOrders({
      variables: {
        args: {
          ...omitBy(
            {
              ...payload,
              store: [currentRow?.store?.refId],
            },
            isNil
          ),
          page: paging.pageIndex,
          limit: paging.pageSize,
        },
      },
    });
  };

  const exportReport = async () => {
    const payload = { ...getPayload(), store: [currentRow?.store?.refId] };
    Progress.show(
      {
        method: loadAllPaginatedData,
        params: [getOrders, "detailReportProductPerformanceOrder", payload],
      },
      (resp) => {
        const exportedData: any[] = resp as any[];

        const rowData = chain(exportedData)
          .map((item: any) => ({
            Warehouse: currentRow?.store?.name,
            Channel: currentRow?.channel?.name,
            "SO No": item?.order?.orderId,
            "Sale Quantity": item.quantity,
            "Order Quantity": item?.totalOrder,
            "Total Sales Amount": formatFloatNumber(item?.salePrice),
            "Total Sales Amount (Net)": formatFloatNumber(item.salePriceNet),
            "Total Sales Amount (Net Excl. VAT)": formatFloatNumber(
              item.salePriceNet ? item.salePriceNet / VAT_DIVIDER : 0
            ),
            "Created At": TimeUtils.convertMiliToDateTime(item.created),
          }))
          .value();
        const exportData = [...rowData];
        exportToXLS(exportData, "export");
      },
      (err: any) => {
        Notifications.showError(err);
      }
    );
  };

  return (
    <Drawer
      title={t("orderList")}
      open={showDrawer}
      onClose={() => setShowDrawer(false)}
      size="auto"
      width="800px"
      destroyOnClose
    >
      <StyledSummaryTable>
        <AwesomeTableComponent
          columns={summaryColumns}
          dataSource={[currentRow]}
          pagination={false}
          classNameTable="mb-3 bg-amber-100"
        />
      </StyledSummaryTable>
      <div className="relative">
        <Button
          className="absolute bottom-2 left-0 z-50"
          iconName="cloud_download"
          onClick={exportReport}
        >
          {t("export")}
        </Button>
        <AwesomeTableComponent
          columns={tableColumns}
          ref={tableRef}
          source={loadTableData}
          rowKey={(item) => item?.product?.refId}
          transformer={(res) => {
            const reportItems =
              res?.data?.detailReportProductPerformanceOrder?.data?.map(
                (item: any) => ({
                  ...item,
                  created: convertToLocalTime(item.created),
                })
              ) ?? [];
            return reportItems;
          }}
          getTotalItems={(response) => {
            return (
              response?.data?.detailReportProductPerformanceOrder?.pagination
                ?.items ?? 0
            );
          }}
        />
      </div>
    </Drawer>
  );
};

const StyledSummaryTable = styled.div`
  .d-table-awesome-component {
    height: auto !important;
    .ant-table,
    .ant-table-header,
    .ant-table-cell {
      background-color: transparent !important;
    }
  }
`;

export default ProductPerformace;

import { Dropdown } from "antd";
import {
  SelectProps,
  SelectInfinityProps,
  Icon,
  Button,
} from "d-react-components";
import { t } from "i18next";
import React, { useCallback, useState } from "react";
import SelectFilter, {
  SelectFilterProps,
  SelectInfinityFilter,
} from "../SelectFilter/SelectFilter";

type FilterDropdownType = "normal" | "infinity";

interface FilterDropdownProps {
  type?: FilterDropdownType;
  className?: string;
  selectProps:
    | (Partial<SelectProps> & SelectFilterProps)
    | (Partial<SelectInfinityProps> & SelectFilterProps);
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  selectProps,
  type = "normal",
  className,
}) => {
  const [displayDropdown, setDisplayDropdown] = useState(false);

  return (
    <>
      {selectProps.label && (
        <label className="text-label">{selectProps.label}</label>
      )}
      <div className={`relative z-10 ${className}`}>
        <Dropdown
          arrow={true}
          destroyPopupOnHide
          trigger={["click"]}
          dropdownRender={() => (
            <DropdownContent
              selectProps={selectProps}
              type={type}
              setDisplayDropdown={setDisplayDropdown}
            />
          )}
          visible={displayDropdown}
          onVisibleChange={(open) => {
            setDisplayDropdown(open);
          }}
        >
          <div
            className="h-[42px] px-3 cursor-pointer border-[1px] border-gray-300 text-center flex items-center pr-4"
            style={
              selectProps.disabled
                ? { backgroundColor: "#f5f5f5" }
                : { backgroundColor: "#fff" }
            }
          >
            <div className="mr-2 whitespace-nowrap">
              <span
                style={
                  selectProps?.value?.length
                    ? { fontSize: "13px" }
                    : { color: "#BFBFBF", fontSize: "13px" }
                }
              >
                {selectProps.tagTitle}
              </span>
              {selectProps?.value?.length > 0 && (
                <span className="text-white bg-primary ml-1 w-4 h-4 rounded-full inline-block text-center text-xs">
                  {selectProps?.value?.length}
                </span>
              )}
            </div>
            <span
              className="ant-select-arrow"
              unselectable="on"
              aria-hidden="true"
            >
              <i className="material-icons d-icon__medium">expand_more</i>
            </span>
          </div>
        </Dropdown>
      </div>
    </>
  );
};

const DropdownContent = ({
  selectProps,
  type = "normal",
  setDisplayDropdown,
}: {
  selectProps: FilterDropdownProps["selectProps"];
  type?: FilterDropdownType;
  setDisplayDropdown: any;
}) => {
  const { value, dataSource, getLabel, getValue, onChange } = selectProps;
  const [dropdownValue, setDropdownValue] = useState(value);

  const onRemoveItem = (index: number) => {
    setDropdownValue(dropdownValue.filter((v: any, i: number) => i !== index));
  };

  const getItemLabel = useCallback(
    (v) => {
      if (!getLabel) {
        return "";
      }
      return type === "normal"
        ? getLabel(
            dataSource?.find((item) => getValue && getValue(item) === v) ?? {}
          )
        : getLabel(v);
    },
    [getLabel]
  );

  return (
    <div
      className="absolute w-[420px] top-0 left-0 bg-white py-3 px-4 border-[1px] border-gray-300"
      // ref={ref}
    >
      <div className="flex border-b-[1px] mb-3 pb-2">
        <h4>{selectProps.tagTitle}</h4>
      </div>
      <div className="mb-3">
        {type === "normal" ? (
          <SelectFilter
            {...selectProps}
            value={dropdownValue}
            onChange={(val) => setDropdownValue(val)}
            customTagRender={(props, dropdownOpen) => {
              return props?.value === dropdownValue?.[0] && !dropdownOpen ? (
                <div
                  className="flex items-center text-xs"
                  style={{ color: "#BFBFBF" }}
                >
                  {t("search")}
                </div>
              ) : (
                <></>
              );
            }}
          />
        ) : (
          <SelectInfinityFilter
            {...selectProps}
            value={dropdownValue}
            onChange={(val) => setDropdownValue(val)}
            customTagRender={(props, dropdownOpen) => {
              return props?.value === dropdownValue?.[0]?.refId &&
                !dropdownOpen ? (
                <div
                  className="flex items-center text-xs"
                  style={{ color: "#BFBFBF" }}
                >
                  {t("search")}
                </div>
              ) : (
                <></>
              );
            }}
          />
        )}

        {dropdownValue?.length > 0 && (
          <div className="mt-3 max-h-[290px] overflow-auto">
            {dropdownValue.map((v: any, index: number) => {
              return (
                <div className="flex mb-3" key={index}>
                  <div className="flex-1">{getItemLabel(v)}</div>
                  <Icon
                    name="do_disturb_on"
                    className="text-red-500 cursor-pointer"
                    onClick={() => onRemoveItem(index)}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="flex items-center border-t-[1px] pt-3">
        <div className="flex-1">
          <span
            className="text-red-500 cursor-pointer"
            onClick={() => setDropdownValue([])}
          >
            {t("clearAll")}
          </span>
        </div>
        <div>
          <Button
            onClick={() => {
              onChange && onChange(dropdownValue, { label: "" });
              setDisplayDropdown(false);
            }}
          >
            {t("apply")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterDropdown;

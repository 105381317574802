import {
  Form,
  HeaderTable,
  HeaderTableProps,
  IFormProps,
  Modal,
  UrlUtils,
  Drawer,
  Button,
} from "d-react-components";
import React, { useEffect, useState } from "react";

export interface IHeaderTableFilterProps extends HeaderTableProps {
  dataSource?: IFormProps["dataSource"];
  filterData?: any;
  onFilter?: (data?: any) => any;
  searchValue?: string;
  filterType?: "drawer" | "modal";
  messages?: {
    [key: string]: string;
  };
}

export const HeaderTableFilter: React.FC<IHeaderTableFilterProps> = ({
  label,
  onChangeText,
  onClickFilter,
  dataSource,
  filterData,
  onFilter,
  filterType = "modal",
  messages,
  ...props
}) => {
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filterState, setFilterState] = useState<any>({});

  useEffect(() => {
    setFilterState(filterData);
  }, [filterData]);

  const onChangeState = (key: string, value: any) => {
    setFilterState((prev: any) => ({ ...prev, [key]: value }));
  };

  const renderContentModal = () => {
    return (
      <div className="">
        <Form
          dataSource={dataSource}
          onChange={onChangeState}
          value={filterState}
          Messages={{}}
        />
      </div>
    );
  };

  const onSaveHandler = () => {
    const query: any = {};
    Object.keys(filterState).forEach((key) => {
      if (filterState[key]) {
        query[key] = JSON.stringify(filterState[key]);
      }
    });
    UrlUtils.replaceState(query);
    onFilter && onFilter(filterState);
    setOpenFilterModal(false);
  };

  const renderModalType = () => {
    return (
      <Modal
        title={`${messages?.["filter"] ?? "Filter"} ${label}`}
        open={openFilterModal}
        onClose={() => setOpenFilterModal(false)}
        size="medium"
        saveText={messages?.["filter"] ?? "Filter"}
        onSideClick={() => {
          setFilterState({});
        }}
        sideText={messages?.["clearAll"] ?? "Clear All"}
        onSave={() => {
          onSaveHandler();
        }}
      >
        {renderContentModal()}
      </Modal>
    );
  };

  const renderDrawerType = () => {
    const renderDrawerFooter = (
      <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
        <Button
          className="text-underline"
          onClick={() => {
            setFilterState({});
          }}
          variant="outline"
        >
          {messages?.["clearAll"] ?? "clearAll"}
        </Button>
        <Button
          onClick={() => {
            onSaveHandler();
          }}
        >
          {messages?.["save"] ?? "Save"}
        </Button>
      </div>
    );
    return (
      <Drawer
        title={`${messages?.["filter"] ?? "Filter"} ${label}`}
        open={openFilterModal}
        onClose={() => setOpenFilterModal(false)}
        size="auto"
        width="1000px"
        footer={renderDrawerFooter}
      >
        {renderContentModal()}
        <div style={{ height: "100px" }} />
      </Drawer>
    );
  };

  return (
    <div>
      <HeaderTable
        label={label}
        onChangeText={onChangeText}
        onClickFilter={
          onClickFilter
            ? () => {
                if (onClickFilter) {
                  return onClickFilter();
                }
                return setOpenFilterModal(true);
              }
            : null
        }
        {...props}
      />
      {filterType === "modal" && openFilterModal && renderModalType()}
      {filterType === "drawer" && openFilterModal && renderDrawerType()}
    </div>
  );
};

import { Moment } from "moment";
import { ProductPerformanceFilterProductType } from "../network/hooks";

export const DAYS_RANGE = 14;
export const DAYS_CHANGE = 7;
export const TIMELINE_FILTER = "TIMELINE_FILTER";

export interface ITimelineFilterData {
  suspicious?: string;
  status?: Array<any>;
  company?: Array<any>;
  department?: Array<any>;
  jobLevel?: Array<any>;
  jobTitle?: Array<any>;
  staffType?: Array<any>;
  employee?: Array<any>;
  rangeDateExport?: Array<any>;
  workPlace?: Array<any>;
  emStatus?: Array<any>;
}

export type TimelineExportType = "export";
export interface ITimelineGetFilterDataProps {
  type?: TimelineExportType | typeof TIMELINE_FILTER;
}

export interface ITimeLineState {
  // selectedTab: typeof TIMELINE_TAB[0];
  // refreshSmartList: any;

  // refreshListView: any;

  openFilterModal: {
    open: boolean;
    type: null | ITimelineGetFilterDataProps["type"] | "PROGRESS";
  };

  filterBody: ITimelineFilterData | null;

  dateRange: [Moment, Moment];
  currentRange: [Moment, Moment];
  onChangeData: (key: keyof ITimeLineState, value: any) => void;
}

export enum FILTER_TYPE {
  BY_DAY = "by_day",
  BY_WEEK = "by_week",
  BY_MONTH = "by_month",
  BY_QUARTER = "by_quarter",
  BY_YEAR = "by_year",
}

export const FILTER_TYPE_VIEW_OPTIONS = [
  {
    label: "byDay",
    id: FILTER_TYPE.BY_DAY,
  },
  // {
  //   label: "byWeek",
  //   id: FILTER_TYPE.BY_WEEK,
  // },
  {
    label: "byMonth",
    id: FILTER_TYPE.BY_MONTH,
  },
  {
    label: "byQuarter",
    id: FILTER_TYPE.BY_QUARTER,
  },
  {
    label: "byYear",
    id: FILTER_TYPE.BY_YEAR,
  },
];

export const FILTER_TYPE_OPTIONS = [
  {
    label: "byDay",
    id: FILTER_TYPE.BY_DAY,
  },
  {
    label: "byWeek",
    id: FILTER_TYPE.BY_WEEK,
  },
  {
    label: "byMonth",
    id: FILTER_TYPE.BY_MONTH,
  },
];

export enum REPORT_TYPE {
  CHANNEL_PERFORMANCE = "CHANNEL_PERFORMANCE",
  TOTAL_SALE = "TOTAL_SALE",
  PRODUCT_PERFORMANCE = "PRODUCT_PERFORMANCE",
  STORE_PERFORMANCE = "STORE_PERFORMANCE",
  CARTRULE_PERFORMANCE = "CARTRULE_PERFORMANCE",
  STAFF_PERFORMANCE = "STAFF_PERFORMANCE",
  PROVINCE_PERFORMANCE = "PROVINCE_PERFORMANCE",
}

export enum CompareValue {
  LAST_3_MONTHS = "LAST_3_MONTHS",
  LAST_6_MONTHS = "LAST_6_MONTHS",
  LAST_12_MONTHS = "LAST_12_MONTHS",
  LAST_4_QUARTERS_OY = "LAST_4_QUARTERS_OY",
  LAST_4_QUARTERS = "LAST_4_QUARTERS",
  CUSTOM = "CUSTOM",
}

export enum CompareTermCriteria {
  BY_QUARTER = "BY_QUARTER",
  BY_MONTH = "BY_MONTH",
}

export enum CompareTerm {
  QOQ = "QOQ",
  YOY = "YOY",
}

export const COMPARE_OPTIONS = [
  {
    value: CompareValue.LAST_3_MONTHS,
    label: "last3MonthsOverYear",
    term: CompareTerm.YOY,
    termCriteria: CompareTermCriteria.BY_MONTH,
  },
  {
    value: CompareValue.LAST_6_MONTHS,
    label: "last6MonthsOverYear",
    term: CompareTerm.YOY,
    termCriteria: CompareTermCriteria.BY_MONTH,
  },
  {
    value: CompareValue.LAST_12_MONTHS,
    label: "last12MonthsOverYear",
    term: CompareTerm.YOY,
    termCriteria: CompareTermCriteria.BY_MONTH,
  },
  {
    value: CompareValue.LAST_4_QUARTERS_OY,
    label: "last4QuartersOverYear",
    term: CompareTerm.YOY,
    termCriteria: CompareTermCriteria.BY_MONTH,
  },
  {
    value: CompareValue.LAST_4_QUARTERS,
    label: "last4Quarters",
    term: CompareTerm.QOQ,
    termCriteria: CompareTermCriteria.BY_QUARTER,
  },
  {
    value: CompareValue.CUSTOM,
    label: "custom",
  },
];

export const COMPARE_TERMS_OPTIONS = [
  {
    value: CompareTerm.QOQ,
    label: "qoq",
  },
  {
    value: CompareTerm.YOY,
    label: "yoy",
  },
];

export const COMPARE_TERMS_CRITERIA_OPTIONS = [
  {
    value: CompareTermCriteria.BY_QUARTER,
    label: "byQuarter",
  },
  {
    value: CompareTermCriteria.BY_MONTH,
    label: "byMonth",
  },
];

export const FILTER_PRODUCT_TYPE_OPTIONS = [
  {
    value: ProductPerformanceFilterProductType.IncludingGroupProduct,
    label: "includingGroupProduct",
  },
  {
    value: ProductPerformanceFilterProductType.ExcludingGroupProduct,
    label: "excludingGroupProduct",
  },
];
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { split, join } from "lodash";
import { IMenuItemProps, MenuPropsType } from "../interfaces/common";

const getMenuItemPath = (item: IMenuItemProps, isDetail?: boolean) => {
  const itemPath = item.path;
  if (!isDetail) {
    return itemPath;
  }
  const params = split(itemPath, "/");
  params.pop();
  const newPath = join(params, "/");
  return newPath;
};

export function usePathInfo(data: MenuPropsType): any {
  const location = useLocation();
  let pathName = location.pathname;
  return useMemo(() => {
    let menuSelecting;
    const params = split(pathName, "/");
    const isDetail = params.includes("detail") || params.includes("update");
    if (isDetail) {
      params.pop();
      pathName = join(params, "/");
    }
    data.forEach((menuItem) => {
      const itemPath = getMenuItemPath(menuItem, isDetail);
      if (itemPath === pathName) {
        menuSelecting = menuItem;
      }

      if (menuItem.subMenu && menuItem.subMenu?.length > 0) {
        menuItem.subMenu.forEach((subMenuItem) => {
          const subMenuItemPath = getMenuItemPath(subMenuItem, isDetail);
          if (subMenuItemPath === pathName) {
            menuSelecting = subMenuItem;
          }
        });
      }
    });
    return menuSelecting;
  }, [pathName]);
}

import React from "react";
import { FILTER_TYPE_VIEW_OPTIONS } from "../../../../constants/report";
import { useTranslation } from "react-i18next";

const SmartViewMode = ({ viewMode, onChangeViewMode }: any) => {
  const { t } = useTranslation();
  return (
    <div>
      {FILTER_TYPE_VIEW_OPTIONS?.map((option) => (
        <span
          className={`inline-block mx-2 cursor-pointer font-semibold text-sm ${
            viewMode === option.id ? "text-primary" : "text-gray-400"
          }`}
          onClick={() => onChangeViewMode(option.id)}
        >
          {t(option.label)}
        </span>
      ))}
    </div>
  );
};

export default SmartViewMode;

import {
  Button,
  Drawer,
  Icon,
  InputText,
  Notifications,
  Select,
} from "d-react-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { userUserInfo } from "../UserDetail";
import { SensitiveStatuses } from "../../../../constants/admin";
import { useTranslation } from "react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  PhoneStatus,
  useSendOtpVerifyPhoneNumberUsersByAdminLazyQuery,
  useUpdatePhoneNumberUsersByAdminMutation,
} from "../../../../network/hooks";
import { CountdownText } from "../../../common/VerifyOTPDrawer";
import { isEmpty } from "lodash";
import { useLocalStorage } from "react-use";
import { IAdmin } from "../../../../interfaces/common";
import { ADMIN_AUTH_KEY } from "../../../../constants/common";

interface VerifyForm {
  phoneStatus?: string;
  phoneNumber?: string;
  otp?: string;
}
const VerifySchema = Yup.object().shape({
  phoneStatus: Yup.string().required("This field is required"),
  phoneNumber: Yup.string()
    .nullable()
    .when("phoneStatus", (phoneStatus, schema) => {
      if (phoneStatus === "ENABLED")
        return schema.required("This field is required");
      return schema;
    }),
  otp: Yup.string()
    .nullable()
    .when("phoneStatus", (phoneStatus, schema) => {
      if (phoneStatus === "ENABLED")
        return schema.required("This field is required");
      return schema;
    }),
});

export const UserSensitiveDataDrawer = ({ open, onClose, onSave }: any) => {
  const [{ user, refetch }] = userUserInfo();
  const [requestOTP] = useSendOtpVerifyPhoneNumberUsersByAdminLazyQuery();
  const [updatePhoneNumber] = useUpdatePhoneNumberUsersByAdminMutation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [otpSent, setOtpSent] = useState<string>("");
  const [alreadySetPhone, setAlreadySetPhone] = useState(
    !isEmpty(user?.phoneNumber)
  );
  const [showChangePhone, setShowChangePhone] = useState<boolean>(false);
  const { t } = useTranslation();
  const [admin, setAdmin] = useLocalStorage<any>(ADMIN_AUTH_KEY);

  const verifyForm = useFormik<VerifyForm>({
    initialValues: {
      phoneStatus: user?.phoneStatus,
      //   phoneNumber: user?.phoneNumber,
    },
    validationSchema: VerifySchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (e) => {
      onUpdatePassword();
    },
  });
  const { values, errors, handleSubmit, setFieldValue } = verifyForm;
  
  const onRequestOTP = async () => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha("otp");
      //   const token = "";
      requestOTP({
        variables: {
          input: {
            recaptchaToken: token,
            phoneNumber: values.phoneNumber ?? "",
          },
        },
        fetchPolicy: "no-cache",
      }).then(
        (resp: any) => {
          // console.log(resp);
          if (resp?.errors) {
            Notifications.showError(resp?.errors?.[0]?.message);
          } else if (resp?.data?.sendOTPVerifyPhoneNumberUsersByAdmin) {
            setOtpSent(resp?.data?.sendOTPVerifyPhoneNumberUsersByAdmin?.token);
          }
        },
        (err) => {
          Notifications.showError(err);
        }
      );
    }
  };

  const onUpdatePassword = async () => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha("otp");
      updatePhoneNumber({
        variables: {
          id: user?.id as string,
          input: {
            phoneStatus: values.phoneStatus as PhoneStatus,
            ...(values.phoneStatus === PhoneStatus.Enabled
              ? {
                  phoneNumber: values.phoneNumber,
                  token: otpSent,
                  code: values.otp,
                  recaptchaToken: token,
                }
              : {}),
          },
        },
        fetchPolicy: "no-cache",
      }).then(
        (resp) => {
          if (resp?.data?.updatePhoneNumberUsersByAdmin) {
            setAdmin({
              ...admin,
              ...resp?.data?.updatePhoneNumberUsersByAdmin,
            });
            Notifications.showSuccess(
              t("notification:userSensitiveDataUpdatedSuccessfully")
            );
            onSave();
          }
        },
        (err) => {
          Notifications.showError(err);
        }
      );
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      size="auto"
      width={"600px"}
      title={t("user:sensitiveDataAccess")}
      destroyOnClose
    >
      <div className="pb-10">
        <Select
          value={values.phoneStatus}
          onChange={(value) => setFieldValue("phoneStatus", value)}
          dataSource={SensitiveStatuses}
          label={t("user:smsOTPVerification")}
          error={errors.phoneStatus}
          className="mb-3"
        />
        {values?.phoneStatus === "ENABLED" && alreadySetPhone && (
          <>
            <label className="text-label">{t("user:phoneNumberForOTP")}</label>
            <div className="flex items-center bg-gray-100 p-2">
              {user?.phoneNumber}{" "}
              <Icon name="check_circle" className="text-green-500" />
            </div>
            <div className="mt-1 mb-3">
              <span>{t("user:changeNewPhoneNo")}</span>
              <span
                className="ml-2 text-primary cursor-pointer"
                onClick={() => setShowChangePhone(true)}
              >
                {t("user:updateNow")}
              </span>
            </div>
            {showChangePhone && (
              <div className="flex mb-3">
                <InputText
                  label={t("user:newPhoneNumberForOTP")}
                  value={values.phoneNumber}
                  onChange={(e) =>
                    setFieldValue("phoneNumber", e?.target?.value)
                  }
                  error={errors.phoneNumber}
                  className="flex-1"
                />
                <Button
                  disabled={
                    !values.phoneNumber ||
                    values?.phoneNumber?.length < 10 ||
                    !isEmpty(otpSent)
                  }
                  className="top-[24px] relative"
                  onClick={() => onRequestOTP()}
                >
                  {t("common:sendOTP")}
                </Button>
              </div>
            )}
          </>
        )}
        {values?.phoneStatus === "ENABLED" && !alreadySetPhone && (
          <div className="flex mb-3">
            <InputText
              label={t("user:phoneNumberForOTP")}
              value={values.phoneNumber}
              onChange={(e) => setFieldValue("phoneNumber", e?.target?.value)}
              error={errors.phoneNumber}
              className="flex-1"
            />
            <Button
              disabled={
                !values.phoneNumber ||
                values?.phoneNumber?.length < 10 ||
                !isEmpty(otpSent)
              }
              className="top-[24px] relative"
              onClick={() => onRequestOTP()}
            >
              {t("common:sendOTP")}
            </Button>
          </div>
        )}
        {values?.phoneStatus === "ENABLED" && (
          <>
            {otpSent && (
              <>
                <InputText
                  label={t("user:otpVerification")}
                  value={values.otp}
                  onChange={(e) => setFieldValue("otp", e?.target?.value)}
                  error={errors.otp}
                />
                <div className="text-left">
                  <span className="block mt-2">{t("notReceiveCode")}</span>
                  <CountdownText resendOTP={onRequestOTP} />
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div className="z-10 position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-end py-3 px-3 bg-white">
        <Button
          onClick={() => {
            handleSubmit();
          }}
        >
          {t("common:save")}
        </Button>
      </div>
    </Drawer>
  );
};

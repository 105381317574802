export enum AdminStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export const AdminStatuses = [
  {
    id: AdminStatus.ENABLED,
    label: AdminStatus.ENABLED,
  },
  {
    id: AdminStatus.DISABLED,
    label: AdminStatus.DISABLED,
  },
];

export const SensitiveStatuses = [
  {
    id: AdminStatus.ENABLED,
    label: AdminStatus.ENABLED,
    value: true,
  },
  {
    id: AdminStatus.DISABLED,
    label: AdminStatus.DISABLED,
    value: false,
  },
];

import { Card, Notifications, Progress, TimeUtils } from "d-react-components";
import {
  chain,
  filter,
  find,
  forEach,
  isNil,
  omitBy,
  orderBy,
  sumBy,
} from "lodash";
import { useApolloClient } from "@apollo/client";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CompareTerm,
  CompareTermCriteria,
  FILTER_TYPE,
  REPORT_TYPE,
} from "../../constants/report";
import {
  ReportStorePerformanceDocument,
  useReportStorePerformanceByStoreLazyQuery,
  useReportStorePerformanceLazyQuery,
  useWarehouseListLazyQuery,
  WarehouseListDocument,
} from "../../network/hooks";
import { exportToXLS } from "../../utils/file";
import {
  convertRangeMonthToArray,
  convertRangeQuarterToArray,
  convertRangeYearToArray,
  convertToLocalTime,
  convertToLocalTimeDayjs,
  getListOfQuarter,
  groupDateToMonths,
  groupDateToMonthsAlt,
  groupDateToWeeks,
  groupDateToWeeksAlt,
} from "../../utils/time";
import ReportCtx from "../common/AppContext/ReportContext";
import AppContext from "../common/AppContext/AppContext";
import InfiniteScroll from "react-infinite-scroller";
import ReportHeader, {
  FilterDataProps,
} from "../common/report/header/ReportHeader";
import ReportContext from "./ReportContext";
import { Spin } from "antd";
import { Channel, Pagination } from "../../interfaces/common";
import { useUpdateEffect } from "react-use";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import CompareTimelineView from "../common/report/body/CompareTimelineView";
import {
  getCompareChartParams,
  getDifferenceInPercent,
  getDifferencePercent,
} from "../../utils/chart";
import TimeLineSmartViewLazy from "../common/report/body/TimeLineSmartViewLazy";
import SmartViewMode from "../common/report/body/SmartViewMode";
import { MOMENT_UNIT } from "../../constants/common";
dayjs.extend(quarterOfYear);

const StorePerformanceContent = () => {
  const { t } = useTranslation();
  const [filterBy, setFilterBy] = useState(FILTER_TYPE.BY_DAY);
  const [visibleRange, setVisibleRange] = useState<any[]>([]);
  const [loadingOtherPage, setLoadingOtherPage] = useState(false);
  const [rawData, setRawData] = useState<any[]>([]);
  const [getReport, { data, loading, refetch }] =
    useReportStorePerformanceLazyQuery();
  const [getReportGrouped, { data: groupedData, loading: loadingGroupedData }] =
    useReportStorePerformanceByStoreLazyQuery();
  const { filterData, channelList, viewMode } = useContext(ReportCtx);
  const { mainContentRef } = useContext(AppContext);
  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 1,
    pageSize: 20,
  });
  const [hasMoreData, setHasMoreData] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [listStore, setListStore] = useState<any[]>([]);

  const onChangeFilter = (filterData: FilterDataProps) => {
    if (
      !filterData?.orderSource?.length ||
      !filterData?.channels ||
      loadingGroupedData ||
      viewMode === "compare"
    ) {
      return;
    }
    const payload = {
      orderSource: filterData?.orderSource,
      channels: filterData?.channels?.length
        ? filterData.channels
        : channelList.map((channel: Channel) => channel.refId),
      orderType: filterData.orderType,
      paymentStatus: filterData.paymentStatus,
      start: filterData?.currentRange?.[0].startOf("d").toDate(),
      end: filterData?.currentRange?.[1].endOf("d").toDate(),
      store: filterData.warehouses?.map((item) => item.refId),
    };
    setTimeout(() => {
      Progress.show(
        {
          method: getReportGrouped,
          params: [
            {
              variables: {
                args: {
                  ...omitBy(payload, isNil),
                  page: pagination.pageIndex,
                  limit: pagination.pageSize,
                },
              },
            },
          ],
        },
        () => {}
      );
    });
  };

  const resetPagination = () => {
    setHasMoreData(false);
    setPagination({
      ...pagination,
      pageIndex: 1,
    });
  };

  const loadMoreReport = () => {
    setHasMoreData(false);
    setPagination({
      ...pagination,
      pageIndex: pagination.pageIndex + 1,
    });
  };

  useUpdateEffect(() => {
    onChangeFilter(filterData);
  }, [pagination]);

  ///append new pagination data
  useEffect(() => {
    if (groupedData?.reportStorePerformanceByStore?.pagination) {
      const { currentPage, total } =
        groupedData?.reportStorePerformanceByStore?.pagination;
      setHasMoreData((currentPage ?? 0) < (total ?? 0));
    }

    const previousData = pagination.pageIndex === 1 ? [] : [...rawData];
    const listStores = groupedData?.reportStorePerformanceByStore?.data?.map(
      (item) => item.store
    );
    const newData =
      groupedData?.reportStorePerformanceByStore?.data?.map((item) => ({
        ...item,
        report: item?.report?.map((i: any) => ({
          ...i,
          created: convertToLocalTimeDayjs(i.created),
        })),
      })) ?? [];
    setListStore(
      pagination.pageIndex === 1
        ? listStores ?? []
        : [...listStore, ...(listStores ?? [])]
    );
    setRawData([...previousData, ...newData]);

    //append new data to table
    const newTableData = visibleRange.map((time, index) => {
      const summedData: { [key: string]: any } = {};
      forEach(listStores ?? [], (currentStore) => {
        const listReport =
          find(newData, (item) => item?.store?.refId === currentStore?.refId)
            ?.report ?? [];
        summedData[currentStore?.name as string] = sumBy(
          listReport,
          (reportItem: any) =>
            dayjs(time).isSame(
              dayjs(reportItem.created),
              MOMENT_UNIT[filterBy] as any
            )
              ? reportItem?.total ?? 0
              : 0
        );
      });

      return {
        ...tableData[index],
        date: dayjs(time).toDate(),
        formattedDate: dayjs(time).format("DD/MM"),
        formattedMonth: dayjs(time).format("MMM"),
        ...summedData,
      };
    });
    setTableData(newTableData);
  }, [groupedData]);

  useEffect(() => {
    if (!rawData.length) {
      return;
    }
    setLoadingOtherPage(true);
    setTableData([]);
    setTimeout(() => {
      const newData = visibleRange.map((time, index) => {
        const timeToCompare = filterBy === FILTER_TYPE.BY_WEEK ? time[0] : time;
        const summedData: { [key: string]: any } = {};
        forEach(listStore ?? [], (currentStore) => {
          const listReport =
            find(rawData, (item) => item?.store?.refId === currentStore?.refId)
              ?.report ?? [];
          summedData[currentStore?.name as string] = sumBy(
            listReport,
            (reportItem: any) =>
              dayjs(timeToCompare).isSame(
                dayjs(reportItem.created),
                MOMENT_UNIT[filterBy] as any
              )
                ? reportItem?.total ?? 0
                : 0
          );
        });

        return {
          date: dayjs(time).toDate(),
          formattedDate: dayjs(time).format("DD/MM"),
          formattedMonth: dayjs(time).format("MMM"),
          ...summedData,
        };
      });
      setTableData(newData);
      setLoadingOtherPage(false);
    }, 50);
  }, [visibleRange]);

  const getTimeRange = useCallback((range: any) => {
    if (!range.length) {
      return;
    }
    let visibleRange;
    switch (filterBy) {
      case FILTER_TYPE.BY_DAY:
        visibleRange = TimeUtils.convertRangeDateToArray(range[0], range[1]);
        break;
      case FILTER_TYPE.BY_WEEK:
        visibleRange = groupDateToWeeksAlt(range[0], range[1]);
        break;
      case FILTER_TYPE.BY_MONTH:
        visibleRange = convertRangeMonthToArray(range[0], range[1]);
        break;
      case FILTER_TYPE.BY_QUARTER:
        visibleRange = convertRangeQuarterToArray(range[0], range[1]);
        break;
      case FILTER_TYPE.BY_YEAR:
        visibleRange = convertRangeYearToArray(range[0], range[1]);
        break;
    }
    return visibleRange;
  }, [filterBy]);

  const getLabelFormat = useCallback((time: any) => {
    let formattedDate;
    switch (filterBy) {
      case FILTER_TYPE.BY_DAY:
        formattedDate = dayjs(time).format("DD/MM/YYYY");
        break;
      case FILTER_TYPE.BY_WEEK:
        formattedDate = dayjs(time).format("DD/MM/YYYY");
        break;
      case FILTER_TYPE.BY_MONTH:
        formattedDate = dayjs(time).format("MM/YYYY");
        break;
      case FILTER_TYPE.BY_QUARTER:
        formattedDate = `Q${dayjs(time).format("Q/YYYY")}`;
        break;
      case FILTER_TYPE.BY_YEAR:
        formattedDate = dayjs(time).format("YYYY");
        break;
    }
    return formattedDate;
  }, [filterBy]);

  const onRangeChanged = (range: any[]) => {
    if (!range.length) {
      return;
    }
    const visibleRange: any = getTimeRange(range);
    setVisibleRange(visibleRange);
  };

  const handleExportData = () => {
    const payload = {
      orderSource: filterData?.orderSource,
      channels: filterData?.channels?.length
        ? filterData.channels
        : channelList.map((channel: Channel) => channel.refId),
      orderType: filterData.orderType,
      paymentStatus: filterData.paymentStatus,
      start: filterData?.currentRange?.[0].startOf("d").toDate(),
      end: filterData?.currentRange?.[1].endOf("d").toDate(),
      store: filterData.warehouses?.map((item: any) => item.refId),
    };
    Progress.show(
      {
        method: getReport,
        params: [
          {
            variables: {
              args: {
                ...omitBy(payload, isNil),
              },
            },
          },
        ],
      },
      (resp: any) => {
        try {
          const reportItems =
            resp?.data?.reportStorePerformance.map((item: any) => ({
              ...item,
              created: convertToLocalTime(item.created),
            })) ?? [];
          const timeRange: any = getTimeRange(filterData.currentRange);
          const listStores = chain(
            resp?.data?.reportStorePerformance?.map((item: any) => item.store)
          )
            .uniqBy("refId")
            .sortBy("name")
            .valueOf();

          const exportData: any[] = [];
          forEach(listStores, (store, index) => {
            const storeData: any = {
              Store: store?.name,
            };
            const storeReports = filter(
              reportItems,
              (reportItem) => reportItem?.store?.refId === store?.refId
            );
            let total = 0;
            forEach(timeRange, (time) => {
              const date = getLabelFormat(time);
              const dataOnTheDate = filter(storeReports, (reportItem) =>
                dayjs(time).isSame(
                  dayjs(reportItem.created),
                  MOMENT_UNIT[filterBy] as any
                )
              );
              const totalInDate = sumBy(dataOnTheDate, "total");
              storeData[date] = totalInDate;
              total += totalInDate;
            });
            storeData["Total"] = total;
            exportData.push(storeData);
          });
          exportToXLS(exportData, "export");
        } catch (err) {
          console.log(err);
        }
      }
    );
  };

  return viewMode === "compare" ? (
    <CompareMode />
  ) : (
    <InfiniteScroll
      pageStart={1}
      loadMore={loadMoreReport}
      hasMore={hasMoreData}
      useWindow={false}
      getScrollParent={() => mainContentRef?.current}
    >
      <ReportHeader
        onChangeFilter={resetPagination}
        handleExportData={handleExportData}
        reportType={REPORT_TYPE.STORE_PERFORMANCE}
      />
      <Card>
        {viewMode === "normal" && (
          <>
            {loadingOtherPage && (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-content-center">
                <Spin size="large" />
              </div>
            )}
            <div className="flex justify-end mb-3">
              <SmartViewMode
                viewMode={filterBy}
                onChangeViewMode={(value: any) => setFilterBy(value)}
              />
            </div>
            <TimeLineSmartViewLazy
              data={tableData}
              timeRange={filterData.currentRange}
              filterBy={filterBy}
              channels={listStore}
              headerTitle={t("warehouse")}
              onRangeChanged={onRangeChanged}
            />
          </>
        )}
      </Card>
    </InfiniteScroll>
  );
};

const CompareMode = () => {
  const [loadingCompareChart, setLoadingCompareChart] = useState(false);
  const [compareChartData, setCompareChartData] = useState<any[]>([]);
  const [getListWarehouse] = useWarehouseListLazyQuery();
  const [paginationStore, setPaginationStore] = useState<Pagination>({
    pageIndex: 1,
    pageSize: 100,
  });
  const [hasMoreStote, setHasMoreStore] = useState(false);
  const client = useApolloClient();
  const { mainContentRef } = useContext(AppContext);
  const { channelList, compareData } = useContext(ReportCtx);
  const [listCompareStore, setListCompareStore] = useState<any[]>([]);
  const { t } = useTranslation();

  const onChangeCompare = () => {
    setCompareChartData([]);
    setListCompareStore([]);
    setHasMoreStore(false);
    setPaginationStore({
      pageIndex: 1,
      pageSize: 100,
    });
  };

  const loadMoreStore = () => {
    if (loadingCompareChart) {
      return;
    }
    setHasMoreStore(false);
    setPaginationStore({
      ...paginationStore,
      pageIndex: paginationStore.pageIndex + 1,
    });
  };

  const handleExportData = async () => {
    const loadExportData = async () => {
      return new Promise(async (resolve, reject) => {
        try {
          let page = 1;
          const limit = 100;
          let hasMoreData = true;
          const exportData = [];
          while (hasMoreData) {
            if (compareData?.warehouses?.length) {
              const storeToLoads = orderBy(
                compareData?.warehouses?.slice(
                  (paginationStore.pageIndex - 1) * paginationStore.pageSize,
                  paginationStore.pageSize
                ),
                "name"
              );
              const chunkData = await loadCompareData(storeToLoads, true);
              exportData.push(chunkData);
              hasMoreData = page * limit < compareData?.warehouses?.length;
              page++;
            } else {
              const resp = await getListWarehouse({
                variables: {
                  args: {
                    page,
                    limit,
                    search: {
                      channelIds: compareData?.channels?.length
                        ? compareData.channels
                        : channelList.map((channel: Channel) => channel.refId),
                    },
                  },
                },
              });
              const listStores = resp?.data?.findWarehousesByAdmin?.data ?? [];
              const chunkData = await loadCompareData(listStores, true);
              exportData.push(chunkData);
              if (resp?.data?.findWarehousesByAdmin?.pagination) {
                const { currentPage, total } =
                  resp?.data?.findWarehousesByAdmin?.pagination;
                hasMoreData = (currentPage ?? 0) < (total ?? 0);
                page++;
              }
            }
          }
          const compareChartData: any[] = combineExportData(exportData);
          const mappedData: any[] = [];
          const listStore = compareChartData?.[0]?.values;
          forEach(listStore, (value, store) => {
            const storeData: any = {
              Store: store,
            };
            if (compareData.term === CompareTerm.YOY) {
              forEach(compareChartData, (item, index) => {
                storeData[`${item.label} (${item.subLabel})`] =
                  compareChartData?.[index]?.values?.[store]?.value;
                if (item.subLabel === "Diff") {
                  storeData[`${item.label} (${item.subLabel})(%)`] =
                    getDifferencePercent({
                      formerIndex:
                        compareChartData?.[index - 2]?.values?.[store]?.value,
                      latterIndex:
                        compareChartData?.[index - 1]?.values?.[store]?.value,
                    });
                }
              });
            }
            if (compareData.term === CompareTerm.QOQ) {
              forEach(compareChartData, (item, index) => {
                storeData[`${item.label} (${item.subLabel})`] =
                  compareChartData?.[index]?.values?.[store]?.value;
                if (index > 0) {
                  storeData[`${item.label} (Diff)`] = Math.abs(
                    compareChartData?.[index - 1]?.values?.[store]?.value -
                      compareChartData?.[index]?.values?.[store]?.value
                  );
                  storeData[`${item.label} (Diff)(%)`] = getDifferencePercent({
                    formerIndex:
                      compareChartData?.[index]?.values?.[store]?.value,
                    latterIndex:
                      compareChartData?.[index - 1]?.values?.[store]?.value,
                  });
                }
              });
            }
            mappedData.push(storeData);
          });
          resolve(mappedData);
        } catch (err: any) {
          Notifications.showError(err);
          reject(err);
        }
      });
    };

    Progress.show(
      {
        method: loadExportData,
        params: [],
      },
      (resp: any) => {
        exportToXLS(resp, "export");
      }
    );
  };

  const resetPagination = () => {};

  const getBasePayload = ({ listStores }: any) => {
    return {
      orderSource: compareData?.orderSource,
      paymentStatus: compareData?.paymentStatus,
      channels: compareData?.channels?.length
        ? compareData.channels
        : channelList.map((channel: Channel) => channel.refId),
      store: listStores
        ? listStores?.map((item: any) => item.refId)
        : compareData?.warehouses?.map((item: any) => item.refId),
    };
  };

  const loadCompareData = async (listStores: any[], isExport = false) => {
    // if (!filterData?.orderSource?.length) {
    //   return;
    // }
    const { term, termCriteria, numberOfMonths, numberOfYears, formerTime } =
      getCompareChartParams(compareData);
    const payload = getBasePayload({ listStores });
    const formerStart = dayjs(formerTime)
      .subtract(numberOfMonths - 1, "M")
      .startOf("M")
      .startOf("d")
      .toDate();
    const formerEnd = dayjs(formerTime).endOf("M").endOf("d").toDate();
    const latterStart = dayjs(formerTime)
      .subtract(numberOfYears, "y")
      .subtract(numberOfMonths - 1, "M")
      .startOf("M")
      .startOf("d")
      .toDate();
    const latterEnd = dayjs(formerTime)
      .subtract(numberOfYears, "y")
      .endOf("M")
      .endOf("d")
      .toDate();
    const formerPayload: any = {
      ...payload,
      start: formerStart,
      end: formerEnd,
    };
    const latterPayload: any = {
      ...payload,
      start: latterStart,
      end: latterEnd,
    };
    const promises = [
      client.query({
        query: ReportStorePerformanceDocument,
        variables: { args: omitBy(formerPayload, isNil) },
        fetchPolicy: "no-cache",
      }),
    ];
    if (term === CompareTerm.YOY) {
      promises.push(
        client.query({
          query: ReportStorePerformanceDocument,
          variables: { args: omitBy(latterPayload, isNil) },
          fetchPolicy: "no-cache",
        })
      );
    }
    try {
      if (!isExport) {
        setLoadingCompareChart(true);
      }
      return Promise.all(promises)
        .then((resp) => {
          const formerResult = resp?.[0]?.data?.reportStorePerformance ?? [];
          const latterResult = resp?.[1]?.data?.reportStorePerformance ?? [];
          if (
            term === CompareTerm.YOY &&
            termCriteria === CompareTermCriteria.BY_MONTH
          ) {
            const formerMonths = groupDateToMonthsAlt(
              dayjs(formerTime).subtract(numberOfMonths - 1, "M"),
              dayjs(formerTime).endOf("d")
            );
            const latterMonths = groupDateToMonthsAlt(
              dayjs(formerTime)
                .subtract(numberOfYears, "y")
                .subtract(numberOfMonths - 1, "M"),
              dayjs(formerTime).subtract(numberOfYears, "y").endOf("d")
            );
            const formerData = processData(
              formerResult,
              formerMonths,
              listStores
            );
            const latterData = processData(
              latterResult,
              latterMonths,
              listStores
            );
            const combinedData = combineData(
              formerData,
              latterData,
              listStores
            );
            const allData: any[] = [];
            formerData.forEach((item, index) => {
              allData.push(item);
              allData.push(latterData[index]);
              allData.push(combinedData[index]);
            });
            if (!isExport) {
              setCompareChartData(appendData(allData));
            } else {
              return allData;
            }
          } else if (
            term === CompareTerm.YOY &&
            termCriteria === CompareTermCriteria.BY_QUARTER
          ) {
            const formerData = processDataByQuarter(
              formerResult,
              getListOfQuarter(formerTime),
              listStores
            );
            const latterData = processDataByQuarter(
              latterResult,
              getListOfQuarter(formerTime.subtract(numberOfYears, "y")),
              listStores
            );
            const combinedData = combineData(
              formerData,
              latterData,
              listStores
            );
            const allData: any[] = [];
            formerData.forEach((item, index) => {
              allData.push(item);
              allData.push(latterData[index]);
              allData.push(combinedData[index]);
            });
            if (!isExport) {
              setCompareChartData(appendData(allData));
            } else {
              return allData;
            }
          } else if (term === CompareTerm.QOQ) {
            const formerData = processDataByQuarter(
              formerResult,
              getListOfQuarter(formerTime),
              listStores,
              true
            );
            if (!isExport) {
              setCompareChartData(appendData(formerData));
            } else {
              return formerData;
            }
          }
        })
        .finally(() => {
          if (!isExport) {
            setLoadingCompareChart(false);
          }
        });
    } catch (err) {
      Notifications.showError(err);
    }
  };

  const appendData = (newData: any[]) => {
    const appendedData = newData.map((item, index) => {
      return {
        ...item,
        values: {
          ...(compareChartData?.[index]?.values ?? {}),
          ...item?.values,
        },
      };
    });
    return appendedData;
  };

  const combineExportData = (newData: any[]) => {
    try {
      const combinedData = newData?.[0];
      for (let i = 1; i < newData.length; i++) {
        forEach(combinedData, (item, index) => {
          combinedData[index].values = {
            ...item.values,
            ...newData[i]?.[index]?.values,
          };
        });
      }
      return combinedData;
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeStore = () => {
    if (compareData?.warehouses?.length) {
      const storeToLoads = orderBy(
        compareData?.warehouses?.slice(
          (paginationStore.pageIndex - 1) * paginationStore.pageSize,
          paginationStore.pageSize
        ),
        "name"
      );
      if (storeToLoads.length) {
        setListCompareStore([...listCompareStore, ...storeToLoads]);
        loadCompareData(storeToLoads);
        setHasMoreStore(
          paginationStore.pageIndex * paginationStore.pageSize <
            compareData?.warehouses?.length
        );
      }
    } else {
      client
        .query({
          query: WarehouseListDocument,
          variables: {
            args: {
              page: paginationStore.pageIndex,
              limit: paginationStore.pageSize,
              search: {
                channelIds: compareData?.channels?.length
                  ? compareData.channels
                  : channelList.map((channel: Channel) => channel.refId),
              },
            },
          },
          fetchPolicy: "no-cache",
        })
        .then((resp) => {
          const listStores = resp?.data?.findWarehousesByAdmin?.data ?? [];
          if (listStores.length) {
            setListCompareStore([...listCompareStore, ...listStores]);
            loadCompareData(listStores);
            if (resp?.data?.findWarehousesByAdmin?.pagination) {
              const { currentPage, total } =
                resp?.data?.findWarehousesByAdmin?.pagination;
              setHasMoreStore(currentPage < total);
            }
          }
        });
    }
  };

  useUpdateEffect(() => {
    onChangeStore();
  }, [paginationStore]);

  return (
    <InfiniteScroll
      pageStart={1}
      loadMore={loadMoreStore}
      hasMore={hasMoreStote}
      loader={<Spin />}
      useWindow={false}
      getScrollParent={() => mainContentRef?.current}
    >
      <ReportHeader
        onChangeFilter={resetPagination}
        handleExportData={handleExportData}
        reportType={REPORT_TYPE.STORE_PERFORMANCE}
        onChangeCompare={onChangeCompare}
      />
      <Card>
        <>
          {loadingCompareChart && (
            <div className="absolute w-full h-full bg-white/75 flex items-center justify-content-center z-[50]">
              <Spin size="large" />
            </div>
          )}
          <TableYOY
            compareChartData={compareChartData}
            listStore={listCompareStore}
            groupLength={compareData.term === CompareTerm.YOY ? 3 : 4}
          />
        </>
      </Card>
    </InfiniteScroll>
  );
};

const TableYOY = ({ compareChartData, listStore, groupLength }: any) => {
  const { t } = useTranslation();
  return (
    <CompareTimelineView
      data={compareChartData}
      channels={listStore}
      headerTitle={t("warehouse")}
      groupLength={groupLength}
    />
  );
};

const processData = (rawData: any[], timeRange: any[], listStores: any[]) => {
  const reportItems =
    rawData.map((item) => ({
      ...item,
      created: convertToLocalTimeDayjs(item.created),
    })) ?? [];
  const newData = timeRange.map((time, index) => {
    const summedData: { [key: string]: any } = {};
    forEach(listStores ?? [], (currentStore) => {
      const listReport =
        filter(
          reportItems,
          (item) => item?.store?.refId === currentStore?.refId
        ) ?? [];
      const storeData: any = {};
      storeData.value = sumBy(listReport, (reportItem: any) =>
        dayjs(time).isSame(reportItem.created, "M") ? reportItem?.total : 0
      );
      summedData[currentStore?.name as string] = { ...storeData };
    });

    return {
      date: dayjs(time),
      subLabel: dayjs(time).format("MM/YYYY"),
      label: dayjs(time).format("MMM"),
      values: { ...summedData },
    };
  });
  return newData;
};

const processDataByQuarter = (
  rawData: any[],
  timeRange: any[],
  listStores: any[],
  calDifference: boolean = false
) => {
  const reportItems =
    rawData.map((item) => ({
      ...item,
      created: convertToLocalTimeDayjs(item.created),
    })) ?? [];
  const newData = timeRange.map((dayInQuarter, index) => {
    const quarter = dayjs(dayInQuarter).quarter();
    const summedData: { [key: string]: any } = {};
    forEach(listStores ?? [], (currentStore) => {
      const listReport =
        filter(
          reportItems,
          (item) => item?.store?.refId === currentStore?.refId
        ) ?? [];
      const storeData: any = {};
      storeData.value = sumBy(listReport, (reportItem: any) =>
        dayjs(reportItem.created).quarter() === quarter ? reportItem?.total : 0
      );
      summedData[currentStore?.name as string] = { ...storeData };
    });

    return {
      date: dayjs(dayInQuarter),
      subLabel: dayjs(dayInQuarter).format("YYYY"),
      label: `${dayjs(dayInQuarter).quarter()}Q`,
      values: { ...summedData },
    };
  });
  if (calDifference) {
    newData.forEach((item, index) => {
      if (index > 0) {
        forEach(listStores ?? [], (currentStore) => {
          if (!currentStore) return;
          const { isIncrease, percentDiff } = getDifferenceInPercent({
            formerIndex: newData[index]?.values?.[currentStore?.name]?.value,
            latterIndex:
              newData[index - 1]?.values?.[currentStore?.name]?.value,
          });
          newData[index].values[currentStore?.name].isIncrease = isIncrease;
          newData[index].values[currentStore?.name].percentDiff = percentDiff;
        });
      }
    });
  }
  return newData;
};

const combineData = (
  formerData: any[],
  latterData: any[],
  listStores: any[]
) => {
  const combinedData = formerData.map((item, index) => {
    const combinedValue: any = {};
    listStores.forEach((store) => {
      if (store) {
        const formerValue = item.values?.[store.name]?.value ?? 0;
        const latterValue =
          latterData?.[index]?.values?.[store.name]?.value ?? 0;
        const data: any = {};
        data.value = Math.abs(formerValue - latterValue);
        const { isIncrease, percentDiff } = getDifferenceInPercent({
          formerIndex: formerValue,
          latterIndex: latterValue,
        });
        data.isIncrease = isIncrease;
        data.percentDiff = percentDiff;
        combinedValue[store.name] = data;
      }
    });
    return {
      subLabel: "Diff",
      label: item.label,
      values: combinedValue,
    };
  });
  return combinedData;
};

const StorePerformance = () => {
  return (
    <ReportContext>
      <StorePerformanceContent />
    </ReportContext>
  );
};

export default StorePerformance;

import React from "react";

interface StatusItemProps {
  statusText: string;
  className?: string;
  dotClassName?: string;
  textClassName?: string;
}

export const StatusItem = ({
  statusText,
  className = "",
  dotClassName = "",
  textClassName = "",
}: StatusItemProps) => {
  return (
    <div className={`text-xs rounded-2xl py-1 pl-2 pr-3 flex items-center ${className}`}>
      <span className={`rounded-full inline-block w-2 h-2 mr-1 ${dotClassName}`}></span>
      <span className={textClassName}>{statusText}</span>
    </div>
  );
};

export default StatusItem;

import React from "react";
import { Avatar as DAvatar } from "d-react-components";
import { isEmpty } from "lodash";

export interface AvatarProps {
  avatar: string | null | undefined;
  name: ObjectName;
}

export interface ObjectName {
  firstName?: string;
  lastName?: string;
}

export const NoAvatar = ({
  name,
  size = "large",
  showTitle = false,
}: {
  name: ObjectName;
  size?: "x-small" | "small" | "large";
  showTitle?: boolean;
}) => {
  const firstName = name?.firstName?.charAt(0) ?? "";
  const lastName = name?.lastName?.charAt(0) ?? "";
  return (
    <div className="d-flex align-items-center">
      <div
        className={`d-image__container d-image__square-${size} align-middle align-items-center justify-content-center d-flex`}
        style={{ backgroundColor: "#D8D8D8" }}
      >
        <h3
          style={{
            fontSize: {
              "x-small": "12px",
              small: "16px",
              large: "24px",
            }[size],
          }}
        >
          {firstName}
          {lastName}
        </h3>
      </div>
      {showTitle && (
        <span className="ml-3">
          {name?.firstName} {name?.lastName}
        </span>
      )}
    </div>
  );
};

export const Avatar: React.FC<AvatarProps> = ({ avatar, name }) => {
  return (
    <div>
      {avatar && !isEmpty(avatar) ? (
        <DAvatar src={avatar} size="large" variant="square" />
      ) : (
        <NoAvatar name={name} />
      )}
    </div>
  );
};

export default Avatar;

import {
  AwesomeTableComponent,
  Button,
  IColumnsProps,
  Notifications,
  Progress,
} from "d-react-components";
import { chain, isNil, isUndefined, omitBy, sumBy } from "lodash";
import React, { useRef, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SORT_DIRECTION } from "../../constants/common";
import { Channel } from "../../interfaces/common";
import {
  useExportReportProductPerformanceLazyQuery,
  useReportProductPerformanceLazyQuery,
} from "../../network/hooks";
import { exportToXLS } from "../../utils/file";
import { formatFloatNumber, moneyFormatter } from "../../utils/money";
import { loadAllPaginatedData } from "../../utils/promises";
import { convertToLocalTime } from "../../utils/time";
import CurrencyFormat from "react-currency-format";
import { ProductPerformanceItem } from "../../interfaces/report";

export const StaffPerformanceByProduct = ({
  filterData,
  channelList,
  staff,
  summaryData,
}: any) => {
  const [getReportDetail, { data, loading, refetch }] =
    useReportProductPerformanceLazyQuery();
  const [sortBy, setSortBy] = useState<any>();
  const tableRef = useRef<any>(null);
  const { t } = useTranslation();

  const getPayload = (sorter?: any) => {
    const payload = {
      orderSource: filterData?.orderSource,
      channels: filterData?.channels?.length
        ? filterData.channels
        : channelList.map((channel: Channel) => channel.refId),
      start: filterData?.currentRange?.[0].startOf("d").toDate(),
      end: filterData?.currentRange?.[1].endOf("d").toDate(),
      store: filterData.warehouses?.map((item: any) => item.refId),
      staffs: [Number(staff?.adminId)],
      ...getSorter(sorter),
    };
    return omitBy(payload, isNil);
  };

  const getSorter = (sorterParams?: any) => {
    const sorter = sorterParams || sortBy;
    return sorter?.order
      ? {
          sort: {
            sortBy: sorter.field,
            sortDirection: sorter.order === SORT_DIRECTION.ASC ? 1 : -1,
          },
        }
      : {
          sort: {
            sortBy: "quantity",
            sortDirection: -1,
          },
        };
  };

  const loadTableData = (paging: any, sorter: any) => {
    setSortBy(sorter);
    return getReportDetail({
      variables: {
        args: {
          ...getPayload(sorter),
          page: paging.pageIndex,
          limit: paging.pageSize,
        },
      },
    });
  };

  const tableColumns: IColumnsProps = useMemo(() => {
    return [
      {
        title: t("itemName"),
        dataIndex: "productName",
        align: "left",
        render: (value, item) => {
          return item?.product?.name;
        },
        sorter: true,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
      },
      {
        title: t("sku"),
        dataIndex: "product",
        align: "left",
        width: 150,
        render: (value, item) => {
          return item?.product?.sku;
        },
      },
      {
        title: t("saleQuantity"),
        dataIndex: "quantity",
        align: "left",
        width: 100,
        sorter: true,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        defaultSortOrder: SORT_DIRECTION.DESC,
        render: (value, item) => {
          return !isUndefined(value) ? moneyFormatter.format(value || 0) : "";
        },
      },
      {
        title: t("totalSalesAmount"),
        dataIndex: "salePrice",
        align: "left",
        width: 140,
        render: (value, item) => {
          return (
            <CurrencyFormat
              thousandSeparator
              displayType="text"
              decimalScale={0}
              value={value || 0}
            />
          );
        },
        sorter: true,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
      },
      {
        title: t("totalSalesAmountNet"),
        dataIndex: "salePriceNet",
        align: "left",
        width: 140,
        render: (value) => {
          return (
            <CurrencyFormat
              thousandSeparator
              displayType="text"
              decimalScale={0}
              value={value || 0}
            />
          );
        },
        sorter: true,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
      },
      {
        title: t("revPerUnit"),
        dataIndex: "revPerUnit",
        align: "left",
        width: 140,
        render: (value) => {
          return (
            <CurrencyFormat
              thousandSeparator
              displayType="text"
              decimalScale={0}
              value={value || 0}
            />
          );
        },
        sorter: true,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
      },
    ];
  }, [t]);

  const exportReport = async () => {
    Progress.show(
      {
        method: loadAllPaginatedData,
        params: [getReportDetail, "reportProductPerformance", getPayload()],
      },
      (resp) => {
        const exportedData: any[] = resp as any[];

        const mappedData = exportedData?.map(
          (item: ProductPerformanceItem, index: number) => ({
            No: `${index + 1}`,
            "Item Name": item?.product?.name,
            SKU: item?.product?.sku,
            "Sale Quantity": item.quantity,
            "Total Sales Amount": formatFloatNumber(item?.salePrice || 0),
            "Total Sales Amount (Net)": formatFloatNumber(
              item.salePriceNet || 0
            ),
            "Avg. Rev Per Unit (Net)": formatFloatNumber(item.revPerUnit || 0),
          })
        );
        mappedData.push({
          No: "",
          "Item Name": "",
          SKU: "",
          "Sale Quantity": sumBy(mappedData, "Sale Quantity"),
          "Total Sales Amount": sumBy(mappedData, "Total Sales Amount"),
          "Total Sales Amount (Net)": sumBy(
            mappedData,
            "Total Sales Amount (Net)"
          ),
          "Avg. Rev Per Unit (Net)": sumBy(
            mappedData,
            "Avg. Rev Per Unit (Net)"
          ),
        });
        exportToXLS(mappedData, "export");
      },
      (err: any) => {
        Notifications.showError(err);
      }
    );
  };

  return (
    <div className="relative">
      <AwesomeTableComponent
        columns={tableColumns}
        ref={tableRef}
        source={loadTableData}
        rowKey={(item) => item?.id}
        transformer={(res) => {
          const reportItems =
            res?.data?.reportProductPerformance?.data?.map((item: any) => ({
              ...item,
              created: convertToLocalTime(item.created),
            })) ?? [];
          return reportItems;
        }}
        getTotalItems={(response) => {
          return (
            response?.data?.reportProductPerformance?.pagination?.items ?? 0
          );
        }}
      />
      <Button
        className="absolute bottom-2 left-0"
        iconName="cloud_download"
        onClick={exportReport}
      >
        {t("export")}
      </Button>
    </div>
  );
};

import React, { useEffect, useRef, useMemo, ReactNode, useState } from "react";
import {
  DialogManager,
  DialogComponent,
  Progress,
  ProgressComponent,
} from "d-react-components";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./styles/tailwind.css";
import "./styles/style.scss";
import { Layout } from "./components/common/layout/AppLayout";
import { BrowserRouter, useNavigate, useLocation } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import AppContext from "./components/common/AppContext/AppContext";
import { ApolloProvider } from "@apollo/client";
import { client } from "./network";
import type { Location } from "history";

function App() {
  const dialogRef = useRef();
  const progressRef = useRef<any>();
  const [mainContentRef, setMainContentRef] = useState(null);

  useEffect(() => {
    if (dialogRef.current) {
      DialogManager.initialDialog(dialogRef.current);
    }
  }, [dialogRef]);

  useEffect(() => {
    if (progressRef.current) {
      Progress.initialProgress(progressRef.current);
    }
  }, [progressRef]);

  return (
    // @ts-ignore
    <HelmetProvider>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <QueryParamProvider ReactRouterRoute={RouteV6Adapter}>
            <AppContext.Provider value={{ mainContentRef, setMainContentRef }}>
              <PageTitle />
              <div className="bg-gray-100 min-h-screen">
                <Layout />
                <DialogComponent ref={dialogRef} />
                <ProgressComponent ref={progressRef} />
              </div>
            </AppContext.Provider>
          </QueryParamProvider>
        </BrowserRouter>
      </ApolloProvider>
    </HelmetProvider>
  );
}

const PageTitle = () => {
  return (
    // @ts-ignore
    <Helmet>
      <title>Report Infinity X</title>
    </Helmet>
  );
};

const RouteV6Adapter: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      push: ({ search, state }: Location) => navigate({ search }, { state }),
      replace: ({ search, state }: Location) =>
        navigate({ search }, { replace: true, state }),
    }),
    [navigate]
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return children({ history: adaptedHistory, location });
};

export default App;

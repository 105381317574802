import { TimeUtils } from "d-react-components";
import moment, { Moment } from "moment";
import React, {
  ElementRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useUpdateEffect } from "react-use";
import {
  DAYS_CHANGE,
  DAYS_RANGE,
  FILTER_TYPE,
} from "../../../../constants/report";
import SmartViewHeader from "./SmartViewHeader";
import SmartViewRow from "./SmartViewRow";
import { INCREMENT_UNIT } from "../../../../constants/common";
import { convertRangeMonthToArray, convertRangeQuarterToArray, convertRangeYearToArray } from "../../../../utils/time";

export interface TimeLineSmartViewProps {
  [key: string]: any;
  data: any[];
  filterBy: FILTER_TYPE;
  channels?: any[];
  headerTitle?: string;
}

const TimeLineSmartViewLazy: React.FC<TimeLineSmartViewProps> = ({
  data,
  channels,
  filterBy,
  headerTitle,
  timeRange,
  onRangeChanged,
}) => {
  //current range to display
  const [currentRange, setCurrentRange] = useState<[Moment, Moment]>([
    moment(),
    moment(),
  ]);
  const unit: moment.unitOfTime.DurationConstructor = INCREMENT_UNIT[
    filterBy
  ] as moment.unitOfTime.DurationConstructor;

  useEffect(() => {
    onRangeChanged && onRangeChanged(currentRange);
  }, [currentRange]);

  const rangeArray = useMemo(
    () => {
      if (filterBy === FILTER_TYPE.BY_DAY) {
        return TimeUtils.convertRangeDateToArray(timeRange[0], timeRange[1]);
      }
      if (filterBy === FILTER_TYPE.BY_MONTH) {
        return convertRangeMonthToArray(timeRange[0], timeRange[1]);
      }
      if (filterBy === FILTER_TYPE.BY_QUARTER) {
        return convertRangeQuarterToArray(timeRange[0], timeRange[1]);
      }
      if (filterBy === FILTER_TYPE.BY_YEAR) {
        return convertRangeYearToArray(timeRange[0], timeRange[1]);
      }
      return TimeUtils.convertRangeDateToArray(timeRange[0], timeRange[1]);
    },
    [timeRange, filterBy]
  );

  useEffect(() => {
    if (timeRange) {
      let range: any = [];
      if (filterBy === FILTER_TYPE.BY_DAY) {
        range = TimeUtils.convertRangeDateToArray(timeRange[0], timeRange[1]);
      }
      if (filterBy === FILTER_TYPE.BY_MONTH) {
        range = convertRangeMonthToArray(timeRange[0], timeRange[1]);
      }
      if (filterBy === FILTER_TYPE.BY_QUARTER) {
        range = convertRangeQuarterToArray(timeRange[0], timeRange[1]);
      }
      if (filterBy === FILTER_TYPE.BY_YEAR) {
        range = convertRangeYearToArray(timeRange[0], timeRange[1]);
      }
      const initialTimeRange: any[] = range.slice(DAYS_RANGE * -1);
      setCurrentRange([
        initialTimeRange?.[0],
        initialTimeRange?.[initialTimeRange.length - 1],
      ]);
    }
  }, [timeRange, filterBy]);

  const isDisabledLeft = useMemo(() => {
    return (
      moment(rangeArray[0])?.startOf(unit)?.valueOf() ===
      moment(currentRange[0])?.startOf(unit)?.valueOf()
    );
  }, [rangeArray, unit, currentRange]);

  const isDisabledRight = useMemo(() => {
    return (
      moment(rangeArray[rangeArray.length - 1])
        ?.startOf(unit)
        ?.valueOf() === moment(currentRange[1])?.startOf(unit)?.valueOf()
    );
  }, [rangeArray, unit, currentRange]);

  const handleDecreaseTimeline = () => {
    const timelineBeginning = moment(rangeArray[0]);
    const newStart = moment(currentRange[0]).subtract(DAYS_CHANGE - 1, unit);
    if (newStart < timelineBeginning) {
      const newEnd = moment(timelineBeginning).add(DAYS_RANGE - 1, unit);
      setCurrentRange([timelineBeginning, newEnd]);
    } else {
      const newEnd = moment(newStart).add(DAYS_RANGE - 1, unit);
      setCurrentRange([newStart, newEnd]);
    }
  };

  const handleIncreaseTimeline = () => {
    const timelineEnding = moment(rangeArray[rangeArray.length - 1]);
    const newEnd = moment(currentRange[1]).add(DAYS_CHANGE - 1, unit);
    if (newEnd > timelineEnding) {
      const newStart = moment(timelineEnding).subtract(DAYS_RANGE - 1, unit);
      setCurrentRange([newStart, timelineEnding]);
    } else {
      const newStart = moment(newEnd).subtract(DAYS_RANGE - 1, unit);
      setCurrentRange([newStart, newEnd]);
    }
  };

  return (
    <div className="timeline-smart-view__container bg-white border-start w-100 border-top overflow-y">
      <SmartViewHeader
        timeRange={currentRange}
        currentData={data}
        disabledLeft={isDisabledLeft}
        disabledRight={isDisabledRight}
        onClickLeft={handleDecreaseTimeline}
        onClickRight={handleIncreaseTimeline}
        filterBy={filterBy}
        headerTitle={headerTitle}
      />
      {channels?.map((item, index) => {
        return (
          <SmartViewRow
            key={`${item?.name}-${index}`}
            dataSource={data}
            channel={item}
          />
        );
      })}
    </div>
  );
};

export default TimeLineSmartViewLazy;

export const ROLE_CREATE = "ROLE_CREATE";
export const ROLE_TABLE = "ROLE_TABLE";
export const ROLE_DETAIL = "ROLE_DETAIL";
export const ROLE_UPDATE = "ROLE_UPDATE";
export const ROLE_DELETE = "ROLE_DELETE";
export const CHANNEL = "CHANNEL";
export const VIEW_CHANNEL = "VIEW_CHANNEL";
export const UPDATE_CHANNEL = "UPDATE_CHANNEL";
export const DELETE_CHANNEL = "DELETE_CHANNEL";
export const USERS = "USERS";
export const VIEW_USER = "VIEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const SETTINGS = "SETTINGS";
export const ROLE = "ROLE";
export const ADMIN_QUERY_COMMON = "COMMON";
export const REPORT = "REPORT";
export const VIEW_REPORT_TOTAL_SALES = "VIEW_REPORT_TOTAL_SALES";
export const VIEW_REPORT_CHANNEL_PERFORMANCE = "VIEW_REPORT_CHANNEL_PERFORMANCE";
export const VIEW_REPORT_STORE_PERFORMANCE = "VIEW_REPORT_STORE_PERFORMANCE";
export const VIEW_REPORT_CART_RULE_PERFORMANCE= "VIEW_REPORT_CART_RULE_PERFORMANCE";
export const VIEW_REPORT_PRODUCT_PERFORMANCE = "VIEW_REPORT_PRODUCT_PERFORMANCE";
export const VIEW_REPORT_PRODUCT_PERFORMANCE_PRODUCTION_COST = "VIEW_REPORT_PRODUCT_PERFORMANCE_PRODUCTION_COST";
export const VIEW_REPORT_STAFF_PERFORMANCE = "VIEW_REPORT_STAFF_PERFORMANCE";
export const VIEW_REPORT_PROVINCE_PERFORMANCE =
  "VIEW_REPORT_PROVINCE_PERFORMANCE";
export const VERIFY_PHONE_NUMBER = "VERIFY_PHONE_NUMBER";

export enum RoleType {
  ALL = "ALL",
  CUSTOM = "CUSTOM",
}

export interface IRoleAdminSetting {
  id?: string;
  roleId?: number;
  roleType: RoleType;
  name: string;
  permissions?: string[];
}

export const ADMIN_ROLE_RESOURCE = [
  { id: RoleType.ALL, label: "all" },
  { id: RoleType.CUSTOM, label: "custom" },
];

export const PERMISSIONS = [
  // {
  //   id: CHANNEL,
  //   label: "channels",
  //   subMenu: [
  //     {
  //       id: VIEW_CHANNEL,
  //       label: "view",
  //       description: "viewChannelDescription",
  //     },
  //     {
  //       id: UPDATE_CHANNEL,
  //       label: "update",
  //       description: "updateChannelDescription",
  //     },
  //     {
  //       id: DELETE_CHANNEL,
  //       label: "delete",
  //       description: "deleteChannelDescription",
  //     },
  //   ],
  // },
  {
    id: USERS,
    label: "users",
    subMenu: [
      {
        id: VIEW_USER,
        label: "view",
        description: "viewUserDescription",
      },
      {
        id: UPDATE_USER,
        label: "update",
        description: "updateUserDescription",
      },
      {
        id: DELETE_USER,
        label: "delete",
        description: "deleteUserDescription",
      },
    ],
  },
  {
    id: REPORT,
    label: "report",
    subMenu: [
      {
        id: VIEW_REPORT_TOTAL_SALES,
        label: "viewTotalSaleReport",
        description: "viewTotalSaleReport",
      },
      {
        id: VIEW_REPORT_CHANNEL_PERFORMANCE,
        label: "viewChannelPerformanceReport",
        description: "viewChannelPerformanceReport",
      },
      {
        id: VIEW_REPORT_STORE_PERFORMANCE,
        label: "viewStorePerformanceReport",
        description: "viewStorePerformanceReport",
      },
      {
        id: VIEW_REPORT_PRODUCT_PERFORMANCE,
        label: "viewProductPerformanceReport",
        description: "viewProductPerformanceReport",
      },
      {
        id: VIEW_REPORT_PRODUCT_PERFORMANCE_PRODUCTION_COST,
        label: "viewProductPerformanceReportProductionCost",
        description: "viewProductPerformanceReportProductionCost",
      },
      {
        id: VIEW_REPORT_CART_RULE_PERFORMANCE,
        label: "viewCartRuleReport",
        description: "viewCartRuleReport",
      },
      {
        id: VIEW_REPORT_STAFF_PERFORMANCE,
        label: "viewStaffReport",
        description: "viewStaffReport",
      },
      {
        id: VIEW_REPORT_PROVINCE_PERFORMANCE,
        label: "viewProvinceReport",
        description: "viewProvinceReport",
      },
    ],
  },
  {
    id: SETTINGS,
    label: "settings",
    subMenu: [
      {
        id: ROLE,
        label: "role",
        description: "role",
      },
    ],
  },
  {
    id: VERIFY_PHONE_NUMBER,
    label: "receiveOTP",
  },
];
import {
  Card,
  Notifications,
  Progress,
  Select,
  TimeUtils,
} from "d-react-components";
import React, { useContext, useMemo, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useTranslation } from "react-i18next";
import ReportHeader, {
  FilterDataProps,
} from "../common/report/header/ReportHeader";
import { CHART_COLORS } from "../../constants/common";
import {
  ReportChannelPerformanceDocument,
  useReportChannelPerformanceLazyQuery,
} from "../../network/hooks";
import { filter, forEach, isNil, omit, omitBy, orderBy, sumBy } from "lodash";
import moment, { Moment } from "moment";
import {
  CompareTerm,
  CompareTermCriteria,
  FILTER_TYPE,
  FILTER_TYPE_OPTIONS,
} from "../../constants/report";
import {
  convertToLocalTime,
  convertToLocalTimeDayjs,
  getListOfQuarter,
  groupDateToMonths,
  groupDateToMonthsAlt,
  groupDateToWeeks,
} from "../../utils/time";
import TimeLineSmartView from "../common/report/body/TimeLineSmartView";
import { exportToXLS } from "../../utils/file";
import ReportContext, { TooltipContent } from "./ReportContext";
import AppContext from "../common/AppContext/ReportContext";
import { Channel } from "../../interfaces/common";
import { useApolloClient } from "@apollo/client";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import CompareTimelineView from "../common/report/body/CompareTimelineView";
import {
  getCompareChartParams,
  getDifferenceInPercent,
  getDifferencePercent,
} from "../../utils/chart";
import { Spin } from "antd";
dayjs.extend(quarterOfYear);

const ChannelPerformanceContent = () => {
  const { t } = useTranslation("common");
  const client = useApolloClient();
  const [loadingCompareChart, setLoadingCompareChart] = useState(false);
  const [compareChartData, setCompareChartData] = useState<any[]>([]);
  const [getReport, { data, loading, refetch }] =
    useReportChannelPerformanceLazyQuery();
  const [filterBy, setFilterBy] = useState(FILTER_TYPE.BY_DAY);
  const { selectedChannels, filterData, channelList, compareData, viewMode } =
    useContext(AppContext);
  const [listCompareChannels, setListCompareChannels] = useState<any[]>([]);

  const getBasePayload = () => {
    return {
      orderSource: filterData?.orderSource,
      channels: filterData?.channels?.length
        ? filterData.channels
        : channelList.map((channel: Channel) => channel.refId),
      orderType: filterData.orderType,
      paymentStatus: filterData.paymentStatus,
    };
  };

  const onChangeFilter = (filterData: FilterDataProps) => {
    if (!filterData?.orderSource?.length || viewMode === "compare") {
      return;
    }
    const payload = {
      ...getBasePayload(),
      start: filterData?.currentRange?.[0].startOf("d").toDate(),
      end: filterData?.currentRange?.[1].endOf("d").toDate(),
    };
    setTimeout(() => {
      Progress.show(
        {
          method: getReport,
          params: [
            {
              variables: {
                args: omitBy(payload, isNil),
              },
            },
          ],
        },
        () => {}
      );
    });
    // setFilterData(filterData);
  };

  const onChangeCompare = async () => {
    const { term, termCriteria, numberOfMonths, numberOfYears, formerTime } =
      getCompareChartParams(compareData);
    const payload = {
      paymentStatus: compareData?.paymentStatus,
      orderSource: compareData?.orderSource,
      channels: compareData?.channels?.length
        ? compareData.channels
        : channelList.map((channel: Channel) => channel.refId),
    };
    const formerStart = dayjs(formerTime)
      .subtract(numberOfMonths - 1, "M")
      .startOf("M")
      .startOf("d")
      .toDate();
    const formerEnd = dayjs(formerTime).endOf("M").endOf("d").toDate();
    const latterStart = dayjs(formerTime)
      .subtract(numberOfYears, "y")
      .subtract(numberOfMonths - 1, "M")
      .startOf("M")
      .startOf("d")
      .toDate();
    const latterEnd = dayjs(formerTime)
      .subtract(numberOfYears, "y")
      .endOf("M")
      .endOf("d")
      .toDate();
    const formerPayload: any = {
      ...payload,
      start: formerStart,
      end: formerEnd,
    };
    const latterPayload: any = {
      ...payload,
      start: latterStart,
      end: latterEnd,
    };
    const promises = [
      client.query({
        query: ReportChannelPerformanceDocument,
        variables: { args: omitBy(formerPayload, isNil) },
        fetchPolicy: "no-cache",
      }),
    ];
    if (term === CompareTerm.YOY) {
      promises.push(
        client.query({
          query: ReportChannelPerformanceDocument,
          variables: { args: omitBy(latterPayload, isNil) },
          fetchPolicy: "no-cache",
        })
      );
    }
    const selectedChannels = filter(channelList, (item) =>
      compareData?.channels?.includes(item.refId)
    );
    const listChannels = selectedChannels?.length
      ? selectedChannels
      : channelList;
    setListCompareChannels(listChannels);
    try {
      setLoadingCompareChart(true);
      Promise.all(promises)
        .then((resp) => {
          const formerResult = resp?.[0]?.data?.reportChannelPerformance ?? [];
          const latterResult = resp?.[1]?.data?.reportChannelPerformance ?? [];
          if (
            term === CompareTerm.YOY &&
            termCriteria === CompareTermCriteria.BY_MONTH
          ) {
            const formerMonths = groupDateToMonthsAlt(
              dayjs(formerTime).subtract(numberOfMonths - 1, "M"),
              dayjs(formerTime).endOf("d")
            );
            const latterMonths = groupDateToMonthsAlt(
              dayjs(formerTime)
                .subtract(numberOfYears, "y")
                .subtract(numberOfMonths - 1, "M"),
              dayjs(formerTime).subtract(numberOfYears, "y").endOf("d")
            );
            const formerData = processData(
              formerResult,
              formerMonths,
              listChannels
            );
            const latterData = processData(
              latterResult,
              latterMonths,
              listChannels
            );
            const combinedData = combineData(
              formerData,
              latterData,
              listChannels
            );
            const allData: any[] = [];
            formerData.forEach((item, index) => {
              allData.push(item);
              allData.push(latterData[index]);
              allData.push(combinedData[index]);
            });
            setCompareChartData(allData);
          } else if (
            term === CompareTerm.YOY &&
            termCriteria === CompareTermCriteria.BY_QUARTER
          ) {
            const formerData = processDataByQuarter(
              formerResult,
              getListOfQuarter(formerTime),
              listChannels
            );
            const latterData = processDataByQuarter(
              latterResult,
              getListOfQuarter(formerTime.subtract(numberOfYears, "y")),
              listChannels
            );
            const combinedData = combineData(
              formerData,
              latterData,
              listChannels
            );
            const allData: any[] = [];
            formerData.forEach((item, index) => {
              allData.push(item);
              allData.push(latterData[index]);
              allData.push(combinedData[index]);
            });
            setCompareChartData(allData);
          } else if (term === CompareTerm.QOQ) {
            const formerData = processDataByQuarter(
              formerResult,
              getListOfQuarter(formerTime),
              listChannels,
              true
            );
            setCompareChartData(formerData);
          }
        })
        .finally(() => {
          setLoadingCompareChart(false);
        });
    } catch (err) {
      Notifications.showError(err);
    }
  };

  const lineChartData = useMemo(() => {
    if (!filterData) {
      return [];
    }
    const reportItems =
      data?.reportChannelPerformance.map((item) => ({
        ...item,
        created: convertToLocalTime(item.created),
      })) ?? [];
    let timeRange;
    if (filterBy === FILTER_TYPE.BY_WEEK) {
      const weeks = groupDateToWeeks(
        filterData.currentRange[0],
        filterData.currentRange[1]
      );
      return weeks.map((week, index) => {
        const dataOnTheWeek = filter(reportItems, (reportItem) =>
          moment(reportItem.created).isBetween(week[0], week[1])
        );
        const summedData: { [key: string]: any } = {};
        forEach(selectedChannels ?? [], (currentChannel) => {
          summedData[currentChannel.name as string] = sumBy(
            dataOnTheWeek,
            (data) =>
              data.channelId === currentChannel.refId ? data?.total ?? 0 : 0
          );
        });

        return {
          date: [moment(week[0]), moment(week[1])],
          label: `W${index + 1}`,
          formattedMonth: moment(week[0]).format("MMM"),
          ...summedData,
        };
      });
    }

    if (filterBy === FILTER_TYPE.BY_MONTH) {
      const months = groupDateToMonths(
        filterData.currentRange[0],
        filterData.currentRange[1]
      );
      return months.map((month, index) => {
        const dataOnTheMonth = filter(reportItems, (reportItem) =>
          moment(reportItem.created).isSame(month, "month")
        );
        const summedData: { [key: string]: any } = {};
        forEach(selectedChannels ?? [], (currentChannel) => {
          summedData[currentChannel.name as string] = sumBy(
            dataOnTheMonth,
            (data) =>
              data.channelId === currentChannel.refId ? data?.total ?? 0 : 0
          );
        });

        return {
          date: moment(month),
          label: `M${index + 1}`,
          formattedMonth: moment(month).format("MMM"),
          ...summedData,
        };
      });
    }
    if (filterBy === FILTER_TYPE.BY_DAY) {
      timeRange = TimeUtils.convertRangeDateToArray(
        filterData.currentRange[0],
        filterData.currentRange[1]
      );
      return timeRange.map((time) => {
        const dataOnTheDate = reportItems.filter((reportItem) => {
          return (
            new Date(time).toDateString() ===
            new Date(reportItem.created).toDateString()
          );
        });
        const summedData: { [key: string]: any } = {};
        forEach(selectedChannels ?? [], (currentChannel) => {
          summedData[currentChannel.name as string] = sumBy(
            dataOnTheDate,
            (data) =>
              data.channelId === currentChannel.refId ? data?.total ?? 0 : 0
          );
        });

        return {
          date: moment(time),
          formattedDate: moment(time).format("DD/MM"),
          formattedMonth: moment(time).format("MMM"),
          ...summedData,
        };
      });
    }
    return [];
  }, [data, filterBy, filterData, selectedChannels]);

  const getXAxis = useMemo(() => {
    switch (filterBy) {
      case FILTER_TYPE.BY_DAY:
        return {
          interval: lineChartData.length >= 30 ? 7 : 1,
          key: lineChartData.length >= 30 ? "formattedMonth" : "formattedDate",
        };
      case FILTER_TYPE.BY_WEEK:
        return {
          interval: lineChartData.length >= 4 ? 2 : 1,
          key: "formattedMonth",
        };
      case FILTER_TYPE.BY_MONTH:
        return {
          interval: 0,
          key: "formattedMonth",
        };
    }
  }, [lineChartData, filterBy]);

  const handleExportData = () => {
    let mappedData: any[] = [];
    if (viewMode === "normal") {
      switch (filterBy) {
        case FILTER_TYPE.BY_DAY:
          mappedData = lineChartData.map((item) => ({
            Date: moment(item.date as Moment).format("DD/MM/YYYY"),
            ...omit(item, ["date", "formattedDate", "formattedMonth", "label"]),
          }));
          break;

        case FILTER_TYPE.BY_WEEK:
          mappedData = lineChartData.map((item) => {
            const date: Moment[] = item.date as Moment[];
            return {
              Date: `${moment(date[0]).format("DD/MM/YYYY")}-${moment(
                date[1]
              ).format("DD/MM/YYYY")}`,
              ...omit(item, [
                "date",
                "formattedDate",
                "formattedMonth",
                "label",
              ]),
            };
          });
          break;

        case FILTER_TYPE.BY_MONTH:
          mappedData = lineChartData.map((item) => ({
            Date: moment(item.date as Moment).format("MM/YYYY"),
            ...omit(item, ["date", "formattedDate", "formattedMonth", "label"]),
          }));
          break;
      }
      if (mappedData.length) {
        const totalRow: any = {
          Date: "",
        };
        forEach(mappedData[0], (value, key) => {
          if (key !== "Date") {
            totalRow[key] = sumBy(mappedData, key);
          }
        });
        mappedData.push(totalRow);
      }
      exportToXLS(mappedData, "export");
    } else {
      if (loadingCompareChart) {
        Notifications.showError(t("notification:pleaseWaitDataLoading"));
        return;
      }
      const mappedData: any[] = [];
      const listChannel = compareChartData?.[0]?.values;
      forEach(listChannel, (value, channel) => {
        const channelData: any = {
          Channel: channel,
        };
        if (compareData.term === CompareTerm.YOY) {
          forEach(compareChartData, (item, index) => {
            channelData[`${item.label} (${item.subLabel})`] =
              compareChartData?.[index]?.values?.[channel]?.value;
            if (item.subLabel === "Diff") {
              channelData[`${item.label} (${item.subLabel})(%)`] =
                getDifferencePercent({
                  formerIndex:
                    compareChartData?.[index - 2]?.values?.[channel]?.value,
                  latterIndex:
                    compareChartData?.[index - 1]?.values?.[channel]?.value,
                });
            }
          });
        }
        if (compareData.term === CompareTerm.QOQ) {
          forEach(compareChartData, (item, index) => {
            channelData[`${item.label} (${item.subLabel})`] =
              compareChartData?.[index]?.values?.[channel]?.value;
            if (index > 0) {
              channelData[`${item.label} (Diff)`] = Math.abs(
                compareChartData?.[index - 1]?.values?.[channel]?.value -
                  compareChartData?.[index]?.values?.[channel]?.value
              );
              channelData[`${item.label} (Diff)(%)`] = getDifferencePercent({
                formerIndex:
                  compareChartData?.[index]?.values?.[channel]?.value,
                latterIndex:
                  compareChartData?.[index - 1]?.values?.[channel]?.value,
              });
            }
          });
        }
        mappedData.push(channelData);
      });
      exportToXLS(mappedData, "export");
    }
  };

  return (
    <div>
      <ReportHeader
        onChangeFilter={onChangeFilter}
        handleExportData={handleExportData}
        onChangeCompare={onChangeCompare}
      />
      {viewMode === "normal" && (
        <Card
          title={t("report:channelPerformance")}
          className="h-[500px] mb-4 relative"
        >
          <Select
            dataSource={FILTER_TYPE_OPTIONS}
            value={filterBy}
            onChange={(val) => setFilterBy(val)}
            className="w-[120px] absolute top-3 right-3"
            getLabel={(item) => t(item.label)}
          />
          <Chart
            lineChartData={lineChartData}
            getXAxis={getXAxis}
            selectedChannels={selectedChannels}
          />
        </Card>
      )}
      <Card>
        {viewMode === "normal" && (
          <TimeLineSmartView
            data={lineChartData}
            filterBy={filterBy}
            channels={selectedChannels}
          />
        )}
        {viewMode === "compare" && (
          <>
            {loadingCompareChart && (
              <div className="absolute w-full h-full bg-white/75 flex items-center justify-content-center z-[50]">
                <Spin size="large" />
              </div>
            )}
            <CompareTimelineView
              data={compareChartData}
              channels={listCompareChannels}
              headerTitle={t("channel")}
              groupLength={compareData.term === CompareTerm.YOY ? 3 : 4}
            />
          </>
        )}
      </Card>
    </div>
  );
};
const ChannelPerformance = () => {
  return (
    <ReportContext>
      <ChannelPerformanceContent />
    </ReportContext>
  );
};

const Chart = React.memo(
  ({ lineChartData, selectedChannels, getXAxis }: any) => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={400}
          data={lineChartData}
          margin={{ bottom: 40, top: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {selectedChannels?.map((channel: Channel, index: number) => (
            <Line
              name={channel?.name}
              dataKey={channel.name}
              stroke={CHART_COLORS[index]}
              strokeWidth="2"
              key={index}
              type="monotone"
            />
          ))}
          <XAxis
            dataKey={getXAxis.key}
            stroke="#C0C7CE"
            interval={getXAxis.interval}
          />
          <YAxis
            stroke="#C0C7CE"
            style={{
              fontSize: ".675rem",
            }}
          />
          <Tooltip
            wrapperStyle={{ zIndex: 1000 }}
            content={<TooltipContent />}
          />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
    );
  }
);

const processData = (
  rawData: any[],
  timeRange: any[],
  selectedChannels: any[]
) => {
  const reportItems =
    rawData.map((item) => ({
      ...item,
      created: convertToLocalTimeDayjs(item.created),
    })) ?? [];
  const newData = timeRange.map((time, index) => {
    const summedData: { [key: string]: any } = {};
    
    forEach(selectedChannels ?? [], (currentChannel) => {
      const listReport =
        filter(
          reportItems,
          (item) => item?.channelId === currentChannel?.refId
        ) ?? [];
      const storeData: any = {};
      storeData.value = sumBy(listReport, (reportItem: any) =>
        dayjs(time).isSame(reportItem.created, "M") ? reportItem?.total : 0
      );
      summedData[currentChannel?.name as string] = { ...storeData };
    });

    return {
      date: dayjs(time),
      subLabel: dayjs(time).format("MM/YYYY"),
      label: dayjs(time).format("MMM"),
      values: { ...summedData },
    };
  });
  return newData;
};

const processDataByQuarter = (
  rawData: any[],
  timeRange: any[],
  selectedChannels: any[],
  calDifference: boolean = false
) => {
  const reportItems =
    rawData.map((item) => ({
      ...item,
      created: convertToLocalTimeDayjs(item.created),
    })) ?? [];
  const newData = timeRange.map((dayInQuarter, index) => {
    const quarter = dayjs(dayInQuarter).quarter();
    const summedData: { [key: string]: any } = {};

    forEach(selectedChannels ?? [], (currentChannel) => {
      const listReport =
        filter(
          reportItems,
          (item) => item?.channelId === currentChannel?.refId
        ) ?? [];
      const storeData: any = {};
      storeData.value = sumBy(listReport, (reportItem: any) =>
        dayjs(reportItem.created).quarter() === quarter ? reportItem?.total : 0
      );
      summedData[currentChannel?.name as string] = { ...storeData };
    });

    return {
      date: dayjs(dayInQuarter),
      subLabel: dayjs(dayInQuarter).format("YYYY"),
      label: `${dayjs(dayInQuarter).quarter()}Q`,
      values: { ...summedData },
    };
  });
  if (calDifference) {
    newData.forEach((item, index) => {
      if (index > 0) {
        forEach(selectedChannels ?? [], (currentChannel) => {
          if (!currentChannel) return;
          const { isIncrease, percentDiff } = getDifferenceInPercent({
            formerIndex: newData[index]?.values?.[currentChannel?.name]?.value,
            latterIndex:
              newData[index - 1]?.values?.[currentChannel?.name]?.value,
          });
          newData[index].values[currentChannel?.name].isIncrease = isIncrease;
          newData[index].values[currentChannel?.name].percentDiff = percentDiff;
        });
      }
    });
  }
  return newData;
};

const combineData = (
  formerData: any[],
  latterData: any[],
  listStores: any[]
) => {
  const combinedData = formerData.map((item, index) => {
    const combinedValue: any = {};
    listStores.forEach((store) => {
      if (store) {
        const formerValue = item.values?.[store.name]?.value ?? 0;
        const latterValue =
          latterData?.[index]?.values?.[store.name]?.value ?? 0;
        const data: any = {};
        data.value = Math.abs(formerValue - latterValue);
        const { isIncrease, percentDiff } = getDifferenceInPercent({
          formerIndex: formerValue,
          latterIndex: latterValue,
        });
        data.isIncrease = isIncrease;
        data.percentDiff = percentDiff;
        combinedValue[store.name] = data;
      }
    });
    return {
      subLabel: "Diff",
      label: item.label,
      values: combinedValue,
    };
  });
  return combinedData;
};

export default ChannelPerformance;

import { uniq } from "lodash";
import { useMemo } from "react";
import { useLocalStorage } from "react-use";
import { ADMIN_AUTH_KEY } from "../../../constants/common";

interface PermissibleRenderProps {
  permission: string;
  children?: React.ReactNode;
}

export const getGrantedPermissions = (roles: any) => {
  return roles
    ? uniq(
        roles?.reduce(
          (perms: any, currentValue: any) => [
            ...perms,
            ...(currentValue?.permissions ?? []),
          ],
          []
        )
      )
    : [];
};

export const isGranted = (permission: string) => {
  const adminInfo = JSON.parse(localStorage.getItem(ADMIN_AUTH_KEY) || "{}");
  const permissions = getGrantedPermissions(adminInfo?.roles ?? []);
  return permissions.includes("*") || permissions.includes(permission);
};

export const isGrantedSensitiveData = (permission: string) => {
  const adminInfo = JSON.parse(localStorage.getItem(ADMIN_AUTH_KEY) || "{}");
  const permissions = getGrantedPermissions(adminInfo?.roles ?? []);
  return permissions.includes(permission);
};

export const PermissibleRender: React.FC<PermissibleRenderProps> = ({
  children,
  permission,
}) => {
  const [adminInfo] = useLocalStorage<any>(ADMIN_AUTH_KEY, {});
  const { roles } = adminInfo;
  const permissions = useMemo(() => getGrantedPermissions(roles), [roles]);
  const isGranted =
    permissions.includes("*") || permissions.includes(permission);

  return isGranted ? <>{children}</> : null;
};

export default PermissibleRender;

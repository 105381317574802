import { IAdmin } from "../../../../interfaces/common";
import {
  Card,
  Icon,
  IRowsKey,
  Modal,
  ViewRowInterchange,
} from "d-react-components";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { userUserInfo } from "../UserDetail";
import UserUpdateProfile from "./UserProfileUpdate";
import { AdminStatus } from "../../../../constants/admin";

const UserProfile = () => {
  const [{ user, loading, refetch }] = userUserInfo();
  const [openProfileUpdateDrawer, setOpenProfileUpdateDrawer] = useState(false);
  const { t } = useTranslation();

  const PERSONAL_INFO_KEY: IRowsKey<IAdmin>[] = useMemo(
    () => [
      { id: "userName", label: t("user:userName") },
      { id: "companyId", label: t("user:companyId") },
      { id: "nickName", label: t("user:nickName") },
      { id: "fullName", label: t("user:fullName") },
      {
        id: "accountAccess",
        label: t("user:accountAccess"),
        renderContent: ({ data }) =>
          data ? t("dynamic:enabled") : t("dynamic:disabled"),
      },
      // { id: "employeeId", label: t("user:employeeId") },
    ],
    [t]
  );

  return (
    <div>
      <Card
        title={t("customer:personalInformation")}
        className="mb-3"
        customRight={
          <span
            className="text-red-500 cursor-pointer"
            onClick={() => setOpenProfileUpdateDrawer(true)}
          >
            {t("common:edit")}
          </span>
        }
      >
        <ViewRowInterchange keyList={PERSONAL_INFO_KEY} dataSource={user} />
      </Card>
      {openProfileUpdateDrawer && (
        <Modal
          open={openProfileUpdateDrawer}
          onClose={() => setOpenProfileUpdateDrawer(false)}
          showFooter={false}
          title={t("user:updateProfile")}
        >
          <UserUpdateProfile
            onClose={() => {
              setOpenProfileUpdateDrawer(false);
              refetch();
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default UserProfile;

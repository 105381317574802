import { useTranslation } from "react-i18next";
import { useLocalStorage } from "react-use";
import { ADMIN_AUTH_KEY } from "../../constants/common";
import { IAdmin } from "../../interfaces/common";

const Dashboard = () => {
  const { t } = useTranslation();
  const [admin] = useLocalStorage<IAdmin>(ADMIN_AUTH_KEY);
  return (
    <div className="text-center py-10">
      <img className="m-auto max-w-full" src="/assets/images/welcome.png" alt="Dashboard" />
      <h1 className="text-3xl mb-3">{t("common:haveAGoodDay")} <span className="text-blue-500">{admin?.fullName}</span></h1>
      <p className="m-auto max-w-screen-md">{t("common:dashboardWelcome")}</p>
    </div>
  );
};

export default Dashboard;

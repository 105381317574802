import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import moment from "moment";
import React, { useState } from "react";
import { Dropdown } from "antd";
import { Button, Icon, RadioGroup } from "d-react-components";
import { useTranslation } from "react-i18next";
import { find, isObject } from "lodash";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { REPORT_TYPE } from "../../../../constants/report";

interface DatePickerDropdownProps {
  onChange: any;
  value: any;
  reportType: REPORT_TYPE;
  disabled?: boolean;
}

const DatePickerDropdown = ({
  onChange,
  value,
  reportType,
  disabled,
}: DatePickerDropdownProps) => {
  const listRange = [
    {
      label: "last14Days",
      value: "last14Days",
      range: [moment().subtract(13, "day"), moment()],
    },
    {
      label: "last30Days",
      value: "last30Days",
      range: [moment().subtract(29, "day"), moment()],
    },
    {
      label: "last3Months",
      value: "last3Months",
      range: [moment().subtract(3, "month"), moment()],
    },
    {
      label: "last6Months",
      value: "last6Months",
      range: [moment().subtract(6, "month"), moment()],
    },
    {
      label: "last12Months",
      value: "last12Months",
      range: [moment().subtract(12, "month"), moment()],
    },
    {
      label: "thisMonth",
      value: "thisMonth",
      range: [moment().startOf("month"), moment()],
    },
    {
      label: "thisQuarter",
      value: "thisQuarter",
      range: [moment().startOf("quarter"), moment()],
    },
    {
      label: "thisYear",
      value: "thisYear",
      range: [moment().startOf("year"), moment()],
    },
    {
      label: "custom",
      value: "custom",
      range: [moment().subtract(13, "day"), moment()],
    },
  ];
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(
    reportType === REPORT_TYPE.STORE_PERFORMANCE
      ? listRange[2].value
      : listRange[0].value
  );
  const [selectedRange, setSelectedRange] = useState<any>(value);
  const { t } = useTranslation();

  return (
    <div className="bg-primary mx-3 w-[42px]">
      <Dropdown
        arrow={true}
        destroyPopupOnHide
        trigger={["click"]}
        visible={displayDropdown}
        onVisibleChange={(open) => {
          setDisplayDropdown(open);
        }}
        placement="bottomRight"
        disabled={disabled}
        dropdownRender={() => (
          <div className="bg-white shadow">
            <div className="border-b flex py-3 px-5">
              <h3>{t("dateRange")}</h3>
            </div>
            <div className="w-[420px] bg-white py-3 px-4">
              <div className="mb-2">
                <RadioGroup
                  dataSource={listRange}
                  getValue={(item) => item.value}
                  getLabel={(item) => t(item.label)}
                  numberOfColumns="1"
                  value={selectedOption}
                  onChange={(value) => {
                    setSelectedOption(value);
                    const selected: any = find(listRange, { value });
                    setSelectedRange(selected?.range);
                  }}
                  className="compare-group"
                />
              </div>
              {selectedOption === "custom" && (
                <DateRangePicker
                  onChange={(val) => {
                    setSelectedRange(val);
                  }}
                  value={selectedRange}
                  calendarIcon={<Icon name="event" className="text-gray-500" />}
                  format="dd/MM/y"
                />
              )}
            </div>
            <div className="border-t flex justify-end p-3">
              <Button
                onClick={() => {
                  setDisplayDropdown(false);
                  onChange && onChange(selectedRange);
                }}
              >
                {t("apply")}
              </Button>
            </div>
          </div>
        )}
      >
        <button className="react-daterange-picker__calendar-button react-daterange-picker__button">
          <Icon name="event" className="text-white" />
        </button>
      </Dropdown>
    </div>
  );
};

export default DatePickerDropdown;

import ClassNames from "classnames";
import { Button, Icon, TimeUtils, Badge } from "d-react-components";
import { isEmpty, some } from "lodash";
import moment, { Moment } from "moment";
import React, { InputHTMLAttributes, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FILTER_TYPE } from "../../../../constants/report";
import { convertRangeQuarterToArray, convertRangeYearToArray, groupDateToMonths, groupDateToMonthsAlt, groupDateToWeeks, groupDateToWeeksAlt } from "../../../../utils/time";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

export interface SmartViewHeaderProps extends InputHTMLAttributes<any> {
  [key: string]: any;
  timeRange: [any, any];
  disabledLeft?: boolean;
  disabledRight?: boolean;
  onClickLeft?: () => void;
  onClickRight?: () => void;
  filterBy: FILTER_TYPE;
  headerTitle?: string;
}

interface IDateItem {
  date: string | Moment | Moment[];
  displayType?: FILTER_TYPE;
  label?: string;
}

const DateView: React.FC<IDateItem> = ({ date }) => {
  const currentDate = date as Moment;
  const toDate = moment(currentDate).toDate();
  const dayOfWeek = useMemo(() => {
    let day = "N/A";
    if (date) {
      day = moment(currentDate).format("ddd").toUpperCase();
    }
    return day;
  }, [date]);
  const subDate = useMemo(() => {
    let day = "N/A";
    if (date) {
      day = moment(currentDate).format("DD/MM");
    }
    return day;
  }, [date]);
  const isWeekend = useMemo(() => {
    const day = toDate.getDay();
    return day === 6 || day === 0;
  }, [toDate]);

  const dayClass = ClassNames("text-x-small font-weight-bold", {
    "text-secondary": isWeekend,
  });
  const dateClass = ClassNames("text-x-small text-gray");

  return (
    <div className="col d-flex flex-column align-items-center justify-content-center">
      <div className={dayClass}>{dayOfWeek}</div>
      <div className={dateClass}>{subDate}</div>
    </div>
  );
};

const WeekView: React.FC<IDateItem> = ({ date, label }) => {
  const currentWeek = date as Moment[];
  const fromDate = moment(currentWeek[0]).format("DD/MM");
  const toDate = moment(currentWeek[1]).format("DD/MM");
  return (
    <div className="col d-flex flex-column align-items-center justify-content-center">
      <div className="text-x-small font-weight-bold">{label}</div>
      <div className="text-xx-small text-gray text-center">
        {fromDate}-{toDate}
      </div>
    </div>
  );
};

const MonthView: React.FC<IDateItem> = ({ date, label }) => {
  const month = moment(date as Moment).format("MM/YYYY");
  return (
    <div className="col d-flex flex-column align-items-center justify-content-center">
      <div className="text-x-small font-weight-bold">{label}</div>
      <div className="text-x-small text-gray text-center">{month}</div>
    </div>
  );
};

const QuarterView: React.FC<IDateItem> = ({ date, label }) => {
  const quarter = dayjs(date as any).format("Q/YYYY");
  return (
    <div className="col d-flex flex-column align-items-center justify-content-center">
      <div className="text-x-small font-weight-bold">{label}</div>
      <div className="text-x-small text-gray text-center">Q{quarter}</div>
    </div>
  );
};

const YearView: React.FC<IDateItem> = ({ date, label }) => {
  const year = dayjs(date as any).format("YYYY");
  return (
    <div className="col d-flex flex-column align-items-center justify-content-center">
      <div className="text-x-small font-weight-bold">{label}</div>
      <div className="text-x-small text-gray text-center">{year}</div>
    </div>
  );
};

const DateItem: React.FC<IDateItem> = ({ date, displayType, label }) => {
  return (
    <div
      className="w-100 col border-bottom border-end d-flex align-items-center justify-content-center"
      style={{ minWidth: "50px" }}
    >
      {displayType === FILTER_TYPE.BY_DAY && <DateView date={date} />}
      {displayType === FILTER_TYPE.BY_WEEK && (
        <WeekView date={date} label={label} />
      )}
      {displayType === FILTER_TYPE.BY_MONTH && (
        <MonthView date={date} label={label} />
      )}
      {displayType === FILTER_TYPE.BY_QUARTER && (
        <QuarterView date={date} label={label} />
      )}
      {displayType === FILTER_TYPE.BY_YEAR && (
        <YearView date={date} label={label} />
      )}
    </div>
  );
};

const SmartViewHeader: React.FC<SmartViewHeaderProps> = ({
  timeRange,
  disabledLeft,
  disabledRight,
  onClickLeft,
  onClickRight,
  filterBy,
  currentData,
  headerTitle,
}) => {
  const { t } = useTranslation();
  const buttonClass = ClassNames(
    "bg-primary h-100 d-flex align-items-center justify-content-center hover-pointer position-absolute"
  );
  const disabledLeftClass = ClassNames({ "bg-gray": disabledLeft });
  const disabledRightClass = ClassNames({ "bg-gray": disabledRight });
  const iconClass = ClassNames("text-white d-block event-none");

  const dataSource = useMemo(() => {
    if (filterBy === FILTER_TYPE.BY_DAY) {
      const result: Array<string> = TimeUtils.convertRangeDateToArray(
        timeRange[0],
        timeRange[1]
      );
      return result;
    }
    if (filterBy === FILTER_TYPE.BY_WEEK) {
      return groupDateToWeeks(timeRange[0], timeRange[1]);
    }
    if (filterBy === FILTER_TYPE.BY_MONTH) {
      return groupDateToMonths(timeRange[0], timeRange[1]);
    }
    if (filterBy === FILTER_TYPE.BY_QUARTER) {
      return convertRangeQuarterToArray(timeRange[0], timeRange[1]);
    }
    if (filterBy === FILTER_TYPE.BY_YEAR) {
      return convertRangeYearToArray(timeRange[0], timeRange[1]);
    }
    return [];
  }, [timeRange, filterBy]);

  const renderButtonLeft = (
    <div
      className={`${buttonClass} ${disabledLeftClass}`}
      onClick={() => {
        if (disabledLeft) {
          return null;
        }
        return onClickLeft && onClickLeft();
      }}
      style={{ zIndex: 2 }}
    >
      <Icon name="arrow_left" size="medium" className={iconClass} />
    </div>
  );

  const renderButtonRight = (
    <div
      className={`end-0 ${buttonClass} ${disabledRightClass}`}
      onClick={() => {
        if (disabledRight) {
          return null;
        }
        return onClickRight && onClickRight();
      }}
    >
      <Icon name="arrow_right" size="medium" className={iconClass} />
    </div>
  );
  
  return (
    <div className="smart-view-header__container d-flex w-100">
      <div className="col-3 d-flex align-items-center p-3 border-end border-bottom smart-view-header__search">
        {headerTitle || t("channel")}
      </div>
      <div className="col-9 p-0 d-flex position-relative">
        {renderButtonLeft}
        {dataSource?.length > 0 &&
          dataSource.map((date, index) => {
            return (
              <DateItem
                date={date}
                key={`${date?.toString()}_${index}`}
                displayType={filterBy}
                label={currentData?.[index]?.label}
              />
            );
          })}
        {renderButtonRight}
      </div>
    </div>
  );
};

export default SmartViewHeader;

import React from "react";
import { Button } from "d-react-components";
import BreadCrumb from "./BreadCrumb";
import { DropdownLanguage } from "../header/DropdownLanguage";
import MAIN_MENU from "../MainMenu";
import ButtonSetting from "../header/ButtonSetting";

export interface INavBarProps {
  className?: string;
}

const NavBar: React.FC<INavBarProps> = ({ className }) => {
  return (
    <nav className={`app-layout__navbar ${className}`} id="app-layout__navbar">
      <Button
        onClick={() => {
          document
            .getElementById("app-layout__sidebar")
            ?.classList.toggle("active");
          document
            .getElementById("app-layout__content")
            ?.classList.toggle("active");
        }}
        iconName="notes"
        variant="trans"
      />
      <div className="container-fluid app-layout__nav-bar-content">
        <BreadCrumb menu={MAIN_MENU} />
        <div className="app-layout__nav-bar-right-content">
          <DropdownLanguage />
          <Button iconName="notifications" variant="trans" />
          <ButtonSetting />
        </div>
      </div>
    </nav>
  );
};

export default NavBar;

import {
  AwesomeTableComponent,
  Card,
  IColumnsProps,
  Progress,
  Select,
  TimeUtils,
} from "d-react-components";
import React, { useContext, useMemo, useRef, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useTranslation } from "react-i18next";
import ReportHeader, {
  FilterDataProps,
} from "../common/report/header/ReportHeader";
import { CHART_COLORS, SORT_DIRECTION } from "../../constants/common";
import { useReportCartRulePerformanceLazyQuery } from "../../network/hooks";
import {
  filter,
  forEach,
  groupBy,
  isEmpty,
  isNil,
  isUndefined,
  omitBy,
  orderBy,
  sumBy,
} from "lodash";
import moment from "moment";
import {
  FILTER_TYPE,
  FILTER_TYPE_OPTIONS,
  REPORT_TYPE,
} from "../../constants/report";
import {
  convertToLocalTime,
  groupDateToMonths,
  groupDateToWeeks,
} from "../../utils/time";
import { exportToXLS } from "../../utils/file";
import ReportContext, { TooltipContent } from "./ReportContext";
import AppContext from "../common/AppContext/ReportContext";
import { CartRule, Channel, SortProps } from "../../interfaces/common";
import { moneyFormatter } from "../../utils/money";
import TableSummary from "../common/Table/TableSummary";

interface CartRuleTableProps {
  cartRule?: CartRule;
  channel?: Channel;
  count?: number;
  total?: number;
}

type SortKeys =
  | "cartRule.name"
  | "count"
  | "total"
  | "action"
  | "channel.name"
  | "expires";

const CartRulePerformanceContent = () => {
  const { t } = useTranslation();
  const tableRef = useRef<any>(null);
  const [sort, setSort] = useState<SortProps<SortKeys>>({
    sortBy: "cartRule.name",
    sortDirection: SORT_DIRECTION.ASC,
  });
  const [getReport, { data, loading, refetch }] =
    useReportCartRulePerformanceLazyQuery();
  const [filterBy, setFilterBy] = useState(FILTER_TYPE.BY_DAY);
  const { selectedChannels, filterData, channelList } = useContext(AppContext);
  const onChangeFilter = (filterData: FilterDataProps) => {
    if (!filterData?.orderSource?.length) {
      return;
    }
    const payload = {
      orderSource: filterData?.orderSource,
      channels: filterData?.channels?.length
        ? filterData.channels
        : channelList.map((channel: Channel) => channel.refId),
      orderStatuses: filterData.orderStatus,
      cartRuleIds: filterData?.cartRule?.map(
        (cartRule: CartRule) => cartRule.refId
      ),
      start: filterData?.currentRange?.[0].startOf("d").toDate(),
      end: filterData?.currentRange?.[1].endOf("d").toDate(),
    };
    setTimeout(() => {
      Progress.show(
        {
          method: getReport,
          params: [
            {
              variables: {
                args: omitBy(payload, isNil),
              },
            },
          ],
        },
        () => {}
      );
    });
    // setFilterData(filterData);
  };

  const lineChartData = useMemo(() => {
    if (!filterData) {
      return [];
    }
    const reportItems =
      data?.reportCartRulePerformance.map((item) => ({
        ...item,
        created: convertToLocalTime(item.created),
      })) ?? [];
    let timeRange;
    if (filterBy === FILTER_TYPE.BY_WEEK) {
      const weeks = groupDateToWeeks(
        filterData.currentRange[0],
        filterData.currentRange[1]
      );
      return weeks.map((week, index) => {
        const dataOnTheWeek = filter(reportItems, (reportItem) =>
          moment(reportItem.created).isBetween(week[0], week[1])
        );
        const summedData: { [key: string]: any } = {};
        forEach(selectedChannels ?? [], (currentChannel) => {
          summedData[currentChannel.name as string] = sumBy(
            dataOnTheWeek,
            (data) =>
              data.channelId === currentChannel.refId ? data?.total ?? 0 : 0
          );
        });

        return {
          date: [moment(week[0]), moment(week[1])],
          label: `W${index + 1}`,
          formattedMonth: moment(week[0]).format("MMM"),
          ...summedData,
        };
      });
    }

    if (filterBy === FILTER_TYPE.BY_MONTH) {
      const months = groupDateToMonths(
        filterData.currentRange[0],
        filterData.currentRange[1]
      );
      return months.map((month, index) => {
        const dataOnTheMonth = filter(reportItems, (reportItem) =>
          moment(reportItem.created).isSame(month, "month")
        );
        const summedData: { [key: string]: any } = {};
        forEach(selectedChannels ?? [], (currentChannel) => {
          summedData[currentChannel.name as string] = sumBy(
            dataOnTheMonth,
            (data) =>
              data.channelId === currentChannel.refId ? data?.total ?? 0 : 0
          );
        });

        return {
          date: moment(month),
          label: `M${index + 1}`,
          formattedMonth: moment(month).format("MMM"),
          ...summedData,
        };
      });
    }
    if (filterBy === FILTER_TYPE.BY_DAY) {
      timeRange = TimeUtils.convertRangeDateToArray(
        filterData.currentRange[0],
        filterData.currentRange[1]
      );
      return timeRange.map((time) => {
        const dataOnTheDate = reportItems.filter((reportItem) => {
          return (
            new Date(time).toDateString() ===
            new Date(reportItem.created).toDateString()
          );
        });
        const summedData: { [key: string]: any } = {};
        forEach(selectedChannels ?? [], (currentChannel) => {
          summedData[currentChannel?.name as string] = sumBy(
            dataOnTheDate,
            (data) =>
              data.channelId === currentChannel.refId ? data?.total ?? 0 : 0
          );
        });

        return {
          date: moment(time),
          formattedDate: moment(time).format("DD/MM"),
          formattedMonth: moment(time).format("MMM"),
          ...summedData,
        };
      });
    }
    return [];
  }, [data, filterBy, filterData, selectedChannels]);

  const tableColumns: IColumnsProps = useMemo(
    () => [
      {
        title: t("no"),
        dataIndex: "index",
        align: "left",
        width: 50,
        render: (value, item, index) => {
          return isUndefined(index) ? value : index + 1;
        },
      },
      {
        title: t("name"),
        dataIndex: "cartRule.name",
        align: "left",
        width: 300,
        render: (value, item) => {
          return item?.cartRule?.name;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        sorter: (a, b) => {
          return a?.cartRule?.name.localeCompare(b?.cartRule?.name);
        },
      },
      {
        title: t("orders"),
        dataIndex: "count",
        align: "left",
        width: 80,
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        sorter: (a, b) => {
          return a?.count - b?.count;
        },
      },
      {
        title: t("amountDiscounted"),
        dataIndex: "total",
        align: "left",
        width: 100,
        render: (value, item) => {
          return moneyFormatter.format(value);
        },
        sorter: (a, b) => {
          return a?.total - b?.total;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
      },
      {
        title: t("action"),
        dataIndex: "cartRule.action",
        align: "left",
        width: 100,
        render: (value, item) => {
          return t(item?.cartRule?.action);
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        sorter: (a, b) => {
          return a?.cartRule?.action.localeCompare(b?.cartRule?.action);
        },
      },
      {
        title: t("channel"),
        dataIndex: "channel",
        align: "left",
        width: 100,
        render: (value) => {
          return value?.name;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        sorter: (a, b) => {
          return a?.channel?.name.localeCompare(b?.channel?.name);
        },
      },
      {
        title: t("expires"),
        dataIndex: "cartRule.expires",
        align: "left",
        width: 100,
        render: (value, item) => {
          return item?.cartRule?.expires
            ? moment(item?.cartRule?.expires).format("DD/MM/YYYY HH:mm")
            : "";
        },
        sorter: (a, b) => {
          return (
            new Date(a?.cartRule?.expires) > new Date(b?.cartRule?.expires)
          );
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
      },
    ],
    [t]
  );

  const tableData: CartRuleTableProps[] = useMemo(() => {
    const transformedData =
      data?.reportCartRulePerformance.map((item) => ({
        ...item,
        created: convertToLocalTime(item.created),
      })) ?? [];
    const groupedDataByCartRule = groupBy(
      transformedData,
      (item) => item?.cartRule?.refId
    );
    const tableRows: any[] = [];
    forEach(groupedDataByCartRule, (value, key) => {
      tableRows.push({
        cartRule: value?.[0]?.cartRule,
        channel: value?.[0]?.channel,
        total: sumBy(value, "total"),
        count: value.length,
      });
    });
    return orderBy(
      tableRows,
      sort.sortBy,
      sort.sortDirection === SORT_DIRECTION.ASC ? "asc" : "desc"
    );
  }, [data, sort]);

  const sumData = useMemo(() => {
    return isEmpty(tableData)
      ? {}
      : {
          index: t("sum"),
          total: sumBy(tableData, "total"),
          count: sumBy(tableData, "count"),
        };
  }, [tableData]);

  const getXAxis = useMemo(() => {
    switch (filterBy) {
      case FILTER_TYPE.BY_DAY:
        return {
          interval: lineChartData.length >= 30 ? 7 : 1,
          key: lineChartData.length >= 30 ? "formattedMonth" : "formattedDate",
        };
      case FILTER_TYPE.BY_WEEK:
        return {
          interval: lineChartData.length >= 4 ? 2 : 1,
          key: "formattedMonth",
        };
      case FILTER_TYPE.BY_MONTH:
        return {
          interval: 0,
          key: "formattedMonth",
        };
    }
  }, [lineChartData, filterBy]);

  const handleExportData = () => {
    const mappedData = tableData?.map((value, index) => ({
      No: `${index + 1}`,
      Name: value?.cartRule?.name,
      Orders: value?.count,
      "Amount Discounted": moneyFormatter.format(value.total as number),
      Action: t(value?.cartRule?.action as string),
      Channel: value?.channel?.name,
      Expires: moment(value?.cartRule?.expires).format("DD/MM/YYYY HH:mm"),
    }));
    mappedData.push({
      No: "",
      Name: "",
      Orders: sumBy(mappedData, "Orders"),
      "Amount Discounted": moneyFormatter.format(sumBy(tableData, "total")),
      Action: "",
      Channel: "",
      Expires: "",
    });
    exportToXLS(mappedData, "export");
  };

  return (
    <div>
      <ReportHeader
        onChangeFilter={onChangeFilter}
        handleExportData={handleExportData}
        reportType={REPORT_TYPE.CARTRULE_PERFORMANCE}
      />
      <Card
        title={t("cartRulePerformance")}
        className="h-[400px] mb-4 relative"
      >
        <Select
          dataSource={FILTER_TYPE_OPTIONS}
          value={filterBy}
          onChange={(val) => setFilterBy(val)}
          className="w-[120px] absolute top-3 right-3"
          getLabel={(item) => t(item.label)}
        />
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={400}
            data={lineChartData}
            margin={{ bottom: 40, top: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            {selectedChannels?.map((channel: Channel, index: number) => (
              <Line
                name={channel?.name}
                dataKey={channel.name}
                stroke={CHART_COLORS[index]}
                strokeWidth="2"
                key={index}
                type="monotone"
              />
            ))}
            <XAxis
              dataKey={getXAxis?.key}
              stroke="#C0C7CE"
              interval={getXAxis?.interval}
            />
            <YAxis
              stroke="#C0C7CE"
              style={{
                fontSize: ".675rem",
              }}
            />
            <Tooltip
              wrapperStyle={{ zIndex: 1000 }}
              content={<TooltipContent />}
            />
            <Legend />
          </LineChart>
        </ResponsiveContainer>
      </Card>
      <Card title={t("report:dataTable")}>
        <AwesomeTableComponent
          dataSource={tableData}
          columns={tableColumns}
          ref={tableRef}
          summary={() => <TableSummary data={sumData} columns={tableColumns} />}
        />
      </Card>
    </div>
  );
};
const CartRulePerformance = () => {
  return (
    <ReportContext>
      <CartRulePerformanceContent />
    </ReportContext>
  );
};

export default CartRulePerformance;

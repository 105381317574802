/* eslint-disable react/no-children-prop */
import * as Yup from "yup";
import {
  Header,
  InputText,
  Notifications,
  Select,
  TreeDataUtils,
  ViewCollapse,
  ViewRow,
} from "d-react-components";
import { useFormik } from "formik";
import React, { useMemo } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { menuFlatter } from "../../../utils/menu";
import {
  ADMIN_ROLE_RESOURCE,
  IRoleAdminSetting,
  PERMISSIONS,
  RoleType,
} from "../../../interfaces/admin";
import { Path } from "../../common/layout/Paths";
import Tree from "../../common/Tree/Tree";
import { useCreateRoleMutation, useFindOneRoleQuery, useUpdateRoleMutation } from "../../../network/hooks";

export interface ISettingRoleDetailProps {
  [key: string]: any;
}

export const parentIdKey = "parentId";

export const SettingRoleDetail: React.FC<ISettingRoleDetailProps> = () => {
  const { t } = useTranslation();
  const { roleId } = useParams<any>();
  const flatMenu = menuFlatter(PERMISSIONS);
  const [createRole] = useCreateRoleMutation();
  const [updateRole] = useUpdateRoleMutation();
  const { data: roleDataResp } = useFindOneRoleQuery({
    skip: !roleId,
    variables: {
      id: roleId as string,
    },
  });
  const roleData = roleDataResp?.findOneRole;

  const SettingRoleSchema = Yup.object().shape({
    name: Yup.string().required(t(`common:fieldRequired`)),
    roleType: Yup.string().required(t(`common:fieldRequired`)),
  });

  const treeMenuData = useMemo(() => {
    const transformList = flatMenu.map((item: any) => ({
      ...item,
      title: t(`dynamic:${item?.label}`),
      key: item?.id ?? "",
      children: [],
    }));
    return TreeDataUtils.mapListDataToTree(transformList, parentIdKey);
  }, [PERMISSIONS, t]);

  const roleForm = useFormik<IRoleAdminSetting>({
    initialValues: { ...(roleData || ({} as any)) },
    onSubmit: () => {
      handleOnSubmit();
    },
    validationSchema: SettingRoleSchema,
    enableReinitialize: true,
    validateOnChange: false,
  });

  const { values, errors, handleSubmit, handleChange, setFieldValue } =
    roleForm;

  const { name, roleType, permissions } = values;
  const navigate = useNavigate();

  const handleOnSubmit = () => {
    const payload: IRoleAdminSetting = {
      name,
      roleType,
      permissions,
    };
    if (roleType === RoleType.ALL) {
      payload.permissions = ["*"];
    }
    if (roleId) {
      updateRole({
        variables: {
          id: roleId,
          input: payload,
        } as any,
      }).then(
        (resp) => {
          Notifications.showSuccess(t(`notification:updateRoleSuccess`));
        },
        (err) => {
          Notifications.showError(err);
        }
      );
    } else {
      createRole({
        variables: {
          input: payload,
        } as any,
      }).then(
        (resp) => {
          Notifications.showSuccess(t(`notification:createRoleSuccess`));
          const dataId = resp?.data?.createRole?.id;
          navigate(
            generatePath(Path.ROLE_DETAIL, { roleId: dataId as string })
          );
        },
        (err) => {
          Notifications.showError(err);
        }
      );
    }
  };

  const handleOnCheck = (checked: any, info: any) => {
    setFieldValue("roleType", RoleType.CUSTOM);
    setFieldValue("permissions", checked);
  };

  return (
    <>
      <Header
        title={roleId ? roleData?.name ?? "" : t(`user:role`)}
        onSave={() => {
          handleSubmit();
        }}
        onCancel={() => {
          navigate(-1);
        }}
        // onDelete={() => {}}
      />
      <ViewCollapse className="my-4" label={t(`common:general`)}>
        <ViewRow
          label={t(`common:name`)}
          children={
            <InputText
              name="name"
              value={name}
              error={errors.name}
              onChange={handleChange}
            />
          }
        />
      </ViewCollapse>
      <ViewCollapse className="my-4" label={t(`user:updateRoleResource`)}>
        <ViewRow
          label={t(`user:access`)}
          children={
            <Select
              dataSource={ADMIN_ROLE_RESOURCE.map((i) => ({
                ...i,
                label: t(`dynamic:${i.label}`),
              }))}
              value={roleType}
              onChange={(v) => setFieldValue("roleType", v)}
              error={errors.roleType}
            />
          }
        />
        {values.roleType !== RoleType.ALL && (
          <ViewRow
            label={" "}
            className="my-5"
            children={
              <Tree
                dataSource={treeMenuData}
                showIcon={false}
                checkable
                draggable={false}
                onCheck={handleOnCheck}
                checkedKeys={permissions ?? []}
              />
            }
          />
        )}
      </ViewCollapse>
    </>
  );
};

export default SettingRoleDetail;

import { find, orderBy } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { CompareValue, DAYS_RANGE } from "../../constants/report";
import { Channel } from "../../interfaces/common";
import { ProductPerformanceFilterProductType, useChannelListQuery } from "../../network/hooks";
import { moneyFormatter } from "../../utils/money";
import ReportCxt from "../common/AppContext/ReportContext";
import { FilterDataProps } from "../common/report/header/ReportHeader";
import { useLocation } from "react-router";
import { Path } from "../common/layout/Paths";
import { FOC_TYPE } from "../../constants/order";

export const TooltipContent = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="pointer-events-auto z-50 bg-white p-3 border">
        <p className="text-bold mb-1">{label}</p>
        <div className="h-[300px] overflow-auto">
          {orderBy(payload, "value", "desc").map((item: any, index: number) => (
            <p
              className="label mb-1"
              style={{ color: item.color }}
              key={index}
            >{`${item.name}: ${moneyFormatter.format(item.value)}`}</p>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export type ViewMode = "normal" | "compare";

const ReportContext: React.FC = ({ children }) => {
  const location = useLocation();
  const defaultRange =
    location.pathname === Path.REPORT_STORE_PERFORMANCE
      ? [moment().subtract(2, "month"), moment()]
      : [moment().subtract(DAYS_RANGE - 1, "days"), moment()];
  const [selectedChannels, setSelectedChannels] = useState<Channel[]>([]);
  const [viewMode, setViewMode] = useState<ViewMode>("normal");
  const [compareData, setCompareData] = useState<any>();
  const [filterData, setFilterData] = useState<FilterDataProps>({
    currentRange: defaultRange as any,
    orderSource: [],
    focType: FOC_TYPE.EXCLUDE_FOC,
    filterProductType: ProductPerformanceFilterProductType.ExcludingGroupProduct
  });
  const { data: dataChannelList, refetch } = useChannelListQuery({
    variables: {
      args: {
        limit: 1000,
        page: 1,
      },
    },
  });

  useEffect(() => {}, [filterData.orderSource]);

  const channelList = useMemo(() => {
    const list = dataChannelList?.findChannelsByAdmin?.data;
    if (!filterData.orderSource?.length) {
      return list;
    }
    return list?.filter((item) =>
      item?.orderSource?.some((source) => {
        return filterData.orderSource?.includes(source);
      })
    );
  }, [filterData.orderSource, dataChannelList]);

  useEffect(() => {
    if (filterData?.channels?.length) {
      setSelectedChannels(
        (filterData?.channels?.map((channelId) =>
          find(channelList, (item) => item.refId === channelId)
        ) ?? []) as Channel[]
      );
    } else if (filterData.orderSource?.length) {
      setSelectedChannels(channelList as Channel[]);
    }
  }, [filterData.channels, filterData.orderSource, channelList]);

  return (
    <ReportCxt.Provider
      value={{
        channelList: channelList || [],
        allChannels: dataChannelList || [],
        filterData,
        setFilterData,
        selectedChannels,
        compareData,
        setCompareData,
        viewMode,
        setViewMode,
        refetchChannel: refetch,
      }}
    >
      {children}
    </ReportCxt.Provider>
  );
};

export default ReportContext;

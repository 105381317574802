import _, { isNumber } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import { Channel } from "../../../../interfaces/common";
import { moneyFormatter } from "../../../../utils/money";

export interface ISmartViewRowProps {
  [key: string]: any;
  channel?: Channel;
}

export interface ISmartViewCellProps {
  data?: string;
}

export interface ITimelineRecord {
  id?: string;
  date: any;
}

export const SmartViewCell: React.FC<ISmartViewCellProps> = ({ data }) => {
  return (
    <div
      className="w-100 col border-bottom border-end d-flex  align-items-center justify-content-center text-xs"
      style={{ minWidth: "50px" }}
    >
      {isNumber(data) ? moneyFormatter.format(data) : data}
    </div>
  );
};

const SmartViewRow: React.FC<ISmartViewRowProps> = ({
  dataSource,
  channel,
}) => {
  return (
    <div className="d-flex w-100">
      <div
        className="col-3 border-end border-bottom d-flex align-items-center text-nowrap"
        style={{ minHeight: "80px" }}
      >
        {channel?.name}
      </div>
      <div className="col-9 p-0 d-flex w-100">
        {dataSource &&
          dataSource?.length > 0 &&
          dataSource.map((item: any, index: number) => {
            return (
              <SmartViewCell
                key={`_${index}`}
                data={item[channel?.name as string]}
              />
            );
          })}
      </div>
    </div>
  );
};

export default SmartViewRow;

/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { PDFExport } from "@progress/kendo-react-pdf";
import { Switch } from "antd";
import {
  Button,
  Card,
  InputText,
  Modal,
  Notifications,
  TimeUtils,
} from "d-react-components";
import { FormikProps } from "formik";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAdmin, IAdminInput } from "../../../../interfaces/common";
import { useRandomTwoFaAuthenticationLazyQuery, useUpdateUserByAdminMutation } from "../../../../network/hooks";
import QrCodeView from "./QrCodeView";

export interface IAdminAuthenticationProps {
  adminForm?: FormikProps<IAdminInput>;
  isDetail?: boolean;
  isModal?: boolean;
  userInfo?: IAdmin;
  onClose?: any;
}

export const AdminAuthentication: React.FC<IAdminAuthenticationProps> = ({
  adminForm,
  isDetail,
  userInfo,
  isModal = false,
  onClose,
}) => {
  const { values, errors, setFieldValue, setValues } = adminForm || {};
  const {
    codeTwoFaAuthentication,
    hasTwoFaAuthentication,
    qrCodeTwoFaAuthentication,
    nickName,
    companyId,
    fullName,
    userName,
  } = values || userInfo || {};
  const showGenerateButton = useMemo(() => {
    return hasTwoFaAuthentication && !codeTwoFaAuthentication;
  }, [hasTwoFaAuthentication, codeTwoFaAuthentication]);
  const showCodeView = useMemo(() => {
    return hasTwoFaAuthentication && codeTwoFaAuthentication;
  }, [hasTwoFaAuthentication, codeTwoFaAuthentication]);
  const [openExportModal, setOpenExportModal] = useState(false);
  const pdfUserAuthRef = useRef();
  const [isPrinting, seTisPrinting] = useState(false);
  const { t } = useTranslation();
  const [getAuthCode] = useRandomTwoFaAuthenticationLazyQuery();
  const [updateUser] = useUpdateUserByAdminMutation();

  const generateRandomCode = async () => {
    const authCode = await getAuthCode({
      variables: { nickName: userName || " " },
    });
    const { qrCodeTwoFaAuthentication, codeTwoFaAuthentication } =
      authCode?.data?.randomTwoFaAuthentication ?? {};
    setFieldValue &&
      setFieldValue("qrCodeTwoFaAuthentication", qrCodeTwoFaAuthentication);
    setFieldValue &&
      setFieldValue("codeTwoFaAuthentication", codeTwoFaAuthentication);
  };

  const renderGenerateCodeView = () => {
    return (
      <div>
        <p className="mt-3">{t("user:adminAuthenticatorDescription")}</p>

        {isDetail && (
          <div className="mb-3">
            <label className="text-label">{t("common:status")}</label>
            <div>
              {userInfo?.hasTwoFaAuthentication
                ? `${t("dynamic:enabled")}`
                : `${t("dynamic:disabled")}`}
            </div>
          </div>
        )}
        <InputText
          label={
            <div className="flex-center-y justify-content-between">
              <div>{t("user:secretCode")}</div>
              {!isDetail && (
                <Button
                  variant="trans"
                  color="blue"
                  size="x-small"
                  onClick={() => generateRandomCode()}
                >
                  {t("user:regenerate")}
                </Button>
              )}
            </div>
          }
          disabled
          value={codeTwoFaAuthentication}
        />
        {qrCodeTwoFaAuthentication && (
          <div className="my-3">
            <div className="label mb-3">{t("user:qrCode")}</div>
            <QrCodeView
              name={userName as string}
              secretCode={codeTwoFaAuthentication as string}
            />
          </div>
        )}
        <Button iconName="download" onClick={() => setOpenExportModal(true)}>
          {t("user:downloadPdf")}
        </Button>
      </div>
    );
  };

  const handleSubmit = () => {
    try {
      const {
        hasTwoFaAuthentication,
        codeTwoFaAuthentication,
        qrCodeTwoFaAuthentication,
      } = values || {};
      updateUser({
        variables: {
          input: {
            id: values?.id,
            hasTwoFaAuthentication,
            codeTwoFaAuthentication,
            qrCodeTwoFaAuthentication,
          },
        },
      }).then(
        (resp) => {
          Notifications.showSuccess(t("notification:userProfileUpdatedSuccessfully"));
          onClose && onClose();
        },
        (err) => {
          Notifications.showError(err);
        }
      );
    } catch (err) {
      Notifications.showError(err);
    }
  };

  const renderGenerateButton = () => {
    return (
      <Button className="my-3" onClick={() => generateRandomCode()}>
        {t("user:generate")}
      </Button>
    );
  };

  const beforePDFPrinting = () => {
    const cc = document.getElementsByClassName("user-detail__auth-code");
    for (let i = 0; i < cc.length; i++) {
      const svg = cc[i];
      const rect = svg.getBoundingClientRect();

      const img = document.createElement("img");
      img.src = `data:image/svg+xml;base64,${btoa(
        unescape(encodeURIComponent(svg.outerHTML))
      )}`;
      //@ts-ignore
      img.style = `height:100px;width:100px;margin-top:16px`;
      img.className = "remove-after-print";
      //@ts-ignore
      svg.parentNode.insertBefore(img, svg);
    }
  };

  const onClickExport = async () => {
    seTisPrinting(true);
    beforePDFPrinting();
    //@ts-ignore
    pdfUserAuthRef.current.save();
    seTisPrinting(false);
    afterPDFPrinting();
    return Promise.resolve();
  };

  const afterPDFPrinting = () => {
    setTimeout(() => {
      const imagePrintE = document.querySelector(".remove-after-print");
      //@ts-ignore
      imagePrintE.parentElement.removeChild(imagePrintE);
    }, 300);
  };

  const renderInfoRow = (title: string, content: string) => (
    <div className="d-flex border-bottom mt-2 py-2">
      <text className="text-bold w-50">{title}</text>
      <text className="w-50">{content}</text>
    </div>
  );

  const content = () => {
    return (
      <>
        <div className="label mb-3">{t("user:authentication")}</div>
        {!isDetail && (
          <Switch
            checked={hasTwoFaAuthentication}
            onChange={() =>
              setFieldValue &&
              setFieldValue("hasTwoFaAuthentication", !hasTwoFaAuthentication)
            }
          />
        )}
        {showGenerateButton && !isDetail && renderGenerateButton()}
        {showCodeView && renderGenerateCodeView()}
        {openExportModal && (
          <Modal
            title={t("common:export")}
            saveText={t("common:export")}
            open={openExportModal}
            onClose={() => setOpenExportModal(false)}
            onSave={onClickExport}
          >
            <PDFExport
              paperSize="auto"
              ref={pdfUserAuthRef as any}
              margin={20}
              fileName={`${companyId}_${fullName}_${TimeUtils.convertMiliToDate(
                new Date().getTime()
              )}`}
              author="Lotus Team"
            >
              <div className="d-flex flex-column">
                <h4 style={{ fontWeight: "bold" }}>
                  {t("user:workInfinityXAdvancedAuthentication")}
                </h4>
                <div className="mt-3">{t("user:exportAuthenDescription")}</div>
                {renderInfoRow(t("user:userName"), userName || "")}
                {renderInfoRow(t("user:fullName"), fullName || "")}
                {renderInfoRow(t("user:nickName"), nickName || "")}
                {renderInfoRow(t("user:employeeId"), companyId as any)}
                <div className="d-flex">
                  <QrCodeView
                    className="user-detail__auth-code"
                    name={nickName || ""}
                    size={isPrinting ? 0 : 100}
                    secretCode={codeTwoFaAuthentication as any}
                  />
                </div>
                <text className="mt-3">{t("user:exportAuthenFooter")}</text>
              </div>
            </PDFExport>
          </Modal>
        )}
      </>
    );
  };

  return isModal ? (
    <>
      {content()}
      <div className="flex align-items-end justify-content-end">
        <Button
          title={t("common:save")}
          className="w-full mb-2 text-sm"
          onClick={() => handleSubmit()}
          color="primary"
        >
          {t("common:save")}
        </Button>
      </div>
    </>
  ) : (
    <Card customHeader={<></>}>{content()}</Card>
  );
};

export default AdminAuthentication;

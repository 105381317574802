import React from "react";
import { Tree as TreeAnt } from "antd";
import { TreeProps } from "antd/lib/tree";

export interface ITreeProps extends Omit<TreeProps, "treeData"> {
  dataSource: TreeProps["treeData"];
}

export const Tree: React.FC<ITreeProps> = ({
  dataSource = [],
  onSelect,
  defaultExpandedKeys = [],
  defaultSelectedKeys,
  onDrop,
  ...props
}) => {
  return (
    <TreeAnt
      defaultExpandedKeys={defaultExpandedKeys}
      onSelect={onSelect}
      treeData={dataSource}
      defaultSelectedKeys={defaultSelectedKeys}
      onDrop={onDrop}
      {...props}
    />
  );
};

export default Tree;

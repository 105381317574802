export const loadAllPaginatedData = async (
  fetchFn: any,
  dataKey: string,
  customParams: any
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let page = 1;
      const limit = 200;
      let hasMoreData = true;
      const data = [];
      while (hasMoreData) {
        const resp = await fetchFn({
          variables: {
            args: {
              page,
              limit,
              ...customParams,
            },
          },
        });
        const chunkData = resp?.data?.[dataKey]?.data ?? [];
        data.push(...chunkData);
        if (resp?.data?.[dataKey]?.pagination) {
          const { currentPage, total } = resp?.data?.[dataKey]?.pagination;
          hasMoreData = currentPage < total;
          page++;
        }
      }
      resolve(data);
    } catch (err: any) {
      reject(err);
    }
  });
};

import {
  AwesomeTableComponent,
  Button,
  IColumnsProps,
  InputTextSearch,
  Notifications,
} from "d-react-components";
import React, { ElementRef, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useUnassignedChannelListLazyQuery,
  useUpdateUserByAdminMutation,
} from "../../../../network/hooks";
import { userUserInfo } from "../UserDetail";

const UserChannelUpdate = ({ onClose }: { onClose: () => void }) => {
  const [refetch] = useUnassignedChannelListLazyQuery();
  const [{ user, loading }] = userUserInfo();
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const tableRef = useRef<any>(null);
  const [selectedRows, setSelectedRows] = useState<Array<any>>(
    (user?.channels ?? []).map((i: any) => i.id)
  );
  const [updateUser] = useUpdateUserByAdminMutation();

  const loadTableData = (paging: any) => {
    return refetch({
      variables: {
        userId: user?.id as string,
        args: {
          page: paging.pageIndex,
          limit: paging.pageSize,
          search: {
            query,
          },
        },
      },
    });
  };
  const tableColumns: IColumnsProps = useMemo(
    () => [
      {
        title: t("common:name"),
        dataIndex: "name",
        align: "left",
        width: 60,
        render: (data, item) => data,
      },
      {
        title: t("common:domain"),
        dataIndex: "domain",
        align: "left",
        width: 75,
        render: (data: any, item: any, index) => data,
      },
    ],
    [t]
  );

  const handleSubmit = async () => {
    try {
      updateUser({
        variables: {
          input: {
            id: user?.id as string,
            channels: selectedRows,
          },
        },
      }).then(
        (resp) => {
          Notifications.showSuccess(
            t("notification:userProfileUpdatedSuccessfully")
          );
          onClose && onClose();
        },
        (err) => {
          Notifications.showError(err);
        }
      );
    } catch (err) {
      Notifications.showError(err);
    }
  };

  return (
    <div>
      <InputTextSearch
        className="mb-3"
        onChange={(e: any) => setQuery(e?.target?.value)}
        value={query}
        onSubmit={() => {
          tableRef.current && tableRef.current.refresh();
        }}
      />
      {/* @ts-ignore */}
      <AwesomeTableComponent
        source={loadTableData}
        transformer={(res) => {
          return res?.data?.findUnassignedChannel?.data ?? [];
        }}
        getTotalItems={(res) => {
          return res?.data?.findUnassignedChannel?.pagination?.items ?? 0;
        }}
        columns={tableColumns}
        ref={tableRef}
        showSelectColumn={false}
        selectingRows={selectedRows}
        rowSelection={{
          onChange: (value) => setSelectedRows(value),
          selectedRowKeys: selectedRows,
          preserveSelectedRowKeys: true,
        }}
      />
      <div>
        <div className="flex align-items-end justify-content-end">
          <Button
            title={t("common:submit")}
            className="w-full mb-2 text-sm"
            onClick={() => handleSubmit()}
            color="primary"
          >
            {t("common:save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserChannelUpdate;

import { Select, SelectProps } from "d-react-components";
import React from "react";
import { useFindAllRoleQuery } from "../../../network/hooks";

export const SelectRole: React.FC<SelectProps> = (props) => {
  const { data } = useFindAllRoleQuery({
    variables: { pagination: { limit: 1000, page: 1 } },
  });

  return (
    <Select
      getLabel={(item) => item.name}
      dataSource={data?.findAllRole?.data ?? []}
      {...props}
    />
  );
};

export default SelectRole;

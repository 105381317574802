import { Drawer, Select, Button } from "d-react-components";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { AdminStatuses } from "../../constants/admin";
import { UserFilter as UserFilterValues } from "../../interfaces/user";
import SelectRole from "../common/SelectRole/SelectRole";

interface UserFilterProps {
  open: boolean;
  onClose: (values?: UserFilterValues) => void;
  filterData: Partial<UserFilterValues>;
}

export const UserFilter: React.FC<UserFilterProps> = ({
  open,
  onClose,
  filterData,
}) => {
  const { t } = useTranslation();
  const customerFilterForm = useFormik<UserFilterValues>({
    initialValues: {
      ...filterData,
    },
    onSubmit: () => {
      return;
    },
  });
  const { values, errors, handleSubmit, setFieldValue } = customerFilterForm;

  return (
    <Drawer
      title={t("common:filter")}
      open={open}
      width="720px"
      size="auto"
      onClose={() => onClose()}
    >
      <div className="mb-3">
        <SelectRole
          label={t("common:roles")}
          getLabel={(item) => item?.name}
          value={values?.roles}
          error={errors?.roles}
          multiple
          onChange={(val) => {
            setFieldValue && setFieldValue("roles", val);
          }}
        />
      </div>
      <div className="mb-3">
        <Select
          dataSource={AdminStatuses}
          // i18next-extract-disable-next-line
          getLabel={(item) => t(`dynamic:${item.label}`)}
          label={t("user:accountAccess")}
          value={values?.accountAccess}
          error={errors?.accountAccess}
          onChange={(val) => {
            setFieldValue && setFieldValue("accountAccess", val);
          }}
        />
      </div>
      <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
        <Button
          onClick={() => {
            onClose && onClose({});
          }}
          variant="outline"
        >
          {t("common:clearAll")}
        </Button>
        <Button
          onClick={() => {
            onClose && onClose(values);
          }}
        >
          {t("common:save")}
        </Button>
      </div>
    </Drawer>
  );
};

export default UserFilter;

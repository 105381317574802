import {
  ROLE,
  VIEW_REPORT_CART_RULE_PERFORMANCE,
  VIEW_REPORT_CHANNEL_PERFORMANCE,
  VIEW_REPORT_PRODUCT_PERFORMANCE,
  VIEW_REPORT_STORE_PERFORMANCE,
  VIEW_REPORT_TOTAL_SALES,
  VIEW_USER,
} from "../../../interfaces/admin";
import { MenuPropsType } from "../../../interfaces/common";
import { isGranted } from "../PermissibleComponent";
import { Path } from "./Paths";

export const COMMON_KEY = "COMMON";

const MAIN_MENU: MenuPropsType = [
  {
    id: "dashboard",
    label: "dashboard",
    iconName: "dashboard",
    title: "dashboard",
    path: Path.ROOT,
  },
  {
    id: "report",
    label: "report",
    iconName: "assessment",
    title: "report",
    subMenu: [
      ...(isGranted(VIEW_REPORT_TOTAL_SALES)
        ? [
            {
              id: "totalSale",
              label: "totalSale",
              iconName: "assessment",
              path: Path.REPORT_TOTAL_SALE,
              title: "totalSale",
            },
          ]
        : []),
      ...(isGranted(VIEW_REPORT_CHANNEL_PERFORMANCE)
        ? [
            {
              id: "channelPerformance",
              label: "channelPerformance",
              iconName: "assessment",
              path: Path.REPORT_CHANNEL_PERFORMANCE,
              title: "channelPerformance",
            },
          ]
        : []),
      ...(isGranted(VIEW_REPORT_CART_RULE_PERFORMANCE)
        ? [
            {
              id: "cartRulePerformance",
              label: "cartRulePerformance",
              iconName: "assessment",
              path: Path.CART_RULE_PERFORMANCE,
              title: "cartRulePerformance",
            },
          ]
        : []),
      ...(isGranted(VIEW_REPORT_STORE_PERFORMANCE)
        ? [
            {
              id: "storePerformance",
              label: "storePerformance",
              iconName: "assessment",
              path: Path.REPORT_STORE_PERFORMANCE,
              title: "storePerformance",
            },
          ]
        : []),
      ...(isGranted(VIEW_REPORT_PRODUCT_PERFORMANCE)
        ? [
            {
              id: "productPerformance",
              label: "productPerformance",
              iconName: "assessment",
              path: Path.REPORT_PRODUCT_PERFORMANCE,
              title: "productPerformance",
            },
          ]
        : []),
      {
        id: "staffPerformance",
        label: "staffPerformance",
        iconName: "assessment",
        path: Path.REPORT_STAFF_PERFORMANCE,
        title: "staffPerformance",
      },
      {
        id: "provincePerformance",
        label: "provincePerformance",
        iconName: "assessment",
        path: Path.REPORT_PROVINCE_PERFORMANCE,
        title: "provincePerformance",
      },
    ],
  },
  {
    id: "settings",
    label: "settings",
    iconName: "settings",
    title: "settings",
    subMenu: [
      ...(isGranted(VIEW_USER)
        ? [
            {
              id: "users",
              label: "users",
              iconName: "people",
              path: Path.USERS,
              title: "users",
              subMenu: [
                {
                  id: "newUser",
                  label: "newUser",
                  path: Path.NEW_USER,
                  title: "newUser",
                  notShow: true,
                  parentId: "users",
                },
                {
                  id: "userDetail",
                  label: "userDetail",
                  path: Path.DETAIL_USER,
                  title: "userDetail",
                  notShow: true,
                  parentId: "users",
                },
              ],
            },
          ]
        : []),
      ...(isGranted(ROLE)
        ? [
            {
              id: "roles",
              label: "roles",
              path: Path.SETTINGS_ROLE,
              title: "roles",
              parentId: "settings",
            },
          ]
        : []),
    ],
  },
];

export default MAIN_MENU;

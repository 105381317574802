
import { IAdmin, IAdminInput } from "../../../../interfaces/common";
import { Modal, Notifications } from "d-react-components";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { userUserInfo } from "../UserDetail";
import AdminAuthentication from "./UserAuthentication";
import { useUpdateUserByAdminMutation } from "../../../../network/hooks";

const UserAuthenticationDetail = () => {
  const [{ user, loading, refetch }] = userUserInfo();
  const { t } = useTranslation();
  const [openProfileUpdateDrawer, setOpenProfileUpdateDrawer] = useState(false);
  const [updateUser] = useUpdateUserByAdminMutation();
  const {
    nickName,
    companyId,
    fullName,
    userName,
    hasTwoFaAuthentication,
    codeTwoFaAuthentication,
    qrCodeTwoFaAuthentication,
    id,
  } = user || {};
  const adminForm = useFormik<IAdminInput>({
    initialValues: {
      nickName,
      companyId,
      fullName,
      userName,
      hasTwoFaAuthentication,
      codeTwoFaAuthentication,
      qrCodeTwoFaAuthentication,
      id
    },
    onSubmit: () => {
      const payload = {};
      updateUser({
        variables: {
          input: payload as any,
        },
      })
        .then(() => {
          Notifications.showSuccess(t("notification:updateUserSuccessful"));
        })
        .catch((err) => {
          Notifications.showError(err);
        });
    },
    enableReinitialize: true,
  });

  return (
    <div className="relative">
      <div className="flex justify-end items-end mb-4 absolute z-10 top-6 right-6">
        <span
          className="cursor-pointer text-red-500"
          onClick={() => setOpenProfileUpdateDrawer(true)}
        >
          {t("common:edit")}
        </span>
      </div>
      <AdminAuthentication isDetail={true} userInfo={user as IAdmin} />
      {openProfileUpdateDrawer && (
        <Modal
          open={openProfileUpdateDrawer}
          onClose={() => setOpenProfileUpdateDrawer(false)}
          showFooter={false}
          title={t("user:authentication")}
          width={800}
        >
          <AdminAuthentication
            isDetail={false}
            adminForm={adminForm}
            isModal={true}
            onClose={() => {
              refetch();
              setOpenProfileUpdateDrawer(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default UserAuthenticationDetail;

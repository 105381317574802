import {
  Avatar,
  AwesomeTableComponent,
  Button,
  Card,
  DialogManager,
  Dropdown,
  IColumnsProps,
  Notifications,
  TimeUtils,
} from "d-react-components";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { useQueryParams, StringParam } from "use-query-params";
import { UserFilter } from "./UsersFilter";
import { useUpdateEffect } from "react-use";
import { Path } from "../common/layout/Paths";
import StatusItem from "../common/StatusItem/StatusItem";
import { HeaderTableFilter } from "../common/Table/HeaderTable";
import { IAdmin } from "../../interfaces/common";
import { UserFilter as UserFilterValues } from "../../interfaces/user";
import {
  useDeleteUsersByAdminMutation,
  useFindUsersByAdminQuery,
} from "../../network/hooks";

export const Users: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [filterData, setFilterData] = useState<UserFilterValues>({});
  const tableRef = useRef<any>(null);
  const [query, setQuery] = useQueryParams({
    search: StringParam,
  });
  const { refetch } = useFindUsersByAdminQuery({
    skip: true,
  });
  const [deleteUsers] = useDeleteUsersByAdminMutation();

  const loadTableData = (paging: any) => {
    return refetch({
      pagination: {
        page: paging.pageIndex,
        limit: paging.pageSize,
        search: {
          query: query?.search,
          ...(filterData?.roles ? { roles: filterData?.roles } : {}),
          ...(filterData?.channels ? { channels: filterData?.channels } : {}),
          ...(filterData?.accountAccess
            ? { accountAccess: filterData?.accountAccess }
            : {}),
        },
      },
    });
  };

  const getWarrantyStatus = (enabled: boolean) => {
    switch (enabled) {
      case true:
        return {
          // i18next-extract-disable-next-line
          statusText: t("dynamic:enabled"),
          dotClassName: "bg-green-500",
          className: "bg-green-100 ml-2",
          textClassName: "text-green-500",
        };
      case false:
        return {
          // i18next-extract-disable-next-line
          statusText: t("dynamic:disabled"),
          dotClassName: "bg-gray-500",
          className: "bg-gray-100 ml-2",
          textClassName: "text-gray-500",
        };
    }
  };

  const customerTableColumns: IColumnsProps = useMemo(
    () => [
      {
        title: t("customer:id"),
        dataIndex: "userId",
        align: "left",
        width: 40,
        render: (data, item, index) => (
          <Link
            to={generatePath(Path.DETAIL_USER, {
              userId: item?.id,
            })}
          >
            <span className="text-red-500">{item?.userId}</span>
          </Link>
        ),
      },
      {
        title: t("customer:userName"),
        dataIndex: "",
        align: "left",
        width: 100,
        render: (data, item: IAdmin, index) => <span>{item.userName}</span>,
      },
      {
        title: t("customer:name"),
        dataIndex: "",
        align: "left",
        width: 100,
        render: (data, item: IAdmin, index) => <span>{item.fullName}</span>,
      },
      {
        title: t("common:avatar"),
        dataIndex: "avatar",
        align: "left",
        width: 50,
        render: (data, item: IAdmin, index) => (
          <div className="flex align-items-center">
            <Avatar
              src={data}
              text={item?.fullName}
              alt=""
              variant="rounded"
              className="mr-2"
              size="x-small"
            />
          </div>
        ),
      },
      {
        title: t("customer:status"),
        dataIndex: "accountAccess",
        align: "left",
        width: 60,
        render: (data, item, index) => (
          <span className="inline-block">
            <StatusItem {...getWarrantyStatus(data)} />
          </span>
        ),
      },
      {
        title: t("common:createdAt"),
        dataIndex: "createdAt",
        align: "left",
        width: 60,
        render: (data, item, index) => TimeUtils.convertMiliToDateTime(data),
      },
      {
        title: t("common:lastUpdated"),
        dataIndex: "updatedAt",
        align: "left",
        width: 60,
        render: (data, item, index) => TimeUtils.convertMiliToDateTime(data),
      },
      {
        title: t("common:action"),
        dataIndex: "",
        align: "center",
        width: 75,
        render: (data: any, item: any, index) => (
          <Dropdown
            dataSource={[{ id: "delete", label: t("common:delete") }]}
          />
        ),
      },
    ],
    [t]
  );

  const onFilter = (values?: UserFilterValues) => {
    setShowFilterDrawer(false);
    if (values) {
      setFilterData(values);
    }
  };

  const refreshTable = () => {
    return tableRef.current && tableRef.current.refresh();
  };

  useUpdateEffect(() => {
    refreshTable();
  }, [filterData]);

  const deleteAll = () => {
    DialogManager.showConfirm(
      t("common:warning"),
      t("notification:confirmDeleteUser"),
      () => {
        deleteUsers({
          variables: {
            ids: selectedRows,
          },
        }).then(
          (resp) => {
            Notifications.showSuccess(t("notification:deleteUserSuccessfully"));
            refreshTable();
          },
          (err) => {
            Notifications.showError(err);
          }
        );
      }
    );
  };

  return (
    <Card>
      <HeaderTableFilter
        label={t("users")}
        className="mb-3"
        messages={{
          clearAll: t("common:clearAll"),
          filter: t("common:filter"),
          save: t("common:save"),
        }}
        onClickNew={() => navigate && navigate(Path.NEW_USER)}
        onClickExport={() => {
          return;
        }}
        searchValue={query?.search ?? ""}
        onChangeText={(e: any) => {
          setQuery({
            ...query,
            search: e?.target?.value,
          });
        }}
        onSubmitSearch={(e: any) => {
          refreshTable();
        }}
        filterType="drawer"
        onClickFilter={() => setShowFilterDrawer(true)}
      />
      {/* @ts-ignore */}
      <AwesomeTableComponent
        source={loadTableData}
        transformer={(res) => {
          return res?.data?.findUsersByAdmin?.data ?? [];
        }}
        getTotalItems={(res) => {
          return res?.data?.findUsersByAdmin?.pagination?.items ?? 0;
        }}
        columns={customerTableColumns}
        className="info-table"
        ref={tableRef}
        selectingRows={selectedRows}
        onSelectionView={(selectingRows) => {
          const length = selectingRows?.length ?? "N/A";
          return (
            <div>
              <Button
                variant="outline"
                content={`${t("common:deleteAll")} (${length})`}
                onClick={() => deleteAll()}
              />
            </div>
          );
        }}
        rowSelection={{
          onChange: (value) => setSelectedRows(value),
          selectedRowKeys: selectedRows,
          preserveSelectedRowKeys: true,
        }}
      />
      {showFilterDrawer && (
        <UserFilter
          open={showFilterDrawer}
          onClose={onFilter}
          filterData={filterData}
        />
      )}
    </Card>
  );
};

export default Users;

import {
  AwesomeTableComponent,
  Button,
  IColumnsProps,
  Notifications,
  Progress,
} from "d-react-components";
import { chain, isNil, omitBy } from "lodash";
import React, { useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SORT_DIRECTION } from "../../constants/common";
import { Channel } from "../../interfaces/common";
import { useDetailReportStaffPerformanceLazyQuery } from "../../network/hooks";
import { exportToXLS } from "../../utils/file";
import { moneyFormatter } from "../../utils/money";
import { loadAllPaginatedData } from "../../utils/promises";
import { convertToLocalTime } from "../../utils/time";

export const StaffPerformanceByChannel = ({
  filterData,
  channelList,
  staff,
  summaryData,
}: any) => {
  const [getReportDetail] = useDetailReportStaffPerformanceLazyQuery();
  const tableRef = useRef<any>(null);
  const { t } = useTranslation();

  const getPayload = () => {
    const payload = {
      orderSource: filterData?.orderSource,
      channels: filterData?.channels?.length
        ? filterData.channels
        : channelList.map((channel: Channel) => channel.refId),
      start: filterData?.currentRange?.[0].startOf("d").toDate(),
      end: filterData?.currentRange?.[1].endOf("d").toDate(),
      store: filterData.warehouses?.map((item: any) => item.refId),
    };
    return omitBy(payload, isNil);
  };

  const loadTableData = (paging: any, sorter: any) => {
    return getReportDetail({
      variables: {
        args: {
          ...getPayload(),
          page: paging.pageIndex,
          limit: paging.pageSize,
          ...(sorter?.order
            ? {
                sort: {
                  sortBy: sorter.field,
                  sortDirection: sorter.order === SORT_DIRECTION.ASC ? 1 : -1,
                },
              }
            : {
                sort: {
                  sortBy: "quantity",
                  sortDirection: -1,
                },
              }),
        },
      },
    });
  };

  const tableColumns: IColumnsProps = useMemo(() => {
    return [
      {
        title: t("warehouse"),
        dataIndex: "store",
        align: "left",
        width: 150,
        render: (data: any) => {
          return data?.name;
        },
      },
      {
        title: t("channel"),
        dataIndex: "channel",
        align: "left",
        width: 150,
        render: (data: any) => {
          return data?.name;
        },
      },
      {
        title: t("saleQuantity"),
        dataIndex: "count",
        align: "left",
        width: 100,
        sorter: (a, b) => {
          return a?.count - b?.count;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value) => {
          return moneyFormatter.format(value);
        },
      },
      {
        title: t("totalSalesAmount"),
        dataIndex: "subTotal",
        align: "left",
        width: 100,
        sorter: (a, b) => {
          return a?.subTotal - b?.subTotal;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value, item) => {
          return moneyFormatter.format(value);
        },
      },
      {
        title: t("totalSalesAmountNet"),
        dataIndex: "total",
        align: "left",
        width: 100,
        sorter: (a, b) => {
          return a?.total - b?.total;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value, item) => {
          return moneyFormatter.format(value);
        },
      },
      {
        title: t("avgOrderValue"),
        dataIndex: "subTotal",
        align: "left",
        width: 100,
        sorter: (a, b) => {
          return a?.subTotal / a?.count - b?.subTotal / b?.count;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value, item) => {
          return moneyFormatter.format(item.subTotal / item.count);
        },
      },
      {
        title: t("avgOrderValueNet"),
        dataIndex: "total",
        align: "left",
        width: 100,
        sorter: (a, b) => {
          return a?.total / a?.count - b?.total / b?.count;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value, item) => {
          return moneyFormatter.format(item.total / item.count);
        },
      },
    ];
  }, [t]);

  const exportReport = async () => {
    Progress.show(
      {
        method: loadAllPaginatedData,
        params: [getReportDetail, "detailReportStaffPerformance", getPayload()],
      },
      (resp) => {
        const exportedData: any[] = resp as any[];

        const mappedData = [
          {
            Name: staff?.admin?.fullname,
            "Employee Id": staff?.admin?.username,
            Warehouse: "",
            Channel: "",
            "Sale Quantity": staff.count,
            "Total Sales Amount": staff?.subTotal?.toFixed(0),
            "Total Sales Amount (Net)": staff?.total?.toFixed(0),
            "Average Order Value": (
              Math.round(staff.subTotal) / parseInt(staff?.count)
            ).toFixed(0),
            "Average Order Value (Net)": (
              Math.round(staff.total) / parseInt(staff?.count)
            ).toFixed(0),
          },
        ];

        const rowData = chain(exportedData)
          .orderBy("store.name")
          .map((item: any) => ({
            Name: staff?.admin?.fullname,
            "Employee Id": staff?.admin?.username,
            Warehouse: item?.store?.name,
            Channel: item?.channel?.name,
            "Sale Quantity": item.count,
            "Total Sales Amount": item?.subTotal?.toFixed(0),
            "Total Sales Amount (Net)": item?.total?.toFixed(0),
            "Average Order Value": (
              Math.round(item.subTotal) / parseInt(item?.count)
            ).toFixed(0),
            "Average Order Value (Net)": (
              Math.round(item.total) / parseInt(item?.count)
            ).toFixed(0),
          }))
          .value();
        const exportData = [...mappedData, ...rowData];
        exportToXLS(exportData, "export");
      },
      (err: any) => {
        Notifications.showError(err);
      }
    );
  };

  return (
    <div className="relative">
      <AwesomeTableComponent
        columns={tableColumns}
        ref={tableRef}
        source={loadTableData}
        rowKey={(item) => item?.id}
        transformer={(res) => {
          const reportItems =
            res?.data?.detailReportStaffPerformance?.data?.map((item: any) => ({
              ...item,
              created: convertToLocalTime(item.created),
            })) ?? [];
          return reportItems;
        }}
        getTotalItems={(response) => {
          return (
            response?.data?.detailReportProductPerformance?.pagination?.items ??
            0
          );
        }}
      />
      <Button
        className="absolute bottom-2 left-0"
        iconName="cloud_download"
        onClick={exportReport}
      >
        {t("export")}
      </Button>
    </div>
  );
};

import dayjs from "dayjs";
import moment from "moment";
import {
  CompareTerm,
  CompareTermCriteria,
  CompareValue,
} from "../constants/report";

export const getCompareChartParams = (compareData: any) => {
  let {
    selectedOption,
    term,
    termCriteria,
    ofYear,
    compareToYear,
    periodFrom,
    periodTo,
  } = compareData;
  // if (!filterData?.orderSource?.length) {
  //   return;
  // }
  let formerTime = dayjs(),
    latterTime = dayjs(),
    numberOfMonths = 0,
    numberOfYears = 0;
  switch (selectedOption) {
    case CompareValue.LAST_3_MONTHS:
      term = CompareTerm.YOY;
      termCriteria = CompareTermCriteria.BY_MONTH;
      numberOfMonths = 3;
      numberOfYears = 1;
      formerTime = dayjs();
      break;
    case CompareValue.LAST_6_MONTHS:
      term = CompareTerm.YOY;
      termCriteria = CompareTermCriteria.BY_MONTH;
      numberOfMonths = 6;
      numberOfYears = 1;
      formerTime = dayjs();
      break;
    case CompareValue.LAST_12_MONTHS:
      term = CompareTerm.YOY;
      termCriteria = CompareTermCriteria.BY_MONTH;
      numberOfMonths = 12;
      numberOfYears = 1;
      formerTime = dayjs();
      latterTime = formerTime.subtract(1, "y");
      break;
    case CompareValue.LAST_4_QUARTERS_OY:
      term = CompareTerm.YOY;
      termCriteria = CompareTermCriteria.BY_QUARTER;
      numberOfMonths = 12;
      numberOfYears = 1;
      formerTime = dayjs();
      break;
    case CompareValue.LAST_4_QUARTERS:
      term = CompareTerm.QOQ;
      termCriteria = CompareTermCriteria.BY_QUARTER;
      numberOfMonths = 12;
      formerTime = dayjs();
      numberOfYears = 1;
      break;
    case CompareValue.CUSTOM:
      if (
        term === CompareTerm.YOY &&
        termCriteria === CompareTermCriteria.BY_QUARTER
      ) {
        const formerYear = Number(moment(ofYear).format("YYYY"));
        const latterYear = Number(moment(compareToYear).format("YYYY"));
        formerTime = dayjs()
          .endOf("quarter")
          .set("y", formerYear > latterYear ? formerYear : latterYear)
          .endOf("y");
        numberOfMonths = 12;
        numberOfYears = Math.abs(formerYear - latterYear);
      }
      if (
        term === CompareTerm.YOY &&
        termCriteria === CompareTermCriteria.BY_MONTH
      ) {
        const formerYear = Number(moment(ofYear).format("YYYY"));
        const latterYear = Number(moment(compareToYear).format("YYYY"));
        const fromMonth = moment(periodFrom).set("y", formerYear).toDate();
        const toMonth = moment(periodTo).set("y", formerYear).toDate();
        numberOfMonths = Math.abs(dayjs(fromMonth).diff(toMonth, "month")) + 1;
        formerTime = dayjs(toMonth)
          .endOf("M")
          .set("y", formerYear > latterYear ? formerYear : latterYear);
        numberOfYears = Math.abs(formerYear - latterYear);
      }
      if (term === CompareTerm.QOQ) {
        const formerYear = Number(moment(ofYear).format("YYYY"));
        formerTime = dayjs().set("y", formerYear).endOf("y");
        numberOfYears = 1;
        numberOfMonths = 12;
      }
      break;
  }

  return {
    term,
    termCriteria,
    numberOfMonths,
    numberOfYears,
    formerTime,
    latterTime,
  };
};

export const getDifferenceInPercent = (item: any) => {
  const difference = item.formerIndex - item.latterIndex;
  const isIncrease = difference >= 0;
  let percentDiff = "";
  if (
    (!item.latterIndex && !item.formerIndex) ||
    item.latterIndex === item.formerIndex
  ) {
    percentDiff = "0";
  } else if (item.latterIndex === 0) {
    percentDiff = "100";
  } else {
    if (isIncrease) {
      percentDiff = (
        ((item.formerIndex - item.latterIndex) / item.latterIndex) *
        100
      ).toFixed(0);
    } else {
      percentDiff = (
        ((item.latterIndex - item.formerIndex) / item.latterIndex) *
        100
      ).toFixed(0);
    }
  }
  return {
    difference,
    isIncrease,
    percentDiff,
  };
};

export const getDifferenceText = (item: any) => {
  const { isIncrease, difference, percentDiff } = getDifferenceInPercent(item);
  return `${isIncrease ? difference : `(${Math.abs(difference)})`} ${
    isIncrease ? `(+${percentDiff}%)` : `(-${percentDiff}%)`
  }`;
};

export const getDifferencePercent = (item: any) => {
  const { isIncrease, percentDiff } = getDifferenceInPercent(item);
  return `${isIncrease ? `+${percentDiff}%` : `-${percentDiff}%`}`;
};

import {
  AwesomeTableComponent,
  Button,
  Card,
  DialogManager,
  IColumnsProps,
  Notifications,
  TimeUtils,
} from "d-react-components";
import React, { ElementRef, useRef, useState } from "react";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParams } from "use-query-params";
import { Path } from "../../common/layout/Paths";
import { HeaderTableFilter } from "../../common/Table/HeaderTable";
import { useFindAllRoleLazyQuery, useRemoveRoleMutation } from "../../../network/hooks";
import { IRoleAdminSetting } from "../../../interfaces/admin";

export interface ISettingRoleProps {
  [key: string]: any;
}

export const SettingRole: React.FC<ISettingRoleProps> = ({ id }) => {
  const [query, setQuery] = useQueryParams({
    query: StringParam,
  });
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const tableRef = useRef<any>(null);
  const [refetch] = useFindAllRoleLazyQuery();
  const [deleteRole] = useRemoveRoleMutation();

  const loadTableData = (paging: any) => {
    return refetch({
      variables: {
        pagination: {
          page: paging.pageIndex,
          limit: paging.pageSize,
          search: {
            ...query,
          },
        },
      },
    });
  };

  const ROLE_COLUMNS: IColumnsProps = [
    {
      title: t("common:name"),
      align: "left",
      dataIndex: "name",
      width: 300,
      render: (data: any, item: IRoleAdminSetting) => (
        <Link to={generatePath(Path.ROLE_DETAIL, { roleId: item?.id })}>
          {data}
        </Link>
      ),
    },
    {
      title: t("common:createdAt"),
      dataIndex: "createdAt",
      align: "left",
      width: 60,
      render: (data, item, index) => TimeUtils.convertMiliToDateTime(data),
    },
    {
      title: t("common:updatedAt"),
      dataIndex: "updatedAt",
      align: "left",
      width: 60,
      render: (data, item, index) => TimeUtils.convertMiliToDateTime(data),
    },
  ];

  const refreshTable = () => {
    return tableRef.current && tableRef.current.refresh();
  };

  const deleteAll = () => {
    DialogManager.showConfirm(
      t("common:warning"),
      t("notification:confirmDeleteRole"),
      () => {
        deleteRole({
          variables: {
            ids: selectedRows,
          },
        }).then(
          (resp) => {
            Notifications.showSuccess(t("notification:deleteRoleSuccessfully"));
            refreshTable();
          },
          (err) => {
            Notifications.showError(err);
          }
        );
      }
    );
  };

  return (
    <Card>
      <HeaderTableFilter
        label={t("roles")}
        className="mb-3"
        messages={{
          clearAll: t("common:clearAll"),
          filter: t("common:filter"),
          save: t("common:save"),
        }}
        onClickNew={() => navigate && navigate(Path.ROLE_CREATE)}
        onClickExport={() => {
          return;
        }}
        searchValue={query?.query ?? ""}
        onChangeText={(e: any) => {
          setQuery({
            ...query,
            query: e?.target?.value,
          });
        }}
        onSubmitSearch={(e: any) => {
          refreshTable();
        }}
      />
      {/* @ts-ignore */}
      <AwesomeTableComponent
        source={loadTableData}
        transformer={(res) => {
          return res?.data?.findAllRole?.data ?? [];
        }}
        columns={ROLE_COLUMNS}
        className="info-table"
        ref={tableRef}
        selectingRows={selectedRows}
        onSelectionView={(selectingRows) => {
          const length = selectingRows?.length ?? "N/A";
          return (
            <div>
              <Button
                variant="outline"
                content={`${t("common:deleteAll")} (${length})`}
                onClick={() => deleteAll()}
              />
            </div>
          );
        }}
        rowSelection={{
          onChange: (value) => setSelectedRows(value),
          selectedRowKeys: selectedRows,
          preserveSelectedRowKeys: true,
        }}
        getTotalItems={(res) => {
          return res?.data?.findAllRole?.pagination?.items ?? 0;
        }}
      />
    </Card>
  );
};

export default SettingRole;

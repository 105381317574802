import { SortOrder } from "antd/lib/table/interface";
import { FILTER_TYPE } from "./report";

export const ADMIN_AUTH_KEY = "report_infinityx_admin";
export const INVALID_CREDENTIALS = "INVALID_CREDENTIALS";
export const UNAUTHENTICATED = "UNAUTHENTICATED";

export const CHART_COLORS = [
  "#EF3E93",
  "#3e43a3",
  "#025e63",
  "#227ED2",
  "#CBA977",
  "#38BDF7",
  "#32CD32",
  "#FFB6C1",
  "#FFA500",
  "#48048c",
  "#7B68EE",
  "#b76ca6",
  "#9966CC",
  "#F4A460",
  "#FFA07A",
  "#5F9EA0",
  "#B8860B",
  "#800080",
  "#60aed6",
  "#FFDAB9",
  "#849b0f",
  "#F08080",
  "#D2B48C",
  "#DC143C",
  "#ceba08",
  "#4682B4",
  "#FF8C00",
  "#6495ED",
  "#8B008B",
  "#90EE90",
  "#20B2AA",
  "#00CED1",
  "#778899",
  "#FF6347",
  "#FF1493",
  "#228B22",
  "#EE82EE",
  "#66CDAA",
  "#D8BFD8",
  "#FFC0CB",
  "#8B0000",
  "#6495ED",
];

export const SORT_DIRECTION: { [key: string]: SortOrder } = {
  ASC: "ascend",
  DESC: "descend",
};

export const CART_RULE_TYPE: { [key: string]: string } = {
  FREE_GIFT: "free_gift",
  BUY_X_GET_BUNDLE: "buy_x_get_bundle",
  DISCOUNT_PERCENTAGE: "discount_percentage",
  DISCOUNT_FIX_AMOUNT: "discount_fix_amount",
  BUY_X_GET_X: "buy_x_get_x",
  DISCOUNT_PERCENTAGE_CART: "discount_percentage_cart",
  DISCOUNT_FIX_AMOUNT_CART: "discount_fix_amount_cart",
  BUY_X_GET_DISCOUNT_PERCENTAGE: "buy_x_get_discount_percentage",
  BUY_X_GET_DISCOUNT_FIX_AMOUNT: "buy_x_get_discount_fix_amount",
};

export const CART_RULE_TYPE_OPTIONS = Object.keys(CART_RULE_TYPE).map(
  (item) => ({
    label: CART_RULE_TYPE[item],
    key: CART_RULE_TYPE[item],
  })
);

export const ORDER_STATUS: { [key: string]: string } = {
  PENDING: "pending",
  PROCESSING: "processing",
  PENDING_PAYMENT_CONFIRMATION: "pending-payment-confirmation",
  PARTIALLY_PAID: "partially-paid",
  DELIVERY_PROCESSING: "delivery-processing",
  DELIVERED: "delivered",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
  REFUNDED: "refunded",
};

export const ORDER_STATUS_OPTIONS = Object.keys(ORDER_STATUS).map((item) => ({
  label: ORDER_STATUS[item],
  key: ORDER_STATUS[item],
}));

export const MOMENT_UNIT = {
  [FILTER_TYPE.BY_DAY]: "day",
  [FILTER_TYPE.BY_MONTH]: "month",
  [FILTER_TYPE.BY_WEEK]: "week",
  [FILTER_TYPE.BY_QUARTER]: "quarter",
  [FILTER_TYPE.BY_YEAR]: "year",
};

export const INCREMENT_UNIT = {
  [FILTER_TYPE.BY_DAY]: "days",
  [FILTER_TYPE.BY_MONTH]: "months",
  [FILTER_TYPE.BY_WEEK]: "weeks",
  [FILTER_TYPE.BY_QUARTER]: "quarters",
  [FILTER_TYPE.BY_YEAR]: "years",
};

export const VAT_DIVIDER = 1.07;

import { useTranslation } from "react-i18next";
import { Dropdown } from "d-react-components";
import { LANGUAGES } from "../../../../constants/locales";

export function DropdownLanguage() {
  const { t, i18n } = useTranslation();
  const languageSelecting = LANGUAGES.find(
    (item) => item.locale === i18n.language
  );

  const onChangeLocale = () => {
    i18n.changeLanguage(i18n.language === "en" ? "th" : "en");
  };

  // return <Dropdown title={title} withIcons items={LANGUAGES} onClick={onChangeLocale} />;
  return (
    <Dropdown
      value={languageSelecting}
      dataSource={LANGUAGES}
      onClick={onChangeLocale}
      variant="view"
      position="left-edge"
    />
  );
}

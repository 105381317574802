import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";

import * as Yup from "yup";

import {
  Button,
  DateInput,
  Drawer,
  Icon,
  RadioGroup,
} from "d-react-components";
import {
  CART_RULE_TYPE_OPTIONS,
  ORDER_STATUS_OPTIONS,
} from "../../../../constants/common";
import {
  COMPARE_OPTIONS,
  COMPARE_TERMS_CRITERIA_OPTIONS,
  COMPARE_TERMS_OPTIONS,
  CompareTerm,
  CompareTermCriteria,
  CompareValue,
  FILTER_PRODUCT_TYPE_OPTIONS,
  ITimeLineState,
  REPORT_TYPE,
} from "../../../../constants/report";
import {
  FOC_TYPE_OPTIONS,
  ORDER_SOURCE_OPTIONS,
  PRODUCT_LAUNCH_AT_OPTIONS,
  STATUS_FILTER_OPTIONS,
} from "../../../../constants/order";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SelectFilter, {
  SelectInfinityFilter,
} from "../../SelectFilter/SelectFilter";
import { filter, find, isEmpty } from "lodash";
import {
  ProductPerformanceFilterProductType,
  useBrandListLazyQuery,
  useCartRuleListLazyQuery,
  useCategoryListLazyQuery,
  useProvinceListLazyQuery,
  useStaffListLazyQuery,
  useWarehouseListLazyQuery,
} from "../../../../network/hooks";

import AppContext from "../../AppContext/ReportContext";
import FilterDropdown from "../../FilterDropdown/FilterDropdown";
import { TranslatedSelect } from "../../Select/Select";
import moment from "moment";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import DatePickerDropdown from "./DatePickerDropdown";

export interface ReportHeaderProps {
  onChangeFilter?: (filter: FilterDataProps) => void;
  onChangeCompare?: (params: any) => void;
  handleExportData?: () => void;
  reportType?: REPORT_TYPE;
  componentAfter?: ReactNode;
}

export interface FilterDataProps {
  channels?: string[];
  orderStatus?: string[];
  orderType?: string;
  paymentStatus?: string;
  currentRange: ITimeLineState["dateRange"];
  warehouses?: any[];
  orderSource?: string[];
  cartRule?: any[];
  focType?: string;
  filterProductType?: ProductPerformanceFilterProductType;
}

const CompareParamSelect = ({
  onChangeCompare,
  reportType = REPORT_TYPE.TOTAL_SALE,
}: any) => {
  const { t } = useTranslation();
  const [showDrawer, setShowDrawer] = useState(false);
  const { compareData, setCompareData, setViewMode, allChannels, viewMode } =
    useContext(AppContext);
  const [getListWarehouse] = useWarehouseListLazyQuery();

  const CompareFormSchema = Yup.object().shape({
    selectedOption: Yup.mixed().required(t(`common:fieldRequired`)),
    term: Yup.string().when("selectedOption", (selectedOption, schema) => {
      if (selectedOption === CompareValue.CUSTOM)
        return schema.required(t(`common:fieldRequired`));
      return schema;
    }),
    termCriteria: Yup.string().when("term", (term, schema) => {
      if (term === CompareTerm.YOY)
        return schema.required(t(`common:fieldRequired`));
      return schema;
    }),
    compareToYear: Yup.string().when("term", (term, schema) => {
      if (term === CompareTerm.YOY)
        return schema.required(t(`common:fieldRequired`));
      return schema;
    }),
    periodFrom: Yup.string().when("termCriteria", (termCriteria, schema) => {
      if (termCriteria === CompareTermCriteria.BY_MONTH)
        return schema.required(t(`common:fieldRequired`));
      return schema;
    }),
    periodTo: Yup.string().when("termCriteria", (termCriteria, schema) => {
      if (termCriteria === CompareTermCriteria.BY_MONTH)
        return schema.required(t(`common:fieldRequired`));
      return schema;
    }),
    ofYear: Yup.string().when("selectedOption", (selectedOption, schema) => {
      if (selectedOption === CompareValue.CUSTOM)
        return schema.required(t(`common:fieldRequired`));
      return schema;
    }),
  });

  const handleOnSubmit = () => {
    const selectedOption = find(COMPARE_OPTIONS, {
      value: values.selectedOption,
    });
    setCompareData({
      ...values,
      ...(selectedOption?.term ? { term: selectedOption.term } : {}),
      ...(selectedOption?.termCriteria
        ? { termCriteria: selectedOption.termCriteria }
        : {}),
    });
    setViewMode("compare");
    setShowDrawer(false);
  };

  const onReset = () => {
    setViewMode("normal");
    setShowDrawer(false);
  };

  const compareForm = useFormik<any>({
    initialValues:
      compareData?.selectedOption === CompareValue.CUSTOM
        ? { ...compareData }
        : {
            selectedOption: compareData?.selectedOption,
            orderSource: compareData?.orderSource,
            channels: compareData?.channels,
            warehouses: compareData?.warehouses,
            paymentStatus: compareData?.paymentStatus,
          },
    onSubmit: () => {
      handleOnSubmit();
    },
    validationSchema: CompareFormSchema,
    enableReinitialize: true,
    validateOnChange: false,
  });

  useEffect(() => {
    if (onChangeCompare && !isEmpty(compareData)) {
      onChangeCompare();
    }
  }, [compareData]);

  const { values, errors, setFieldValue, handleSubmit } = compareForm;
  const updateFilterValue = (key: string, value: any) => {
    setFieldValue(key, value);
  };

  useEffect(() => {
    if (values?.ofYear) {
      if (
        values?.periodFrom &&
        !moment(values?.ofYear).isSame(moment(values?.periodFrom), "year")
      ) {
        setFieldValue("periodFrom", undefined);
      }
      if (
        values?.periodTo &&
        !moment(values?.ofYear).isSame(moment(values?.periodTo), "year")
      ) {
        setFieldValue("periodTo", undefined);
      }
      if (
        values?.compareToYear &&
        moment(values?.ofYear).isSame(moment(values?.compareToYear), "year")
      ) {
        setFieldValue("compareToYear", undefined);
      }
    }
  }, [values?.ofYear]);

  const channelList = useMemo(() => {
    const list = allChannels?.findChannelsByAdmin?.data;
    if (!values?.orderSource?.length) {
      return list;
    }
    return list?.filter((item: any) =>
      item?.orderSource?.some((source: any) => {
        return values?.orderSource?.includes(source);
      })
    );
  }, [values?.orderSource, allChannels]);

  return (
    <>
      <div className="mr-3 relative">
        <Button
          className="px-0"
          style={{ minWidth: "42px" }}
          onClick={() => setShowDrawer(true)}
        >
          <img src="/images/icons/scale.svg" alt="" />
        </Button>
        {viewMode === "compare" && (
          <div className="rounded-full absolute -right-1 -top-1 w-[10px] h-[10px] bg-red-500" />
        )}
      </div>
      {showDrawer && (
        <Drawer
          title={t("compare")}
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          size="auto"
          width="500px"
        >
          <div className="pb-10">
            {reportType !== REPORT_TYPE.PROVINCE_PERFORMANCE && (
              <SelectFilter
                placeholder={t("orderSource")}
                dataSource={ORDER_SOURCE_OPTIONS}
                getLabel={(item) => t(item.label)}
                getValue={(item) => item.key}
                className="mb-2 min-w-[170px]"
                value={values?.orderSource}
                onChange={(value) => {
                  setFieldValue("orderSource", value);
                  setFieldValue("channels", []);
                  setFieldValue("warehouses", []);
                }}
                multiple
                tagTitle={t("orderSource")}
                label={t("orderSource")}
              />
            )}
            <FilterDropdown
              selectProps={{
                placeholder: t("search"),
                multiple: true,
                mode: "multiple",
                value: values?.channels || [],
                getLabel: (item) => item?.name ?? "N/A",
                dataSource: channelList ?? [],
                onChange: (value) => {
                  setFieldValue("channels", value);
                  setFieldValue("warehouses", []);
                },
                className: "mb-3",
                tagTitle: t("channel"),
                getValue: (item) => item.refId,
                disabled: !values?.orderSource?.length,
                label: t("channel"),
                allowCreateNew: false,
              }}
              type="normal"
              className="mb-2"
            />
            {(reportType === REPORT_TYPE.TOTAL_SALE ||
              reportType === REPORT_TYPE.CHANNEL_PERFORMANCE ||
              reportType === REPORT_TYPE.STORE_PERFORMANCE) && (
              <TranslatedSelect
                placeholder={t("paymentStatus")}
                label={t("paymentStatus")}
                dataSource={STATUS_FILTER_OPTIONS}
                getLabel={(item) => t(item.label)}
                getValue={(item) => item.key}
                className="mb-2 min-w-[220px]"
                value={values?.paymentStatus}
                onChange={(value) => setFieldValue("paymentStatus", value)}
                disabled={!values?.orderSource?.length}
              />
            )}
            {(reportType === REPORT_TYPE.STORE_PERFORMANCE ||
              reportType === REPORT_TYPE.PRODUCT_PERFORMANCE ||
              reportType === REPORT_TYPE.STAFF_PERFORMANCE) && (
              <FilterDropdown
                selectProps={{
                  placeholder: t("search"),
                  mode: "multiple",
                  label: t("warehouse"),
                  value: values?.warehouses || [],
                  getLabel: (item) => item?.name,
                  onChange: (value) => setFieldValue("warehouses", value),
                  className: "w-100 min-w-[200px] mb-2",
                  tagTitle: t("warehouse"),
                  getValue: (item) => item?.refId,
                  disabled:
                    !values?.orderSource?.length || !values?.channels?.length,
                  fetchFn: getListWarehouse,
                  dataKey: "findWarehousesByAdmin",
                  customQuery: () => ({
                    ...(values?.channels
                      ? { channelIds: values?.channels }
                      : {}),
                  }),
                }}
                className="mb-2"
                type="infinity"
              />
            )}
            <div className="mt-3">
              <label className="text-label mb-2">{t("comparePeriod")}</label>
              <div className="row">
                <RadioGroup
                  dataSource={COMPARE_OPTIONS}
                  getValue={(item) => item.value}
                  getLabel={(item) => t(item.label)}
                  numberOfColumns="1"
                  value={values?.selectedOption}
                  error={errors?.selectedOption}
                  onChange={(value) => {
                    updateFilterValue("selectedOption", value);
                  }}
                  className="compare-group"
                />
              </div>
            </div>
            {values?.selectedOption === CompareValue.CUSTOM && (
              <div className="mt-3">
                <TranslatedSelect
                  label={t("term")}
                  dataSource={COMPARE_TERMS_OPTIONS}
                  value={values.term}
                  onChange={(value) => updateFilterValue("term", value)}
                  getValue={(item) => item.value}
                  getLabel={(item) => t(item.label)}
                  className="mb-3"
                  error={errors?.term}
                />
                {values.term === CompareTerm.YOY && (
                  <TranslatedSelect
                    label={t("termCriteria")}
                    dataSource={COMPARE_TERMS_CRITERIA_OPTIONS}
                    value={values.termCriteria}
                    onChange={(value) =>
                      updateFilterValue("termCriteria", value)
                    }
                    getValue={(item) => item.value}
                    getLabel={(item) => t(item.label)}
                    className="mb-3"
                    error={errors?.termCriteria}
                  />
                )}
                <DateInput
                  type="year"
                  format={"YYYY"}
                  label={t("ofYear")}
                  value={values?.ofYear}
                  error={errors?.ofYear as string}
                  onChange={(value) => {
                    updateFilterValue("ofYear", value);
                  }}
                  className="mb-3"
                  disabledDate={(current) =>
                    values?.compareToYear && values.term === CompareTerm.YOY
                      ? moment(current).isSame(
                          moment(values?.compareToYear),
                          "year"
                        )
                      : false
                  }
                />
                {values.term === CompareTerm.YOY &&
                  values.termCriteria === CompareTermCriteria.BY_MONTH && (
                    <>
                      <div className="mb-3 grid grid-cols-2 gap-2">
                        <div className="col-span-1">
                          <DateInput
                            type="month"
                            format={"MM/YYYY"}
                            label={t("period")}
                            value={values?.periodFrom}
                            onChange={(value) => {
                              updateFilterValue("periodFrom", value);
                            }}
                            error={errors?.periodFrom as string}
                            disabledDate={(current) =>
                              values?.ofYear
                                ? !moment(current).isSame(
                                    moment(values?.ofYear),
                                    "year"
                                  )
                                : false
                            }
                          />
                        </div>
                        <div className="col-span-1">
                          <DateInput
                            type="month"
                            format={"MM/YYYY"}
                            label="&nbsp;"
                            value={values?.periodTo}
                            onChange={(value) => {
                              updateFilterValue("periodTo", value);
                            }}
                            error={errors?.periodTo as string}
                            disabledDate={(current) =>
                              values?.ofYear
                                ? !moment(current).isSame(
                                    moment(values?.ofYear),
                                    "year"
                                  )
                                : false
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                {values.term === CompareTerm.YOY && (
                  <DateInput
                    type="year"
                    format={"YYYY"}
                    label={t("compareToYear")}
                    value={values?.compareToYear}
                    onChange={(value) => {
                      updateFilterValue("compareToYear", value);
                    }}
                    className="mb-3"
                    error={errors?.compareToYear as string}
                    disabledDate={(current) =>
                      values?.ofYear
                        ? moment(current).isSame(moment(values?.ofYear), "year")
                        : false
                    }
                  />
                )}
              </div>
            )}
          </div>
          <div className="position-absolute z-50 bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
            <div className="flex-1 text-red-500 px-3">
              <span className="cursor-pointer" onClick={() => onReset()}>
                {t("clearAll")}
              </span>
            </div>
            <div className="">
              <Button onClick={() => handleSubmit()}>{t("apply")}</Button>
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

const ReportHeader: React.FC<ReportHeaderProps> = ({
  onChangeFilter,
  onChangeCompare,
  handleExportData,
  reportType = REPORT_TYPE.TOTAL_SALE,
  componentAfter = null,
}) => {
  const { channelList, filterData, setFilterData, viewMode } =
    useContext(AppContext);
  const [dateRange, setDateRange] = useState<any>(
    filterData?.currentRange
      ? [
          moment(filterData.currentRange?.[0]).toDate(),
          moment(filterData.currentRange?.[1]).toDate(),
        ]
      : []
  );
  const [getListWarehouse] = useWarehouseListLazyQuery();
  const [getListCategory] = useCategoryListLazyQuery();
  const [getListBrand] = useBrandListLazyQuery();
  const [getListCartRule] = useCartRuleListLazyQuery();
  const [getListStaff] = useStaffListLazyQuery();
  const [getListProvince] = useProvinceListLazyQuery();
  const { t } = useTranslation();

  const onChangeFilterValue = (value: any, key: string) => {
    setFilterData((pre: any) => ({
      ...pre,
      [key]: value,
    }));
  };

  const onChangeFilterValues = (values: any) => {
    setFilterData((pre: any) => ({
      ...pre,
      ...values,
    }));
  };

  useEffect(() => {
    onChangeFilterValue(
      [moment(dateRange?.[0]), moment(dateRange?.[1])],
      "currentRange"
    );
  }, [dateRange]);

  useEffect(() => {
    if (onChangeFilter) {
      onChangeFilter(filterData);
    }
  }, [filterData]);

  const isDisableSelect = useMemo(() => viewMode === "compare", [viewMode]);

  const rightSide = () => {
    return (
      <div className="flex shrink-0">
        {viewMode === "normal" && (
          <>
            <div className="flex flex-column align-items-end flex-none">
              <label className="d-block">{t("report:dateRange")}</label>
              <div className="text-x-small">
                {filterData?.currentRange?.[0].format("DD/MM/YYYY")}-
                {filterData?.currentRange?.[1].format("DD/MM/YYYY")}
              </div>
            </div>
            {/* <DateInput
              useButton
              className={`mx-3 cursor-pointer ${
                viewMode === "compare" && "grayscale"
              }`}
              isRangePicker
              onChange={(value: any) => handleDateRange(value as any)}
              onOpenChange={handleDateRangeBlur}
              value={dateRange as any}
              format="DD/MM/YYYY"
              disabled={viewMode === "compare"}
            /> */}
            {/* <DateRangePicker
              onChange={(val) => {
                setDateRange(val);
              }}
              value={dateRange}
              className={`bg-primary mx-3 w-[42px] ${
                viewMode === "compare" && "grayscale"
              }`}
              calendarClassName="shadow"
              disabled={viewMode === "compare"}
              calendarIcon={<Icon name="event" className="text-white" />}
            /> */}
            <DatePickerDropdown
              onChange={(val: any) => {
                setDateRange(val);
              }}
              value={dateRange}
              reportType={reportType}
              disabled={viewMode === "compare"}
            />
          </>
        )}
        {(reportType === REPORT_TYPE.STORE_PERFORMANCE ||
          reportType === REPORT_TYPE.CHANNEL_PERFORMANCE ||
          reportType === REPORT_TYPE.TOTAL_SALE) && (
          <CompareParamSelect
            onChangeCompare={onChangeCompare}
            reportType={reportType}
          />
        )}
        <Button
          onClick={handleExportData}
          title={t("export")}
          iconName="cloud_download"
        />
      </div>
    );
  };

  const leftSide = () => {
    return viewMode === "compare" ? (
      <h4>{t("comparisonMode")}</h4>
    ) : (
      <>
        {reportType !== REPORT_TYPE.PROVINCE_PERFORMANCE && (
          <SelectFilter
            placeholder={t("orderSource")}
            dataSource={ORDER_SOURCE_OPTIONS}
            getLabel={(item) => t(item.label)}
            getValue={(item) => item.key}
            className="mr-2 min-w-[170px] mb-2"
            value={filterData?.orderSource}
            onChange={(value) => {
              onChangeFilterValues({
                orderSource: value,
                channels: [],
                warehouses: [],
              });
            }}
            multiple
            tagTitle={t("orderSource")}
            disabled={isDisableSelect}
          />
        )}
        <FilterDropdown
          selectProps={{
            placeholder: t("search"),
            multiple: true,
            mode: "multiple",
            value: filterData?.channels || [],
            getLabel: (item) => item?.name ?? "N/A",
            dataSource: channelList ?? [],
            onChange: (value) => {
              onChangeFilterValues({
                channels: value,
                warehouses: [],
              });
            },
            className: "mb-2 min-w-[150px] mr-2",
            tagTitle: t("channel"),
            getValue: (item) => item.refId,
            disabled:
              isDisableSelect ||
              (reportType === REPORT_TYPE.PROVINCE_PERFORMANCE
                ? false
                : !filterData?.orderSource?.length),
          }}
          className="mr-2 mb-2"
          type="normal"
        />
        {(reportType === REPORT_TYPE.STORE_PERFORMANCE ||
          reportType === REPORT_TYPE.PRODUCT_PERFORMANCE ||
          reportType === REPORT_TYPE.STAFF_PERFORMANCE) && (
          <FilterDropdown
            selectProps={{
              placeholder: t("search"),
              mode: "multiple",
              value: filterData?.warehouses || [],
              getLabel: (item) => item?.name,
              onChange: (value) => onChangeFilterValue(value, "warehouses"),
              className: "mb-2 w-100 min-w-[200px] mr-2",
              tagTitle: t("warehouse"),
              getValue: (item) => item?.refId,
              disabled:
                isDisableSelect ||
                !filterData?.orderSource?.length ||
                !filterData?.channels?.length,
              fetchFn: getListWarehouse,
              dataKey: "findWarehousesByAdmin",
              customQuery: () => ({
                ...(filterData?.channels
                  ? { channelIds: filterData?.channels }
                  : {}),
              }),
            }}
            className="mr-2 mb-2"
            type="infinity"
          />
        )}
        {(reportType === REPORT_TYPE.PRODUCT_PERFORMANCE ||
          reportType === REPORT_TYPE.CARTRULE_PERFORMANCE) && (
          <SelectFilter
            placeholder={t("orderStatus")}
            dataSource={ORDER_STATUS_OPTIONS}
            getLabel={(item) => t(item.label)}
            getValue={(item) => item.key}
            className="mb-2 mr-2 min-w-[160px]"
            value={filterData?.orderStatus}
            onChange={(value) => onChangeFilterValue(value, "orderStatus")}
            tagTitle={t("orderStatus")}
            disabled={!filterData?.orderSource?.length}
            multiple
          />
        )}
        {reportType === REPORT_TYPE.PRODUCT_PERFORMANCE && (
          <>
            <SelectInfinityFilter
              value={filterData?.categories || []}
              placeholder={t("category")}
              fetchFn={getListCategory}
              dataKey="findCategoriesByAdmin"
              onChange={(value) => onChangeFilterValue(value, "categories")}
              className="mb-2 min-w-[150px] mr-2"
              tagTitle={t("category")}
              getValue={(item) => item.refId}
              disabled={!filterData?.orderSource?.length}
            />
            <SelectInfinityFilter
              value={filterData?.brands || []}
              placeholder={t("brand")}
              fetchFn={getListBrand}
              dataKey="findBrandByAdmin"
              onChange={(value) => onChangeFilterValue(value, "brands")}
              className="mb-2 min-w-[150px] mr-2"
              tagTitle={t("brand")}
              getValue={(item) => item.refId}
              disabled={!filterData?.orderSource?.length}
            />
            <TranslatedSelect
              placeholder={t("focType")}
              dataSource={FOC_TYPE_OPTIONS}
              getLabel={(item) => t(item.label)}
              getValue={(item) => item.key}
              className="mb-2 mr-2 min-w-[150px]"
              value={filterData?.focType}
              onChange={(value) => onChangeFilterValue(value, "focType")}
              disabled={!filterData?.orderSource?.length}
            />
            <TranslatedSelect
              placeholder={t("productType")}
              dataSource={FILTER_PRODUCT_TYPE_OPTIONS}
              getLabel={(item) => t(item.label)}
              getValue={(item) => item.value}
              className="mb-2 mr-2 min-w-[200px]"
              value={filterData?.filterProductType}
              onChange={(value) => {
                console.log(value);
                onChangeFilterValue(value, "filterProductType");
              }}
              disabled={!filterData?.orderSource?.length}
            />
            <TranslatedSelect
              placeholder={t("newLaunch")}
              dataSource={PRODUCT_LAUNCH_AT_OPTIONS}
              getLabel={(item) => t(item.label)}
              getValue={(item) => item.key}
              className="mb-2 mr-2 min-w-[150px]"
              value={filterData?.launchAt}
              onChange={(value) => onChangeFilterValue(value, "launchAt")}
              disabled={!filterData?.orderSource?.length}
            />
          </>
        )}
        {reportType === REPORT_TYPE.CARTRULE_PERFORMANCE && (
          <>
            <FilterDropdown
              selectProps={{
                placeholder: t("search"),
                multiple: true,
                mode: "multiple",
                value: filterData?.cartRuleType || [],
                getLabel: (item) => t(item.label),
                dataSource: CART_RULE_TYPE_OPTIONS ?? [],
                onChange: (value) => {
                  onChangeFilterValue(value, "cartRuleType");
                  onChangeFilterValue([], "cartRule");
                },
                className: "mb-2 w-100 min-w-[200px] mr-2",
                tagTitle: t("cartRuleType"),
                getValue: (item) => item.key,
                disabled: !filterData?.orderSource?.length,
              }}
              className="mr-2"
              type="normal"
            />
            <FilterDropdown
              selectProps={{
                placeholder: t("search"),
                multiple: true,
                mode: "multiple",
                value: filterData?.cartRule || [],
                getLabel: (item) => item.name,
                onChange: (value) => onChangeFilterValue(value, "cartRule"),
                className: "mb-2 w-100 min-w-[200px] mr-2",
                tagTitle: t("cartRule"),
                getValue: (item) => item.refId,
                disabled:
                  !filterData?.orderSource?.length ||
                  !filterData?.cartRuleType?.length,
                fetchFn: getListCartRule,
                dataKey: "findCartRuleByAdmin",
                customQuery: () => ({
                  ...(filterData?.cartRuleType
                    ? { type: filterData?.cartRuleType }
                    : {}),
                  ...(filterData?.channels
                    ? { channelIds: filterData?.channels }
                    : {}),
                }),
              }}
              className="mr-2"
              type="infinity"
            />
            {/* <SelectInfinityFilter
              value={filterData?.cartRule || []}
              placeholder={t("cartRule")}
              fetchFn={getListCartRule}
              dataKey="findCartRuleByAdmin"
              onChange={(value) => onChangeFilterValue(value, "cartRule")}
              className="w-100 min-w-[200px] mr-2"
              tagTitle={t("cartRule")}
              getValue={(item) => item.refId}
              disabled={!filterData?.orderSource?.length}
              customQuery={() => ({
                ...(filterData?.cartRuleType
                  ? { type: filterData?.cartRuleType }
                  : {}),
                ...(filterData?.channels
                  ? { channelIds: filterData?.channels }
                  : {}),
              })}
            /> */}
          </>
        )}
        {reportType === REPORT_TYPE.STAFF_PERFORMANCE && (
          <FilterDropdown
            selectProps={{
              placeholder: t("search"),
              mode: "multiple",
              value: filterData?.staffs || [],
              getLabel: (item) => item?.fullname,
              onChange: (value) => onChangeFilterValue(value, "staffs"),
              className: "mb-2 w-100 min-w-[200px] mr-2",
              tagTitle: t("staff"),
              getValue: (item) => item?.id,
              disabled:
                !filterData?.orderSource?.length ||
                !filterData?.channels?.length,
              fetchFn: getListStaff,
              dataKey: "findStaffByAdmin",
              // customQuery: () => ({
              //   ...(filterData?.channels
              //     ? { channelIds: filterData?.channels }
              //     : {}),
              // }),
            }}
            className="mr-2 min-w-[100px]"
            type="infinity"
          />
        )}
        {reportType === REPORT_TYPE.PROVINCE_PERFORMANCE && (
          <FilterDropdown
            selectProps={{
              placeholder: t("search"),
              mode: "multiple",
              value: filterData?.provinces || [],
              getLabel: (item) => item?.name,
              onChange: (value) => onChangeFilterValue(value, "provinces"),
              className: "mb-2 w-100 min-w-[200px] mr-2",
              tagTitle: t("provinces"),
              getValue: (item) => item?.id,
              // disabled: !filterData?.orderSource?.length,
              fetchFn: getListProvince,
              dataKey: "findProvincesByAdmin",
            }}
            className="mr-2 min-w-[100px]"
            type="infinity"
          />
        )}
        {reportType !== REPORT_TYPE.PRODUCT_PERFORMANCE &&
          reportType !== REPORT_TYPE.STAFF_PERFORMANCE &&
          reportType !== REPORT_TYPE.CARTRULE_PERFORMANCE &&
          reportType !== REPORT_TYPE.PROVINCE_PERFORMANCE && (
            <TranslatedSelect
              placeholder={t("paymentStatus")}
              dataSource={STATUS_FILTER_OPTIONS}
              getLabel={(item) => t(item.label)}
              getValue={(item) => item.key}
              className="mb-2 mr-2 min-w-[220px]"
              value={filterData?.paymentStatus}
              onChange={(value) => onChangeFilterValue(value, "paymentStatus")}
              disabled={
                !filterData?.orderSource?.length || viewMode === "compare"
              }
            />
          )}
        {componentAfter}
      </>
    );
  };

  return (
    <div className="d-flex items-center justify-content-between mt-1 mb-4">
      <div className="flex flex-wrap">{leftSide()}</div>
      {rightSide()}
    </div>
  );
};

export default ReportHeader;

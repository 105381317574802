import { Avatar } from "d-react-components";
import React from "react";
// import { menuFlatter } from "@infinityx/utils";
// import { isGrantPermission } from "../../../utils/PermissionUtils";
import MAIN_MENU from "../MainMenu";
import MenuItem from "./MenuItem";
import { useLocalStorage } from "react-use";
import { ADMIN_AUTH_KEY } from "../../../../constants/common";
import { IAdmin } from "../../../../interfaces/common";

export interface IMenuBarProps {
  [key: string]: any;
}

const MenuBar: React.FC<IMenuBarProps> = ({ id }) => {
  const [adminProfile] = useLocalStorage<IAdmin>(ADMIN_AUTH_KEY);
  //   const flatMenu = menuFlatter(MAIN_MENU);

  return (
    <nav
      id="app-layout__sidebar"
      className="active"
      onMouseEnter={() => {
        document
          .getElementById("app-layout__content")
          ?.classList.add("mouse-enter");
        document
          .getElementById("app-layout__sidebar")
          ?.classList.add("mouse-enter");
      }}
      onMouseLeave={() => {
        document
          .getElementById("app-layout__content")
          ?.classList.remove("mouse-enter");
        document
          .getElementById("app-layout__sidebar")
          ?.classList.remove("mouse-enter");
      }}
    >
      <div className="">
        <div className="app-layout__sidebar-header">
          <img src={"/assets/images/logos/logo.png"} alt="" />
        </div>
        <div className="app-layout__sidebar-user-info">
          <Avatar
            src={adminProfile?.avatar}
            text={`${adminProfile?.fullName}`}
            alt=""
            variant="rounded"
            className="user-info-avatar"
          />
          <text className="text-large-bold text-light">
            {`${adminProfile?.fullName}`}{" "}
            (ID: {adminProfile?.companyId})
          </text>
          {adminProfile?.email && (
            <text className="text-small text-light mt-1">
              {`${adminProfile?.email}`}
            </text>
          )}
        </div>
        <div className="app-layout__menu-list">
          {MAIN_MENU.map((menuItem, index) => {
            // const allowAccess = isGrantPermission(menuItem?.id, flatMenu);
            // if (!allowAccess) {
            //   return <div />;
            // }
            return <MenuItem menu={menuItem} key={index} />;
          })}
        </div>
        {/* <div
                    onClick={() => {
                        document
                            .getElementById("app-layout__sidebar")
                            ?.classList.toggle("active");
                        document
                            .getElementById("app-layout__content")
                            ?.classList.toggle("active");
                    }}
                    className="app-layout__sidebar-button-toggle border-top border-1 flex-center-y hover-pointer justify-content-center"
                    style={{ height: "50px" }}
                >
                    <Icon
                        name="keyboard_double_arrow_left"
                        color="white"
                        className="d-block"
                    />
                    <div className="text text-white app-layout__sidebar-button-toggle-text">
                        {Messages.collapseMenu}
                    </div>
                </div> */}
      </div>
    </nav>
  );
};

export default MenuBar;

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Icon,
  InputText,
  Notifications,
  Progress,
} from "d-react-components";
import { useLocalStorage } from "react-use";
import { isMobile } from "react-device-detect";
import { ADMIN_AUTH_KEY } from "../../constants/common";
import { useNavigate } from "react-router-dom";
import {
  useAdminLoginMutation,
  useLoginByWorkInfinityMutation,
} from "../../network/hooks";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { AUTH_ERRORS } from "../../constants/errors";
import { StringParam, useQueryParam } from "use-query-params";
// import { adminLogin } from "../../network/auth";

interface LoginProps {
  [key: string]: any;
}

interface LoginForm {
  username?: string;
  password?: string;
  authenticator?: string;
}

type LoginType = "default" | "password" | "workinfinityx";
const WORK_INFINITYX_MOBILE_URL =
  "staff.workinfinityx.com://client_id=637d8ec9fe2699db8cad876b";

export const Login: React.FC<LoginProps> = () => {
  const [loginType, setLoginType] = useState<LoginType>("default");
  const [, setAdminInfo] = useLocalStorage<any>(ADMIN_AUTH_KEY, {});
  const [adminLoginWorkInfinityX] = useLoginByWorkInfinityMutation();
  const [thirdPartyAccessToken] = useQueryParam("code", StringParam);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (thirdPartyAccessToken) {
      setTimeout(() => {
        Progress.show(
          {
            method: adminLoginWorkInfinityX,
            params: { variables: { code: thirdPartyAccessToken } },
          },
          (resp: any) => {
            const adminProfile = resp?.data?.loginByWorkInfinity ?? {};
            if (!isEmpty(adminProfile)) {
              setAdminInfo(adminProfile);
              Notifications.showSuccess(t("message:loginSuccessfully"));
              window.location.replace("/");
            }
          },
          (err: any) => {
            //@ts-ignore
            document.getElementsByClassName("ant-modal-wrap")?.[0]?.click();
            Notifications.showError(err);
          }
        );
      }, 500);
    }
  }, [thirdPartyAccessToken]);

  return (
    <div className="bg-gray-100 w-full">
      <div className="flex items-center justify-center min-h-screen">
        <div className="h-[500px] overflow-hidden">
          <img
            alt=""
            src={"/images/login-welcome.jpg"}
            className="w-[500px] h-[500px] mb-4 object-cover"
          />
        </div>
        <div className="max-w-[350px] h-[500px] w-full bg-white p-6 text-left flex items-center relative">
          <div className="w-100">
            <div className="w-100 py-3 d-flex flex-columns align-items-center justify-content-center">
              <img
                alt=""
                src={"/assets/images/logos/logo-color.png"}
                className="mb-4"
                style={{ maxWidth: "100px", maxHeight: "100px" }}
              />
            </div>
            {loginType === "default" && (
              <div>
                <a
                  href={
                    isMobile
                      ? WORK_INFINITYX_MOBILE_URL
                      : process.env.REACT_APP_WORK_INFINITY_OAUTH
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="text text-nowrap d-button d-button__large d-button__standard-primary w-100 inline-block text-white"
                >
                  <div className="bg-white w-6 h-6 p-1 rounded-1 mr-3">
                    <img
                      src="/images/workInfinityLogo.png"
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <span className="text-white">
                    {t("loginWithWorkInfinityX")}
                  </span>
                </a>
                <div className="relative my-3 text-center">
                  <div className="border-t-[1px] absolute w-full top-[18px] z-10"></div>
                  <span className="py-2 relative z-20 px-2 bg-white inline-block">
                    {t("orLoginWith")}
                  </span>
                </div>
                <Button
                  onClick={() => setLoginType("password")}
                  variant="outline"
                  className="w-100"
                >
                  {t("loginWithUsernamePassword")}
                </Button>
              </div>
            )}
            {loginType === "password" && (
              <LoginDefault setLoginType={setLoginType} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const LoginDefault = ({
  setLoginType,
}: {
  setLoginType: (type: LoginType) => void;
}) => {
  const [showAuthenticationView, setShowAuthenticationView] = useState(false);
  const [adminLogin] = useAdminLoginMutation();
  const [, setAdminInfo] = useLocalStorage<any>(ADMIN_AUTH_KEY, {});
  const { t } = useTranslation();

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required("This field is required"),
    password: Yup.string().required("This field is required"),
  });
  const loginForm = useFormik<LoginForm>({
    initialValues: {},
    validationSchema: LoginSchema,
    onSubmit: (e) => {
      const { username, password, authenticator } = values;
      if (username && password) {
        adminLogin({
          variables: {
            args: {
              username,
              password,
              authenticator,
            },
          },
        }).then(
          (resp) => {
            const loggedInData = resp?.data?.loginByAdmin;
            if (!isEmpty(loggedInData)) {
              const { error } = loggedInData ?? {};
              if (error) {
                if (error === AUTH_ERRORS.TWOFA_REQUIRED) {
                  setShowAuthenticationView(true);
                  return;
                } else {
                  // i18next-extract-disable-next-line
                  Notifications.showError(t(`notification:${error}`));
                  return;
                }
              } else {
                setAdminInfo(resp?.data?.loginByAdmin);
                Notifications.showSuccess(t("message:loginSuccessfully"));
                window.location.replace("/");
              }
            }
          },
          (error) => {
            Notifications.showError(error);
          }
        );
      }
    },
  });
  const { values, errors, handleSubmit, handleChange } = loginForm;

  return (
    <div className="w-100">
      {!showAuthenticationView && (
        <>
          <span
            className="flex cursor-pointer items-center absolute top-3 left-3"
            onClick={() => setLoginType("default")}
          >
            <Icon name="chevron_left" />
            {t("common:back")}
          </span>
          <InputText
            name="username"
            label={"Username"}
            placeholder={"Username"}
            value={values.username}
            error={errors.username}
            onChange={handleChange}
            classNameInput="w-full"
            className="mb-3"
          />
          <InputText
            name="password"
            label={"Password"}
            placeholder={"Password"}
            value={values.password}
            error={errors.password}
            onChange={handleChange}
            classNameInput="w-full"
            className="mb-3"
            type="password"
          />
        </>
      )}
      {showAuthenticationView && (
        <>
          <span
            className="flex cursor-pointer items-center absolute top-3 left-3"
            onClick={() => setShowAuthenticationView(false)}
          >
            <Icon name="chevron_left" />
            {t("common:back")}
          </span>
          <InputText
            name="authenticator"
            label="2FA Authentication"
            value={values?.authenticator}
            error={errors?.authenticator}
            onChange={handleChange}
            classNameInput="w-full"
            className="mb-3"
          />
        </>
      )}
      <Button
        color="primary"
        variant="standard"
        onClick={() => handleSubmit()}
        title={`login`}
        className="w-100 mt-4"
        type="button"
      >
        Login
      </Button>
    </div>
  );
};

export default Login;

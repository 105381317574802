import ClassNames from "classnames";
import { Icon } from "d-react-components";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { isGrantPermission } from "../../../utils/PermissionUtils";
import MAIN_MENU from "../MainMenu";
import { IMenuItemProps } from "../../../../interfaces/common";
import { menuFlatter } from "../../../../utils/menu";
import { usePathInfo } from "../../../../utils/hooks";

export interface MenuItemProps {
  menu: IMenuItemProps;
}

export interface CustomLinkProps {
  [key: string]: any;
}

export const CustomLink: React.FC<CustomLinkProps> = ({
  to,
  className,
  onClick,
  ...props
}) => {
  if (isEmpty(to)) {
    return (
      <div className={className} onClick={onClick}>
        {props.children}
      </div>
    );
  }
  return (
    <Link to={to} className={className}>
      {props.children}
    </Link>
  );
};

const MenuItem: React.FC<MenuItemProps> = ({ menu }) => {
  const location = useLocation();
  const flatMenu = menuFlatter(MAIN_MENU);
  const currentPath = usePathInfo(flatMenu);
  const { t } = useTranslation("common");

  const [openSubMenu, setOpenSubMenu] = useState(false);
  const subMenuList = menu.subMenu ?? [];
  const hasSubMenu =
    subMenuList.length > 0 &&
    subMenuList.filter((item) => !item?.notShow)?.length > 0;

  const isSelected = (menuSelect: any) => {
    if (isEmpty(menuSelect.path)) return false;
    if (menuSelect.path !== location.pathname) return false;
    return true;
  };

  const hasSubMenuActive =
    isSelected(menu) || currentPath?.parentId === menu?.id;

  const onClickMenuItem = () => {
    setOpenSubMenu(!openSubMenu);
  };

  const classNameInner = ClassNames("app-layout__menu-list-item-inner", {
    "app-layout__menu-list-item-active": isSelected(menu),
    "app-layout__menu-list-item-sub-active": hasSubMenuActive,
  });

  return (
    <div className="app-layout__menu-list-item">
      <CustomLink
        to={menu?.path}
        className={classNameInner}
        onClick={onClickMenuItem}
      >
        <Icon name={menu?.iconName ?? ""} />
        <text className="text-medium app-layout__menu-list-title">
          {/* i18next-extract-disable-next-line */}
          {t([menu.label])}
        </text>
        {hasSubMenu &&
          (!openSubMenu ? (
            <Icon
              name="chevron_right"
              className="material-icons-outlined app-layout__menu-list-icon-arrow"
            />
          ) : (
            <Icon
              name="keyboard_arrow_down"
              className="app-layout__menu-list-icon-arrow"
            />
          ))}
      </CustomLink>
      {openSubMenu && hasSubMenu && (
        <div className="app-layout__sub-menu-list">
          {subMenuList.map((subMenu: IMenuItemProps) => {
            const classNameSubMenu = ClassNames(
              "app-layout__sub-menu-item ml-3",
              {
                "app-layout__sub-menu-item-active": isSelected(subMenu),
              }
            );
            // const allowAccess = isGrantPermission(
            //     subMenu?.id,
            //     flatMenu
            // );
            // if (!allowAccess) {
            //     return <div />;
            // }
            if (subMenu.notShow) {
              return <div />;
            }
            return (
              <Link
                to={subMenu.path as any}
                className={classNameSubMenu}
                key={subMenu?.id}
              >
                {/* <Icon name="done" /> */}
                <div className="text-medium app-layout__menu-list-title">
                  {/* i18next-extract-disable-next-line */}
                  {t([subMenu.label])}
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MenuItem;

import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  DialogManager,
  HeaderDetail,
  HeaderDetailProps,
  Modal,
  Notifications,
  Progress,
  TabBar,
} from "d-react-components";
import { useTranslation } from "react-i18next";
import { createStateContext } from "react-use";
import UserSummaryView from "./content/UserSummaryView";
import CustomerProfile from "./content/UserProfile";
import CustomerChangePassword from "./content/UserChangePassword";
import UserChannel from "./content/UserChannel";
import UserRole from "./content/UserRole";
import UserAuthenticationDetail from "./content/UserAuthenticationDetail";
import { IAdmin } from "../../../interfaces/common";
import {
  useDuplicateUserByAdminMutation,
  useFindUserByAdminQuery,
} from "../../../network/hooks";
import { isEmpty } from "lodash";
import UserSensitiveData from "./content/UserSensitiveData";

export interface UserContext {
  user: IAdmin | undefined;
  loading?: boolean;
  refetch?: any;
}
export const [userUserInfo, UserInfoProvider] = createStateContext<UserContext>(
  { user: undefined }
);

const UserDetailContent: React.FC = () => {
  const [_, setUserInfo] = userUserInfo();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const { userId } = useParams<string>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [duplicateUser] = useDuplicateUserByAdminMutation();
  const { data, loading, refetch } = useFindUserByAdminQuery({
    variables: {
      id: userId as string,
    },
  });
  const user = data?.findUserByAdmin;

  const CUSTOMER_DETAIL_TAB = useMemo(
    () => [
      {
        id: "profile",
        label: t("customer:profile"),
        component: <CustomerProfile />,
      },
      {
        id: "channels",
        label: t("common:channel"),
        component: <UserChannel />,
      },
      {
        id: "roles",
        label: t("common:role"),
        component: <UserRole />,
      },
      {
        id: "authentication",
        label: t("user:authentication"),
        component: <UserAuthenticationDetail />,
      },
      {
        id: "sensitiveDataAccess",
        label: t("user:sensitiveDataAccess"),
        component: <UserSensitiveData />,
      },
    ],
    [t]
  );
  const [selectedTab, setSelectedTab] = useState(CUSTOMER_DETAIL_TAB[0]);

  useEffect(() => {
    setUserInfo({ user, loading, refetch } as UserContext);
  }, [user, loading, refetch]);

  const listButton: HeaderDetailProps["listButton"] = useMemo(
    () => [
      {
        id: "changePassword",
        icon: "key",
        label: t("customer:changePassword"),
        buttonProps: {},
        onClick: () => setShowPasswordModal(true),
      },
    ],
    [t, user]
  );

  const handleDuplicateUser = () => {
    if (!userId) {
      return;
    }
    DialogManager.showConfirm(
      t("common:warning"),
      t("notification:duplicateUserNoti"),
      () => {
        duplicateUser({
          variables: {
            input: userId as string,
          },
        })
          .then((resp) => {
            const newUser = resp?.data?.duplicateUserByAdmin;
            if (!isEmpty(newUser)) {
              navigate(`/user/detail/${newUser.id}`);
            }
          })
          .then((err: any) => {
            if (err) Notifications.showError(err);
          });
      }
    );
  };

  return (
    <>
      <HeaderDetail
        title={user?.userId ? `${user?.userId}` : "N/A"}
        listButton={listButton}
        customRight={() => (
          <div className="flex">
            <Button
              className="mr-2"
              variant="outline"
              onClick={() => handleDuplicateUser()}
            >
              {t("common:duplicate")}
            </Button>
            <Button variant="outline" onClick={() => navigate(-1)}>
              {t("common:back")}
            </Button>
          </div>
        )}
      />
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-1">
          <UserSummaryView user={user as any} loading={loading} />
        </div>
        <div className="col-span-3">
          <TabBar
            dataSource={CUSTOMER_DETAIL_TAB}
            onChange={(tab) => setSelectedTab(tab as any)}
            value={selectedTab}
            className="overflow-x-scroll my-4 border-0"
          />
          {selectedTab.component}
        </div>
      </div>
      {showPasswordModal && (
        <Modal
          open={showPasswordModal}
          onClose={() => setShowPasswordModal(false)}
          showFooter={false}
          title={t("customer:changePassword")}
        >
          <CustomerChangePassword onClose={() => setShowPasswordModal(false)} />
        </Modal>
      )}
    </>
  );
};

export const UserDetail: React.FC = () => {
  return (
    <UserInfoProvider>
      <UserDetailContent />
    </UserInfoProvider>
  );
};

export default UserDetail;

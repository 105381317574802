import { isEmpty } from "lodash";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { ADMIN_AUTH_KEY } from "../../../constants/common";
import Dashboard from "../../dashboard/Dashboard";
import Login from "../../login/Login";
import CartRulePerformace from "../../reports/CartRulePerformance";
import ChannelPerformance from "../../reports/ChannelPerformance";
import LocationPerformance from "../../reports/LocationPerformance";
import ProductPerformace from "../../reports/ProductPerformace";
import StaffPerformance from "../../reports/StaffPerformance";
import StorePerformance from "../../reports/StorePerformance";
import TotalSalesReport from "../../reports/TotalSalesReport";
import SettingRole from "../../settings/roles/SettingRole";
import SettingRoleDetail from "../../settings/roles/SettingRoleDetail";
import UserCreate from "../../users/detail/UserCreate";
import UserDetail from "../../users/detail/UserDetail";
import Users from "../../users/Users";
import { Path } from "./Paths";
import { ProtectedRoute } from "./ProtectedRoute";

export const Layout = () => {
  return (
    <LayoutInner>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.REPORT_TOTAL_SALE}
          element={
            <ProtectedRoute>
              <TotalSalesReport />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.REPORT_CHANNEL_PERFORMANCE}
          element={
            <ProtectedRoute>
              <ChannelPerformance />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.CART_RULE_PERFORMANCE}
          element={
            <ProtectedRoute>
              <CartRulePerformace />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.REPORT_STORE_PERFORMANCE}
          element={
            <ProtectedRoute>
              <StorePerformance />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.REPORT_PRODUCT_PERFORMANCE}
          element={
            <ProtectedRoute>
              <ProductPerformace />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.REPORT_STAFF_PERFORMANCE}
          element={
            <ProtectedRoute>
              <StaffPerformance />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.REPORT_PROVINCE_PERFORMANCE}
          element={
            <ProtectedRoute>
              <LocationPerformance />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.USERS}
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.NEW_USER}
          element={
            <ProtectedRoute>
              <UserCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.DETAIL_USER}
          element={
            <ProtectedRoute>
              <UserDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.SETTINGS_ROLE}
          element={
            <ProtectedRoute>
              <SettingRole />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.ROLE_CREATE}
          element={
            <ProtectedRoute>
              <SettingRoleDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.ROLE_DETAIL}
          element={
            <ProtectedRoute>
              <SettingRoleDetail />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />}></Route>
      </Routes>
    </LayoutInner>
  );
};

const LayoutInner: React.FC<any> = ({ children }) => {
  const [isAuthenticated] = useLocalStorage(ADMIN_AUTH_KEY);
  return !isEmpty(isAuthenticated) ? (
    <div className="app-layout__wrapper">{children}</div>
  ) : (
    <div className="app-layout__wrapper">
      <div className="active" id="app-layout__content">
        {children}
      </div>
    </div>
  );
};

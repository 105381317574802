import {
  AwesomeTableComponent,
  Card,
  DialogManager,
  IColumnsProps,
  Icon,
  Modal,
  Notifications,
  Progress,
} from "d-react-components";
import { ElementRef, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRemoveUserRoleMutation } from "../../../../network/hooks";
import { userUserInfo } from "../UserDetail";
import UserRoleUpdate from "./UserRoleUpdate";
import { useLocalStorage } from "react-use";
import { ADMIN_AUTH_KEY } from "../../../../constants/common";

const UserRole = () => {
  const { t } = useTranslation();
  const [removeRoles] = useRemoveUserRoleMutation();
  const [{ user, refetch }] = userUserInfo();
  const tableRef = useRef<any>(null);
  const [openProfileUpdateDrawer, setOpenProfileUpdateDrawer] = useState(false);
  const [admin, setAdmin] = useLocalStorage<any>(ADMIN_AUTH_KEY);

  const confirmRemoveRole = (roles: string[]) => {
    DialogManager.showConfirm(
      t("common:warning"),
      t("notification:confirmRemoveRole"),
      () => {
        Progress.show(
          {
            method: removeRoles,
            params: {
              variables: {
                input: {
                  id: user?.id,
                  roles,
                },
              },
            },
          },
          (resp: any) => {
            Notifications.showSuccess("notification:removeRoleSuccessfully");
            setAdmin({ ...admin, ...resp?.data?.removeUserRole });
            refetch();
          }
        );
      }
    );
  };

  const tableColumns: IColumnsProps = useMemo(
    () => [
      {
        title: t("common:roleId"),
        dataIndex: "roleId",
        align: "left",
        width: 30,
        render: (data, item) => data,
      },
      {
        title: t("common:name"),
        dataIndex: "name",
        align: "left",
        width: 200,
        render: (data: any, item: any, index) => data,
      },
      {
        title: t("common:action"),
        dataIndex: "id",
        align: "left",
        width: 60,
        render: (data, item) => {
          return (
            <Icon
              name="remove_circle"
              className="text-red-500 cursor-pointer"
              onClick={() => confirmRemoveRole([item.id])}
            />
          );
        },
      },
    ],
    [t]
  );

  return (
    <Card customHeader={<></>}>
      <div className="flex justify-end items-end mb-4">
        <span
          className="cursor-pointer text-red-500"
          onClick={() => setOpenProfileUpdateDrawer(true)}
        >
          {t("common:add")}
        </span>
      </div>
      {/* @ts-ignore */}
      <AwesomeTableComponent
        dataSource={user?.roles}
        columns={tableColumns}
        ref={tableRef}
      />
      {openProfileUpdateDrawer && (
        <Modal
          open={openProfileUpdateDrawer}
          onClose={() => setOpenProfileUpdateDrawer(false)}
          showFooter={false}
          title={t("common:role")}
          width={800}
        >
          <UserRoleUpdate
            onClose={() => {
              setOpenProfileUpdateDrawer(false);
              refetch();
            }}
          />
        </Modal>
      )}
    </Card>
  );
};

export default UserRole;

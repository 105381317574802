import {
  AwesomeTableComponent,
  Card,
  IColumnsProps,
  Notifications,
  Progress,
  Select,
  TimeUtils,
} from "d-react-components";
import { Spin } from "antd";
import { useApolloClient } from "@apollo/client";
import {
  filter,
  isEmpty,
  isNaN,
  isNil,
  isNumber,
  isUndefined,
  omit,
  omitBy,
  orderBy,
  sumBy,
} from "lodash";
import React, { useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import moment, { Moment } from "moment";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import {
  CompareTerm,
  CompareTermCriteria,
  CompareValue,
  FILTER_TYPE,
  FILTER_TYPE_OPTIONS,
} from "../../constants/report";
import {
  ReportTotalSalesDocument,
  useReportChannelPerformanceLazyQuery,
  useReportTotalSalesLazyQuery,
} from "../../network/hooks";
import ReportHeader, {
  FilterDataProps,
} from "../common/report/header/ReportHeader";
import {
  convertToLocalTime,
  convertToLocalTimeDayjs,
  getListOfQuarter,
  groupDateToMonths,
  groupDateToMonthsAlt,
  groupDateToWeeks,
} from "../../utils/time";
import ReportContext from "./ReportContext";
import AppContext from "../common/AppContext/ReportContext";
import { exportToXLS } from "../../utils/file";
import { moneyFormatter } from "../../utils/money";
import { Channel } from "../../interfaces/common";
import { getDifferenceInPercent, getDifferenceText } from "../../utils/chart";
import TableSummary from "../common/Table/TableSummary";
import { TranslatedSelect } from "../common/Select/Select";
dayjs.extend(quarterOfYear);

const TotalSalesReportContent = () => {
  const client = useApolloClient();
  const { t } = useTranslation();
  const [loadingCompareChart, setLoadingCompareChart] = useState(false);
  const [compareChartData, setCompareChartData] = useState<any>([]);
  const [filterBy, setFilterBy] = useState(FILTER_TYPE.BY_DAY);
  const [getReport, { data, loading, refetch }] =
    useReportTotalSalesLazyQuery();
  const { filterData, channelList, compareData, viewMode } =
    useContext(AppContext);

  const onChangeFilter = (filterData: FilterDataProps) => {
    if (!filterData?.orderSource?.length || viewMode !== "normal") {
      return;
    }
    const payload = {
      orderSource: filterData?.orderSource,
      channels: filterData?.channels?.length
        ? filterData.channels
        : channelList.map((channel: Channel) => channel.refId),
      start: filterData?.currentRange?.[0].startOf("d").toDate(),
      end: filterData?.currentRange?.[1].endOf("d").toDate(),
      paymentStatus: filterData?.paymentStatus,
    };
    setTimeout(() => {
      Progress.show(
        {
          method: getReport,
          params: [
            {
              variables: {
                args: omitBy(payload, isNil),
              },
            },
          ],
        },
        () => {}
      );
    });

    // setFilterData(filterData);
  };

  const lineChartData = useMemo(() => {
    if (!filterData) {
      return [];
    }
    const reportItems =
      data?.reportTotalSales.map((item) => ({
        ...item,
        created: convertToLocalTime(item.created),
      })) ?? [];
    if (reportItems.length === 0) {
      return [];
    }
    let timeRange;
    if (filterBy === FILTER_TYPE.BY_WEEK) {
      const weeks = groupDateToWeeks(
        filterData.currentRange[0],
        filterData.currentRange[1]
      );
      return weeks.map((week, index) => {
        const dataOnTheWeek = filter(reportItems, (reportItem) =>
          moment(reportItem.created).isBetween(week[0], week[1])
        );
        return {
          date: [moment(week[0]), moment(week[1])],
          index,
          formattedDate: `${moment(week[0]).format("DD/MM/YYYY")}-${moment(
            week[1]
          ).format("DD/MM/YYYY")}`,
          formattedMonth: moment(week[0]).format("MMM"),
          total: sumBy(dataOnTheWeek, (data) => data?.total ?? 0),
        };
      });
    }

    if (filterBy === FILTER_TYPE.BY_MONTH) {
      const months = groupDateToMonths(
        filterData.currentRange[0],
        filterData.currentRange[1]
      );
      return months.map((month, index) => {
        const dataOnTheMonth = filter(reportItems, (reportItem) =>
          moment(reportItem.created).isSame(month, "month")
        );
        return {
          date: moment(month),
          index,
          formattedDate: moment(month).format("MM/YYYY"),
          formattedMonth: moment(month).format("MMM"),
          total: sumBy(dataOnTheMonth, (data) => data?.total ?? 0),
        };
      });
    }
    if (filterBy === FILTER_TYPE.BY_DAY) {
      timeRange = TimeUtils.convertRangeDateToArray(
        filterData.currentRange[0],
        filterData.currentRange[1]
      );
      return timeRange.map((time, index) => {
        const dataOnTheDate = filter(reportItems, (reportItem) =>
          moment(time).isSame(moment(reportItem.created), "day")
        );
        return {
          date: moment(time),
          formattedDate: moment(time).format("DD/MM"),
          formattedMonth: moment(time).format("MMM"),
          total: sumBy(dataOnTheDate, (data) => data?.total ?? 0),
          index,
        };
      });
    }
    return [];
  }, [data, filterBy, filterData]);

  const handleExportData = () => {
    let mappedData: any[] = [];
    if (viewMode === "normal") {
      switch (filterBy) {
        case FILTER_TYPE.BY_DAY:
          mappedData = lineChartData.map((item) => ({
            No: item.index + 1,
            Date: moment(item.date as Moment).format("DD/MM/YYYY"),
            "Actual Sale": item?.total,
            ...omit(item, [
              "date",
              "formattedDate",
              "formattedMonth",
              "index",
              "total",
            ]),
          }));
          break;

        case FILTER_TYPE.BY_WEEK:
          mappedData = lineChartData.map((item) => {
            const date: Moment[] = item.date as Moment[];
            return {
              No: item.index + 1,
              Date: `${moment(date[0]).format("DD/MM/YYYY")}-${moment(
                date[1]
              ).format("DD/MM/YYYY")}`,
              "Actual Sale": item.total,
              ...omit(item, [
                "date",
                "formattedDate",
                "formattedMonth",
                "index",
                "total",
              ]),
            };
          });
          break;

        case FILTER_TYPE.BY_MONTH:
          mappedData = lineChartData.map((item) => ({
            No: item.index + 1,
            Date: moment(item.date as Moment).format("MM/YYYY"),
            "Actual Sale": item.total,
            ...omit(item, [
              "date",
              "formattedDate",
              "formattedMonth",
              "index",
              "total",
            ]),
          }));
          break;
      }
      mappedData.push({
        No: "",
        Date: "",
        "Actual Sale": sumBy(mappedData, "Actual Sale"),
      });
    } else if (viewMode === "compare") {
      if (compareData.term === CompareTerm.YOY) {
        mappedData = compareChartData.map((item: any, index: number) => ({
          No: index + 1,
          Period: item.tableTitle,
          "Total Sales (Recent Period)": item.formerIndex,
          "Total Sales (Previous Period)": item.latterIndex,
          "Total Sales (Difference)": getDifferenceText(item),
        }));
      }
      if (compareData.term === CompareTerm.QOQ) {
        const generatedData = generateDataTableQOQ(compareChartData);
        mappedData = generatedData.map((item: any, index: number) => ({
          No: index + 1,
          Period: item.tableTitle,
          "Total Sales (Recent Period)": item.formerIndex,
          "Total Sales (Previous Period)": item.latterIndex,
          "Total Sales (Difference)": getDifferenceText(item),
        }));
      }
    }
    exportToXLS(mappedData, "export");
  };

  const onChangeCompare = async () => {
    let {
      selectedOption,
      term,
      termCriteria,
      ofYear,
      compareToYear,
      periodFrom,
      periodTo,
    } = compareData;

    // if (!filterData?.orderSource?.length) {
    //   return;
    // }
    let formerTime = dayjs(),
      latterTime = dayjs(),
      numberOfMonths = 0,
      numberOfYears = 0;
    switch (selectedOption) {
      case CompareValue.LAST_3_MONTHS:
        term = CompareTerm.YOY;
        termCriteria = CompareTermCriteria.BY_MONTH;
        numberOfMonths = 3;
        numberOfYears = 1;
        formerTime = dayjs();
        break;
      case CompareValue.LAST_6_MONTHS:
        term = CompareTerm.YOY;
        termCriteria = CompareTermCriteria.BY_MONTH;
        numberOfMonths = 6;
        numberOfYears = 1;
        formerTime = dayjs();
        break;
      case CompareValue.LAST_12_MONTHS:
        term = CompareTerm.YOY;
        termCriteria = CompareTermCriteria.BY_MONTH;
        numberOfMonths = 12;
        numberOfYears = 1;
        formerTime = dayjs();
        latterTime = formerTime.subtract(1, "y");
        break;
      case CompareValue.LAST_4_QUARTERS_OY:
        term = CompareTerm.YOY;
        termCriteria = CompareTermCriteria.BY_QUARTER;
        numberOfMonths = 12;
        numberOfYears = 1;
        formerTime = dayjs();
        break;
      case CompareValue.LAST_4_QUARTERS:
        term = CompareTerm.QOQ;
        termCriteria = CompareTermCriteria.BY_QUARTER;
        numberOfMonths = 12;
        formerTime = dayjs().endOf("y");
        numberOfYears = 1;
        break;
      case CompareValue.CUSTOM:
        if (
          term === CompareTerm.YOY &&
          termCriteria === CompareTermCriteria.BY_QUARTER
        ) {
          const formerYear = Number(moment(ofYear).format("YYYY"));
          const latterYear = Number(moment(compareToYear).format("YYYY"));
          formerTime = dayjs()
            .endOf("year")
            .endOf("quarter")
            .set("y", formerYear > latterYear ? formerYear : latterYear);
          numberOfMonths = 12;
          numberOfYears = Math.abs(formerYear - latterYear);
        }
        if (
          term === CompareTerm.YOY &&
          termCriteria === CompareTermCriteria.BY_MONTH
        ) {
          const formerYear = Number(moment(ofYear).format("YYYY"));
          const latterYear = Number(moment(compareToYear).format("YYYY"));
          const fromMonth = moment(periodFrom).set("y", formerYear).toDate();
          const toMonth = moment(periodTo).set("y", formerYear).toDate();
          numberOfMonths =
            Math.abs(dayjs(fromMonth).diff(toMonth, "month")) + 1;
          formerTime = dayjs(toMonth)
            .endOf("M")
            .set("y", formerYear > latterYear ? formerYear : latterYear);
          numberOfYears = Math.abs(formerYear - latterYear);
        }
        if (term === CompareTerm.QOQ) {
          const formerYear = Number(moment(ofYear).format("YYYY"));
          formerTime = dayjs().set("y", formerYear).endOf("y");
          numberOfYears = 1;
          numberOfMonths = 12;
        }
        break;
    }
    const payload = {
      orderSource: compareData?.orderSource,
      channels: compareData?.channels?.length
        ? compareData.channels
        : channelList.map((channel: Channel) => channel.refId),
      paymentStatus: compareData?.paymentStatus,
    };
    const formerPayload = {
      ...payload,
      start: dayjs(formerTime)
        .subtract(numberOfMonths - 1, "M")
        .startOf("M")
        .startOf("d")
        .toDate(),
      end: dayjs(formerTime).endOf("M").endOf("d").toDate(),
    };
    const latterPayload = {
      ...payload,
      start: dayjs(formerTime)
        .subtract(numberOfYears, "y")
        .subtract(numberOfMonths - 1, "M")
        .startOf("M")
        .startOf("d")
        .toDate(),
      end: dayjs(formerTime)
        .subtract(numberOfYears, "y")
        .endOf("M")
        .endOf("d")
        .toDate(),
    };
    const promises = [
      client.query({
        query: ReportTotalSalesDocument,
        variables: { args: omitBy(formerPayload, isNil) },
        fetchPolicy: "no-cache",
      }),
    ];
    if (term === CompareTerm.YOY) {
      promises.push(
        client.query({
          query: ReportTotalSalesDocument,
          variables: { args: omitBy(latterPayload, isNil) },
          fetchPolicy: "no-cache",
        })
      );
    }
    try {
      setLoadingCompareChart(true);
      Promise.all(promises)
        .then((resp) => {
          const formerResult = resp?.[0]?.data?.reportTotalSales?.map(
            (item: any) => ({
              ...item,
              created: convertToLocalTimeDayjs(item.created),
            })
          );
          const latterResult =
            resp?.[1]?.data?.reportTotalSales?.map((item: any) => ({
              ...item,
              created: convertToLocalTimeDayjs(item.created),
            })) ?? [];
          if (
            term === CompareTerm.YOY &&
            termCriteria === CompareTermCriteria.BY_MONTH
          ) {
            const formerMonths = groupDateToMonthsAlt(
              dayjs(formerTime).subtract(numberOfMonths - 1, "M"),
              dayjs(formerTime).endOf("d")
            );
            const latterMonths = groupDateToMonthsAlt(
              dayjs(formerTime)
                .subtract(numberOfYears, "y")
                .subtract(numberOfMonths - 1, "M"),
              dayjs(formerTime).subtract(numberOfYears, "y").endOf("d")
            );
            const formerData = getCompareChartData(formerMonths, formerResult);
            const latterData = getCompareChartData(latterMonths, latterResult);
            const combinedData = formerData.map((item, index) => {
              return {
                name: `${latterData[index].formattedDate} - ${item.formattedDate}`,
                tableTitle: `${item.formattedDate} vs ${latterData[index].formattedDate}`,
                formerIndex: item.total,
                latterIndex: latterData[index].total,
              };
            });
            setCompareChartData(combinedData);
          } else if (
            term === CompareTerm.YOY &&
            termCriteria === CompareTermCriteria.BY_QUARTER
          ) {
            const formerData = getCompareChartDataByQuarter(
              getListOfQuarter(formerTime),
              formerResult
            );
            const latterData = getCompareChartDataByQuarter(
              getListOfQuarter(formerTime.subtract(numberOfYears, "y")),
              latterResult
            );
            const combinedData = formerData.map((item, index) => {
              return {
                name: `${latterData[index].formattedDate} - ${item.formattedDate}`,
                tableTitle: `${item.formattedDate} vs ${latterData[index].formattedDate}`,
                formerIndex: item.total,
                latterIndex: latterData[index].total,
              };
            });
            setCompareChartData(combinedData);
          } else if (term === CompareTerm.QOQ) {
            const formerData = getCompareChartDataQuarterByQuarter(
              getListOfQuarter(formerTime),
              formerResult
            );
            setCompareChartData(formerData);
          }
        })
        .finally(() => {
          setLoadingCompareChart(false);
        });
    } catch (err) {
      Notifications.showError(err);
    }
  };
  // console.log(viewMode, compareChartData);

  const getCompareChartData = (months: any[], rawData: any) => {
    return months.map((month, index) => {
      const dataOnTheMonth: any[] = (rawData ?? []).filter((reportItem: any) =>
        dayjs(reportItem.created).isSame(month, "month")
      );
      const currentTime: any = dayjs(month);
      return {
        index,
        formattedDate: currentTime.format("MM/YYYY"),
        formattedMonth: currentTime.format("MMM"),
        formattedYear: currentTime.format("YYYY"),
        total: sumBy(dataOnTheMonth, (data) => data?.total ?? 0),
      };
    });
  };

  const getCompareChartDataByQuarter = (quarters: any[], rawData: any) => {
    return quarters.map((dayInQuarter, index) => {
      const quarter = dayjs(dayInQuarter).quarter();
      const dataOnTheQ: any[] = (rawData ?? []).filter(
        (reportItem: any) => dayjs(reportItem.created).quarter() === quarter
      );
      return {
        index,
        formattedDate: `${quarter}Q${dayjs(dayInQuarter).format("YYYY")}`,
        total: sumBy(dataOnTheQ, (data) => data?.total ?? 0),
      };
    });
  };

  const getCompareChartDataQuarterByQuarter = (
    quarters: any[],
    rawData: any
  ) => {
    return quarters.map((dayInQuarter, index) => {
      const quarter = dayjs(dayInQuarter).quarter();
      const dataOnTheQ: any[] = (rawData ?? []).filter(
        (reportItem: any) => dayjs(reportItem.created).quarter() === quarter
      );
      return {
        index,
        formattedDate: `${quarter}Q${dayjs(dayInQuarter).format("YYYY")}`,
        total: sumBy(dataOnTheQ, (data) => data?.total ?? 0),
      };
    });
  };

  return (
    <div>
      <ReportHeader
        onChangeFilter={onChangeFilter}
        handleExportData={handleExportData}
        onChangeCompare={onChangeCompare}
      />
      <Card title={t("report:totalSales")} className="mb-4 relative">
        <div className="h-[400px]">
          <TranslatedSelect
            dataSource={FILTER_TYPE_OPTIONS}
            value={filterBy}
            onChange={(val) => setFilterBy(val)}
            className="w-[120px] absolute top-4 right-4"
            getLabel={(item) => t(item.label)}
            disabled={viewMode === "compare"}
          />
          {viewMode === "normal" && (
            <>
              <ChartNormal lineChartData={lineChartData} />
            </>
          )}
          {viewMode === "compare" && (
            <>
              {loadingCompareChart && (
                <div className="absolute w-full h-full bg-white/75 flex items-center justify-content-center z-[50]">
                  <Spin size="large" />
                </div>
              )}
              {compareData.term === CompareTerm.YOY && (
                <>
                  <ChartYOY compareChartData={compareChartData} />
                </>
              )}
              {compareData.term === CompareTerm.QOQ && (
                <>
                  <ChartQOQ compareChartData={compareChartData} />
                </>
              )}
            </>
          )}
        </div>
      </Card>
      <Card className="mb-4 relative">
        {viewMode === "normal" && (
          <>
            <ChartNormal lineChartData={lineChartData} />
            <DataTableNormal
              lineChartData={lineChartData}
              filterBy={filterBy}
            />
          </>
        )}
        {viewMode === "compare" && (
          <>
            {compareData.term === CompareTerm.YOY && (
              <>
                <DataTableYOY chartData={compareChartData} />
              </>
            )}
            {compareData.term === CompareTerm.QOQ && (
              <>
                <DataTableQOQ chartData={compareChartData} />
              </>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

const CustomBarLabel = ({
  x,
  y,
  value,
  barWidth,
  refData,
  index,
  ...rest
}: any) => {
  const dataToCompare = refData?.[index]?.latterIndex;
  const isIncrease = value >= dataToCompare;
  const difference = useMemo(() => {
    if (refData) {
      if (dataToCompare > value) {
        return (((dataToCompare - value) / dataToCompare) * 100).toFixed(0);
      } else {
        return (((value - dataToCompare) / dataToCompare) * 100).toFixed(0);
      }
    }
    return null;
  }, [refData, dataToCompare]);

  return (
    <g>
      <text
        style={{ fontSize: "0.675rem" }}
        x={x + barWidth / 2}
        y={y - (!difference ? 0 : 15)}
        fill="#A7A7A7"
        textAnchor="middle"
        dy={-6}
      >
        {moneyFormatter.format(value)}
      </text>
      {isIncrease && difference && (
        <text
          style={{ fontSize: "0.675rem" }}
          x={x + barWidth / 2}
          y={y}
          fill="#33B950"
          textAnchor="middle"
          dy={-6}
        >
          +{difference}%
        </text>
      )}
      {!isIncrease && difference && (
        <text
          style={{ fontSize: "0.675rem" }}
          x={x + barWidth / 2}
          y={y}
          fill="#ED6969"
          textAnchor="middle"
          dy={-6}
        >
          -{difference}%
        </text>
      )}
    </g>
  );
};

const CustomBarLabelQOQ = ({
  x,
  y,
  value,
  barWidth,
  refData,
  index,
  ...rest
}: any) => {
  const dataToCompare = refData?.[index - 1]?.total;
  const isIncrease = value >= dataToCompare;
  const difference = useMemo(() => {
    if (index === 0) {
      return null;
    }
    if (dataToCompare === 0) {
      return 0;
    }
    if (refData) {
      if (dataToCompare > value) {
        return (((dataToCompare - value) / dataToCompare) * 100).toFixed(0);
      } else {
        return (((value - dataToCompare) / dataToCompare) * 100).toFixed(0);
      }
    }
    return null;
  }, [refData]);

  return (
    <g>
      <text
        style={{ fontSize: "0.675rem" }}
        x={x + barWidth / 2}
        y={y - (!difference ? 0 : 15)}
        fill="#A7A7A7"
        textAnchor="middle"
        dy={-6}
      >
        {moneyFormatter.format(value)}
      </text>
      {isIncrease && difference && (
        <text
          style={{ fontSize: "0.675rem" }}
          x={x + barWidth / 2}
          y={y}
          fill="#33B950"
          textAnchor="middle"
          dy={-6}
        >
          +{difference}%
        </text>
      )}
      {!isIncrease && difference && (
        <text
          style={{ fontSize: "0.675rem" }}
          x={x + barWidth / 2}
          y={y}
          fill="#ED6969"
          textAnchor="middle"
          dy={-6}
        >
          -{difference}%
        </text>
      )}
    </g>
  );
};

const ChartNormal = ({ lineChartData }: any) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={400}
        data={lineChartData}
        margin={{ bottom: 40, top: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Bar name="Actual Sale" dataKey="total" barSize={50} fill="#041B47" />
        {/* <Line name="KPI" dataKey="kpi" stroke="#219653" strokeWidth="2" /> */}
        <XAxis dataKey="formattedDate" stroke="#C0C7CE" />
        <YAxis
          stroke="#C0C7CE"
          style={{
            fontSize: ".675rem",
          }}
        />
        <Tooltip
          formatter={(value, name, props) =>
            moneyFormatter.format(value as number)
          }
          cursor={false}
        />
        <Legend />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const ChartYOY = ({ compareChartData }: any) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={400}
        data={compareChartData}
        margin={{ bottom: 40, top: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Bar dataKey="latterIndex" barSize={50} fill="#84C4FF">
          <LabelList
            dataKey="latterIndex"
            position="top"
            content={<CustomBarLabel barWidth={50} />}
          />
        </Bar>
        <Bar dataKey="formerIndex" barSize={50} fill="#041B47">
          <LabelList
            dataKey="formerIndex"
            position="top"
            content={
              <CustomBarLabel barWidth={50} refData={compareChartData} />
            }
          />
        </Bar>
        <XAxis
          dataKey="name"
          stroke="#C0C7CE"
          style={{
            fontSize: ".675rem",
          }}
        />
        <YAxis
          stroke="#C0C7CE"
          style={{
            fontSize: ".675rem",
          }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const ChartQOQ = ({ compareChartData }: any) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={400}
        data={compareChartData}
        margin={{ bottom: 40, top: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Bar dataKey="total" barSize={50} fill="#84C4FF">
          <LabelList
            dataKey="total"
            position="top"
            content={
              <CustomBarLabelQOQ barWidth={50} refData={compareChartData} />
            }
          />
        </Bar>
        <XAxis
          dataKey="formattedDate"
          stroke="#C0C7CE"
          style={{
            fontSize: ".675rem",
          }}
        />
        <YAxis
          stroke="#C0C7CE"
          style={{
            fontSize: ".675rem",
          }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const DataTableNormal = ({ lineChartData, filterBy }: any) => {
  const tableRef = useRef<any>(null);
  const { t } = useTranslation();
  const tableData = useMemo(() => {
    const sortedData = orderBy(lineChartData, "date", "desc") as any[];
    return sortedData.map((item, index) => {
      return { ...item, index };
    });
  }, [lineChartData]);

  const tableColumns: IColumnsProps = useMemo(
    () => [
      {
        title: t("report:no"),
        dataIndex: "index",
        align: "left",
        width: 100,
        render: (value, item, index) => {
          return !isNumber(value) ? value : value + 1;
        },
      },
      {
        title: t("report:date"),
        dataIndex: "date",
        align: "left",
        width: 200,
        render: (value, item) => {
          if (filterBy === FILTER_TYPE.BY_DAY) {
            return item?.date?.format("DD/MM/YYYY");
          }
          if (filterBy === FILTER_TYPE.BY_MONTH) {
            return item?.date?.format("MM/YYYY");
          }
          if (filterBy === FILTER_TYPE.BY_WEEK) {
            return `${item?.date?.[0]?.format(
              "DD/MM/YYYY"
            )} - ${item?.date?.[1]?.format("DD/MM/YYYY")}`;
          }
        },
      },
      {
        title: t("report:actualSale"),
        dataIndex: "total",
        align: "left",
        width: 200,
        render: (value) => {
          return moneyFormatter.format(value);
        },
      },
    ],
    [filterBy, t]
  );

  const sumData = useMemo(() => {
    return isEmpty(tableData)
      ? {}
      : {
          index: t("sum"),
          total: sumBy(tableData, "total"),
        };
  }, [tableData]);


  return (
    <>
      {/* @ts-ignore */}
      <AwesomeTableComponent
        dataSource={tableData}
        columns={tableColumns}
        ref={tableRef}
        title={() => t("report:dataTable")}
        summary={() => <TableSummary data={sumData} columns={tableColumns} />}
      />
    </>
  );
};

const DataTableYOY = ({ chartData }: any) => {
  const tableRef = useRef<any>(null);
  const { t } = useTranslation();
  const tableData = useMemo(() => {
    return chartData;
  }, [chartData]);

  const tableColumns: IColumnsProps = useMemo(
    () => [
      {
        title: t("report:no"),
        dataIndex: "index",
        align: "left",
        width: 100,
        render: (value, item, index) => {
          return index + 1;
        },
      },
      {
        title: (
          <div className="flex flex-column">
            <span>{t("report:period")}</span>
            <span className="text-xs text-gray-400">
              {t("report:vsPreviousPeriod")}
            </span>
          </div>
        ),
        dataIndex: "tableTitle",
        align: "left",
      },
      {
        title: (
          <div className="flex flex-column">
            <span>{t("report:totalSales")}</span>
            <span className="text-xs text-gray-400">
              {t("report:recentPeriod")}
            </span>
          </div>
        ),
        dataIndex: "formerIndex",
        align: "left",
        width: 200,
        render: (data) => moneyFormatter.format(data),
      },
      {
        title: (
          <div className="flex flex-column">
            <span>{t("report:totalSales")}</span>
            <span className="text-xs text-gray-400">
              {t("report:previousPeriod")}
            </span>
          </div>
        ),
        dataIndex: "latterIndex",
        align: "left",
        width: 200,
        render: (data) => moneyFormatter.format(data),
      },
      {
        title: (
          <div className="flex flex-column">
            <span>{t("report:totalSales")}</span>
            <span className="text-xs text-gray-400">
              {t("report:difference")}
            </span>
          </div>
        ),
        dataIndex: "latterIndex",
        align: "left",
        width: 200,
        render: (value, item) => {
          const { isIncrease, difference, percentDiff } =
            getDifferenceInPercent(item);
          return (
            <>
              {isIncrease
                ? moneyFormatter.format(difference)
                : `(${moneyFormatter.format(Math.abs(difference))})`}
              {isIncrease ? (
                <span className="text-green-500"> (+{percentDiff}%)</span>
              ) : (
                <span className="text-red-500"> (-{percentDiff}%)</span>
              )}
            </>
          );
        },
      },
    ],
    [t]
  );
  return (
    <>
      {/* @ts-ignore */}
      <AwesomeTableComponent
        dataSource={tableData}
        columns={tableColumns}
        ref={tableRef}
        title={() => t("report:dataTable")}
      />
    </>
  );
};

const DataTableQOQ = ({ chartData }: any) => {
  const tableRef = useRef<any>(null);
  const { t } = useTranslation();
  const tableData = useMemo(() => {
    return generateDataTableQOQ(chartData);
  }, [chartData]);

  const tableColumns: IColumnsProps = useMemo(
    () => [
      {
        title: t("report:no"),
        dataIndex: "index",
        align: "left",
        width: 100,
        render: (value, item, index) => {
          return index + 1;
        },
      },
      {
        title: (
          <div className="flex flex-column">
            <span>{t("report:period")}</span>
            <span className="text-xs text-gray-400">
              {t("report:vsPreviousPeriod")}
            </span>
          </div>
        ),
        dataIndex: "tableTitle",
        align: "left",
      },
      {
        title: (
          <div className="flex flex-column">
            <span>{t("report:totalSales")}</span>
            <span className="text-xs text-gray-400">
              {t("report:recentPeriod")}
            </span>
          </div>
        ),
        dataIndex: "formerIndex",
        align: "left",
        width: 200,
        render: (data) => moneyFormatter.format(data),
      },
      {
        title: (
          <div className="flex flex-column">
            <span>{t("report:totalSales")}</span>
            <span className="text-xs text-gray-400">
              {t("report:previousPeriod")}
            </span>
          </div>
        ),
        dataIndex: "latterIndex",
        align: "left",
        width: 200,
        render: (data) => moneyFormatter.format(data),
      },
      {
        title: (
          <div className="flex flex-column">
            <span>{t("report:totalSales")}</span>
            <span className="text-xs text-gray-400">
              {t("report:difference")}
            </span>
          </div>
        ),
        dataIndex: "latterIndex",
        align: "left",
        width: 200,
        render: (value, item) => {
          const { isIncrease, difference, percentDiff } =
            getDifferenceInPercent(item);
          return (
            <>
              {isIncrease
                ? moneyFormatter.format(difference)
                : `(${moneyFormatter.format(Math.abs(difference))})`}
              {isIncrease ? (
                <span className="text-green-500"> (+{percentDiff}%)</span>
              ) : (
                <span className="text-red-500"> (-{percentDiff}%)</span>
              )}
            </>
          );
        },
      },
    ],
    [t]
  );
  return (
    <>
      {/* @ts-ignore */}
      <AwesomeTableComponent
        dataSource={tableData}
        columns={tableColumns}
        ref={tableRef}
        title={() => t("report:dataTable")}
      />
    </>
  );
};

const generateDataTableQOQ = (chartData: any[]) => {
  const tableData = [];
  for (let i = 0; i < chartData.length - 1; i++) {
    const item = chartData[i];
    const nextItem = chartData[i + 1];
    tableData.push({
      tableTitle: `${item.formattedDate} vs ${nextItem.formattedDate}`,
      formerIndex: nextItem.total,
      latterIndex: item.total,
    });
  }
  return tableData;
};

const TotalSalesReport = () => {
  return (
    <ReportContext>
      <TotalSalesReportContent />
    </ReportContext>
  );
};

export default TotalSalesReport;

import {
  AwesomeTableComponent,
  Card,
  DialogManager,
  IColumnsProps,
  Icon,
  InputTextSearch,
  Modal,
  Notifications,
  Progress,
} from "d-react-components";
import { orderBy } from "lodash";
import { ElementRef, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRemoveUserChannelMutation, useUpdateUserByAdminMutation } from "../../../../network/hooks";
import { userUserInfo } from "../UserDetail";
import UserChannelRemove from "./UserChannelRemove";
import UserChannelUpdate from "./UserChannelUpdate";

const UserChannel = () => {
  const { t } = useTranslation();
  const [{ user, loading, refetch }] = userUserInfo();
  const [updateUser] = useUpdateUserByAdminMutation();
  const [removeChannels] = useRemoveUserChannelMutation();
  const tableRef = useRef<any>(null);
  const [query, setQuery] = useState("");
  const [tableQuery, setTableQuery] = useState("");
  const [openProfileUpdateDrawer, setOpenProfileUpdateDrawer] = useState(false);
  const [openRemoveChannelDrawer, setOpenRemoveChannelDrawer] = useState(false);

  const confirmRemoveChannel = (channels: string[]) => {
    DialogManager.showConfirm(
      t("common:warning"),
      t("notification:confirmRemoveChannel"),
      () => {
        Progress.show(
          {
            method: removeChannels,
            params: {
              variables: {
                input: {
                  id: user?.id,
                  channels,
                },
              },
            },
          },
          () => {
            Notifications.showSuccess(t("notification:removeChannelSuccessfully"));
            refetch();
          }
        );
      }
    );
  };

  const tableColumns: IColumnsProps = useMemo(
    () => [
      {
        title: t("common:name"),
        dataIndex: "name",
        align: "left",
        width: 200,
        render: (data, item) => data,
      },
      {
        title: t("common:action"),
        dataIndex: "id",
        align: "left",
        width: 60,
        render: (data, item) => {
          return (
            <Icon
              name="remove_circle"
              className="text-red-500 cursor-pointer"
              onClick={() => confirmRemoveChannel([item.id])}
            />
          );
        },
      },
    ],
    [t]
  );

  const userChannels = useMemo(
    () =>
      orderBy(
        user?.channels?.filter((channel) =>
          tableQuery
            ? channel.name.toLowerCase().includes(tableQuery.toLowerCase())
            : true
        ) ?? [],
        "name"
      ),
    [user, tableQuery]
  );

  return (
    <Card customHeader={<></>}>
      <div className="flex justify-end items-end mb-4">
        <span
          className="cursor-pointer text-red-500"
          onClick={() => setOpenProfileUpdateDrawer(true)}
        >
          {t("common:add")}
        </span>
        <span
          className="cursor-pointer text-red-500 ml-3"
          onClick={() => setOpenRemoveChannelDrawer(true)}
        >
          {t("common:remove")}
        </span>
      </div>
      <InputTextSearch
        className="mb-3"
        onChange={(e: any) => setQuery(e?.target?.value)}
        value={query}
        onSubmit={() => {
          setTableQuery(query);
        }}
      />
      {/* @ts-ignore */}
      <AwesomeTableComponent
        dataSource={userChannels}
        columns={tableColumns}
        ref={tableRef}
      />
      {openProfileUpdateDrawer && (
        <Modal
          open={openProfileUpdateDrawer}
          onClose={() => setOpenProfileUpdateDrawer(false)}
          showFooter={false}
          title={t("common:channel")}
          width={800}
        >
          <UserChannelUpdate
            onClose={() => {
              setOpenProfileUpdateDrawer(false);
              refetch();
            }}
          />
        </Modal>
      )}
      {openRemoveChannelDrawer && (
        <Modal
          open={openRemoveChannelDrawer}
          onClose={() => setOpenRemoveChannelDrawer(false)}
          showFooter={false}
          title={t("common:removeChannel")}
          width={800}
        >
          <UserChannelRemove
            channels={userChannels}
            onClose={() => {
              setOpenRemoveChannelDrawer(false);
              refetch();
            }}
          />
        </Modal>
      )}
    </Card>
  );
};

export default UserChannel;

export const ORDER_TYPE: { [key: string]: string } = {
  OFFLINE: "offline",
  ONLINE: "online",
};

export const STATUS_FILTER: { [key: string]: string } = {
  INCLUDING_PENDING: "INCLUDING_PENDING",
  CONFIRMED: "CONFIRMED",
};

export const ORDER_TYPE_OPTIONS = Object.keys(ORDER_TYPE).map((item) => ({
  label: ORDER_TYPE[item],
  key: ORDER_TYPE[item],
}));

export const STATUS_FILTER_OPTIONS = Object.keys(STATUS_FILTER).map((item) => ({
  label: STATUS_FILTER[item],
  key: STATUS_FILTER[item],
}));

export const FOC_TYPE: { [key: string]: string } = {
  INCLUDE_FOC: "INCLUDE_FOC",
  EXCLUDE_FOC: "EXCLUDE_FOC",
};

export const FOC_TYPE_OPTIONS = Object.keys(FOC_TYPE).map((item) => ({
  label: FOC_TYPE[item],
  key: FOC_TYPE[item],
}));

export const ORDER_SOURCE: { [key: string]: string } = {
  ONLINE: "online",
  STANDALONE_STORE: "standalone_store",
  MODERN_TRADE: "modern_trade",
  MARKERPLACE: "marketplace",
};

export const ORDER_SOURCE_OPTIONS = Object.keys(ORDER_SOURCE).map((item) => ({
  label: ORDER_SOURCE[item],
  key: ORDER_SOURCE[item],
}));

export const PRODUCT_LAUNCH_AT: { [key: string]: string } = {
  LAST_3_MONTHS: "last_3_months",
  LAST_6_MONTHS: "last_6_months",
  LAST_9_MONTHS: "last_9_months",
  LAST_12_MONTHS: "last_12_months",
};

export const PRODUCT_LAUNCH_AT_OPTIONS = Object.keys(PRODUCT_LAUNCH_AT).map(
  (item) => ({
    label: PRODUCT_LAUNCH_AT[item],
    key: PRODUCT_LAUNCH_AT[item],
  })
);

import {
  AwesomeTableComponent,
  AwesomeTableComponentProps,
  Button,
} from "d-react-components";
import { find, uniq, uniqBy } from "lodash";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useUpdateEffect } from "react-use";

interface TableSelectAllProps {
  onSelectAll?: () => Promise<string[]>;
  itemKey: string;
  filterParams: any;
  onSelectRows?: (items: any[]) => void;
  initialSelectedRows: any[];
}

const TableSelectAll: React.ForwardRefExoticComponent<
  AwesomeTableComponentProps &
    React.RefAttributes<AwesomeTableComponentProps> &
    TableSelectAllProps
> = forwardRef((props, ref) => {
  const { onSelectAll, itemKey, filterParams, onSelectRows, className, initialSelectedRows, ...rest } = props;
  //selected items on table
  const [selectedRows, setSelectedRows] = useState<any>(initialSelectedRows?.map((item: any) => item[itemKey])??[]);

  //selected items from all search sessions
  const [allSelectedRows, setAllSelectedRows] = useState<any[]>(initialSelectedRows);

  ///all result found for current search 
  const [allResultRows, setAllResultRows] = useState<any[]>([]);
  const [isSelectedAllPages, setIsSelectedAllPages] = useState(false);

  const selectAllPages = async () => {
    if (isSelectedAllPages) {
      setSelectedRows(
        selectedRows.filter((rowId: string) => !find(allResultRows, {[itemKey]: rowId}))
      );
      setAllSelectedRows(
        allSelectedRows.filter(
          (row: any) => !find(allResultRows, { [itemKey]: row[itemKey] })
        )
      );
      setIsSelectedAllPages(false);
    } else {
      onSelectAll &&
        (await onSelectAll().then((resp) => {
          const allNewItem = resp?.map((item: any) => item[itemKey]);
          setSelectedRows(uniq([...selectedRows, ...allNewItem]));
          setAllResultRows(resp);
          setAllSelectedRows(uniqBy([...allSelectedRows, ...resp], itemKey));
          setIsSelectedAllPages(true);
        }));
    }
  };

  const onChangeSelectedRow = (value: any) => {
    setSelectedRows(value);
  };

  const onSelectOneRow = (record: any, selected: boolean) => {
    if (selected) {
      setAllSelectedRows(uniqBy([...allSelectedRows, record], itemKey));
    } else {
      setAllSelectedRows(
        allSelectedRows.filter((row: any) => row[itemKey] !== record[itemKey])
      );
    }
  };

  const onSelectAllCurrentPage = (
    selected: boolean,
    selectedRows: any[],
    changeRows: any[]
  ) => {
    if (selected) {
      setAllSelectedRows(uniqBy([...allSelectedRows, ...changeRows], itemKey));
    } else {
      setAllSelectedRows(
        allSelectedRows.filter(
          (row: any) => !find(changeRows, { [itemKey]: row[itemKey] })
        )
      );
    }
  };

  useUpdateEffect(() => {
    setIsSelectedAllPages(false);
  }, [filterParams]);

  useUpdateEffect(() => {
    onSelectRows && onSelectRows(allSelectedRows);
  }, [allSelectedRows])

  return (
    <div className={className}>
      <Button className="mb-3" color="muted" onClick={() => selectAllPages()}>
        {isSelectedAllPages ? "Deselect All Pages" : "Select All Pages"}
      </Button>
      <AwesomeTableComponent
        selectingRows={selectedRows}
        rowSelection={{
          onChange: onChangeSelectedRow,
          selectedRowKeys: selectedRows,
          preserveSelectedRowKeys: true,
          onSelectAll: onSelectAllCurrentPage,
          onSelect: onSelectOneRow,
        }}
        {...rest}
        // @ts-ignore
        ref={ref}
      />
    </div>
  );
});

export default TableSelectAll;

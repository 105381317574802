import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactInputVerificationCode from "react-input-verification-code";
import Countdown, { zeroPad } from "react-countdown";
import { Button, Drawer, Notifications } from "d-react-components";
import {
  useSendOtpVerifyPhoneNumberByAdminLazyQuery,
  useVerifyPhoneNumberByAdminMutation,
} from "../../network/hooks";
import styled from "styled-components";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLocalStorage } from "react-use";
import { ADMIN_AUTH_KEY } from "../../constants/common";
export interface OtpInputProps {
  token?: string;
  otp?: string;
}
export const VerifyOTPDrawer = ({ open, onClose, onSave }: any) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [adminInfo, setAdminInfo] = useLocalStorage<any>(ADMIN_AUTH_KEY, {});
  const { t } = useTranslation();
  const [otpInput, setOtpInput] = React.useState<OtpInputProps>({});
  const [otpSent, setOtpSent] = useState<string>();
  const [error, setError] = useState<string>("");
  const showError = useMemo(() => otpInput.otp?.length !== 6, [error]);
  const [requestOTP] = useSendOtpVerifyPhoneNumberByAdminLazyQuery();
  const [verifyPhoneNumber] = useVerifyPhoneNumberByAdminMutation();

  const resendOTP = async () => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha("otp");
      requestOTP({
        variables: {
          input: {
            recaptchaToken: token,
          },
        },
        fetchPolicy: "no-cache",
      }).then(
        (resp: any) => {
          if (resp?.errors) {
            Notifications.showError(resp?.errors?.[0]?.message);
          } else if (resp?.data?.sendOTPVerifyPhoneNumberByAdmin) {
            setOtpSent(resp?.data?.sendOTPVerifyPhoneNumberByAdmin?.token);
          }
        },
        (err) => {
          Notifications.showError(err);
        }
      );
    }
  };
  const onSubmitOtp = async () => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha("otp");
      verifyPhoneNumber({
        variables: {
          input: {
            token: otpSent,
            code: otpInput.otp,
            recaptchaToken: token,
          },
        },
        fetchPolicy: "no-cache",
      }).then(
        (resp) => {
          if (resp?.data?.verifyPhoneNumberByAdmin?.accessToken) {
            setAdminInfo({
              ...adminInfo,
              accessToken: resp?.data?.verifyPhoneNumberByAdmin?.accessToken,
              hasVerifyOtp: true,
            });
            Notifications.showSuccess(
              t("notification:verifyNumberSuccessfully")
            );
            onSave && onSave();
          } else {
            Notifications.showError("An unknown error occurred");
          }
        },
        (err) => {
          Notifications.showError(err);
        }
      );
    }
  };

  // useEffect(() => {
  //   resendOTP();
  // }, []);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      size="auto"
      width={"600px"}
      title={t("user:phoneNumberVerification")}
    >
      <div className="max-w-[600px] p-4 bg-white m-auto">
        {!otpSent && (
          <div className="flex flex-col items-center">
            <p className="text-center mb-6">
              {t("user:inOrderToVerify")}
              <span className="text-red-500">({adminInfo?.phoneNumber})</span>
            </p>
            <Button onClick={() => resendOTP()}>{t("common:sendOTP")}</Button>
          </div>
        )}
        {otpSent && (
          <>
            <p className="text-center mb-6">
              <span className="">{t("user:codeSent")} </span>
              <span className="text-red-500">({adminInfo?.phoneNumber})</span>
            </p>
            <StyledInput>
              <ReactInputVerificationCode
                length={6}
                onChange={(otp: any) => {
                  setOtpInput({ ...otpInput, otp });
                }}
                value={otpInput.otp}
              />
            </StyledInput>
            <p className="text-center text-xs mt-4">
              <span className="block mb-2">{t("user:notReceiveCode")}</span>
              <CountdownText resendOTP={resendOTP} />
              <div className="flex items-center justify-center">
                <Button
                  title={t("common:verify")}
                  className="w-full mt-8 mb-6 text-sm text-center"
                  onClick={() => onSubmitOtp()}
                >
                  {t("common:verify")}
                </Button>
              </div>
            </p>
          </>
        )}
        {showError && error && (
          <div className="text-red-500 my-3 text-center text-xs">{error}</div>
        )}
      </div>
    </Drawer>
  );
};

export const CountdownText = ({ resendOTP }: any) => {
  const { t } = useTranslation();
  const [countdownKey, setCountdownKey] = React.useState<number>(1);
  const time = React.useMemo(() => {
    return Date.now() + 60000;
  }, [countdownKey]);

  return (
    //@ts-ignore
    <Countdown
      date={time}
      precision={3}
      zeroPadTime={2}
      key={countdownKey}
      renderer={({ hours, minutes, seconds, completed }) =>
        completed ? (
          <a
            className="cursor-pointer block text-red-500"
            onClick={() => {
              resendOTP();
              setCountdownKey(countdownKey + 1);
            }}
          >
            {t("resendNow")}
          </a>
        ) : (
          <span className="block text-primary">
            {t("resendNow")} ({zeroPad(minutes)}:{zeroPad(seconds)})
          </span>
        )
      }
    />
  );
};

const StyledInput = styled.div`
  .ReactInputVerificationCode__container {
    width: 21rem;
    margin: auto;
  }
  .ReactInputVerificationCode__item {
    box-shadow: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    font-size: 1.2rem;
    width: 3rem;
    height: 4rem;
    line-height: 4rem;
    transition: all 0.5s;
    &.is-active {
      border-bottom: 1px solid #727272;
    }
  }
`;

import { IMenuItemProps } from "../interfaces/common";

export const menuFlatter = (
  data: Array<IMenuItemProps> = [],
  prev: Array<any> = []
) => {
  let result: Array<IMenuItemProps> = [...prev];
  if (data?.length > 0) {
    data.forEach((item) => {
      const isIn = !!result.find((i) => i?.id === item?.id);
      if (!isIn) {
        result.push(item);
      }
      const { subMenu = [] } = item;
      if (subMenu.length > 0) {
        subMenu.forEach((sub) => {
          const isSubIn = !!result.find((i) => i?.id === sub?.id);
          if (!isSubIn) {
            result.push({ ...sub, parentId: item.id });
          }
        });
        result = menuFlatter(subMenu, result);
      }
    });
  }
  return result;
};

import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  from,
  ApolloProvider,
  HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { find } from "lodash";
import { Path } from "../components/common/layout/Paths";
import {
  ADMIN_AUTH_KEY,
  INVALID_CREDENTIALS,
  UNAUTHENTICATED,
} from "../constants/common";
import { Notifications } from "d-react-components";

const httpLink = createUploadLink({
  uri: process.env["REACT_APP_API_HOST_URL"],
});

export const AuthMiddleware = new ApolloLink((operation, forward) => {
  const adminInfo = JSON.parse(localStorage.getItem(ADMIN_AUTH_KEY) || "");
  if (adminInfo.accessToken) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${adminInfo.accessToken}`,
      },
    }));
  }
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    // Handle Errors
    console.log(graphQLErrors);
  }

  forward(operation);
});

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const { errors } = response;

    const isInvalidCredentials = find(errors, { code: INVALID_CREDENTIALS });
    const isUnauthenticated = find(errors, { code: UNAUTHENTICATED });
    if (
      window.location.pathname !== "/login" &&
      (isInvalidCredentials || isUnauthenticated)
    ) {
      localStorage.removeItem(ADMIN_AUTH_KEY);
      window.location.replace(Path.AUTH_LOGIN);
    }

    return response;
  });
});

const cache = new InMemoryCache();
export const client = new ApolloClient({
  uri: process.env["REACT_APP_API_HOST_URL"],
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
  link: from([AuthMiddleware, afterwareLink, errorLink, httpLink]),
});

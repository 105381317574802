import { Icon } from "d-react-components";
import React, { InputHTMLAttributes, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ClassNames from "classnames";
import { Channel } from "../../../../interfaces/common";
import { isNumber } from "lodash";
import { moneyFormatter } from "../../../../utils/money";

interface ISmartViewRowProps {
  [key: string]: any;
  channel?: Channel;
}

interface SmartViewHeaderProps extends InputHTMLAttributes<any> {
  [key: string]: any;
  timeRange: CompareTimeLineItem[];
  disabledLeft?: boolean;
  disabledRight?: boolean;
  onClickLeft?: () => void;
  onClickRight?: () => void;
  headerTitle?: string;
  groupLength: number;
}

export interface CompareTimelineViewProps {
  [key: string]: any;
  data: any[];
  channels?: any[];
  headerTitle?: string;
  groupLength?: number;
}

interface CompareTimeLineItem {
  label: string;
  subLabel: string;
  values: any;
}

const CompareTimelineView: React.FC<CompareTimelineViewProps> = ({
  data,
  channels,
  headerTitle,
  groupLength = 3,
}) => {
  const columnCount = 12;
  //current range to display
  const [currentRange, setCurrentRange] = useState(0);

  useEffect(() => {
    setCurrentRange(0);
  }, [data]);

  //get the data of the current range
  const currentData = useMemo(() => {
    const slicedData = data.slice(currentRange, columnCount + currentRange);
    return slicedData;
  }, [currentRange, data]);

  const isDisabledLeft = useMemo(() => {
    return currentRange - groupLength < 0;
  }, [data, currentRange]);

  const isDisabledRight = useMemo(() => {
    return currentRange + columnCount >= data.length;
  }, [data, currentRange]);

  const handleDecreaseTimeline = () => {
    setCurrentRange(currentRange - groupLength);
  };

  const handleIncreaseTimeline = () => {
    setCurrentRange(currentRange + groupLength);
  };

  return (
    <div className="timeline-smart-view__container bg-white border-start w-100 border-top overflow-y">
      <SmartViewHeader
        timeRange={currentData}
        currentData={currentData}
        disabledLeft={isDisabledLeft}
        disabledRight={isDisabledRight}
        onClickLeft={handleDecreaseTimeline}
        onClickRight={handleIncreaseTimeline}
        headerTitle={headerTitle}
        groupLength={groupLength}
      />
      {channels?.map((item, index) => {
        return (
          <SmartViewRow
            key={`${item?.name}`}
            dataSource={currentData}
            channel={item}
            groupLength={groupLength}
          />
        );
      })}
    </div>
  );
};

const SmartViewHeader: React.FC<SmartViewHeaderProps> = ({
  timeRange,
  disabledLeft,
  disabledRight,
  onClickLeft,
  onClickRight,
  filterBy,
  currentData,
  headerTitle,
  groupLength,
}) => {
  const { t } = useTranslation();
  const buttonClass = ClassNames(
    "bg-primary h-100 d-flex align-items-center justify-content-center hover-pointer position-absolute"
  );
  const disabledLeftClass = ClassNames({ "bg-gray": disabledLeft });
  const disabledRightClass = ClassNames({ "bg-gray": disabledRight });
  const iconClass = ClassNames("text-white d-block event-none");

  const dataSource: any[] = useMemo(() => {
    const source: any[] = [];
    timeRange.forEach((item) => {
      source.push({
        label: item.label,
        subLabel: item.subLabel,
      });
    });
    return source;
  }, [timeRange]);

  const renderButtonLeft = (
    <div
      className={`${buttonClass} ${disabledLeftClass}`}
      onClick={() => {
        if (disabledLeft) {
          return null;
        }
        return onClickLeft && onClickLeft();
      }}
      style={{ zIndex: 2 }}
    >
      <Icon name="arrow_left" size="medium" className={iconClass} />
    </div>
  );

  const renderButtonRight = (
    <div
      className={`end-0 ${buttonClass} ${disabledRightClass}`}
      onClick={() => {
        if (disabledRight) {
          return null;
        }
        return onClickRight && onClickRight();
      }}
    >
      <Icon name="arrow_right" size="medium" className={iconClass} />
    </div>
  );

  return (
    <div className="smart-view-header__container d-flex w-100">
      <div className="col-3 d-flex align-items-center p-3 border-end border-bottom smart-view-header__search">
        {headerTitle || t("channel")}
      </div>
      <div className="col-9 p-0 d-flex position-relative">
        {renderButtonLeft}
        {dataSource?.length > 0 &&
          dataSource.map((date, index) => {
            return (
              <DateItem
                date={date}
                key={`${date?.toString()}_${index}`}
                displayType={filterBy}
                label={currentData?.[index]?.label}
                className={isHighlight(index, groupLength) ? "bg-gray-100" : ""}
              />
            );
          })}
        {renderButtonRight}
      </div>
    </div>
  );
};

const isHighlight = (index: number, groupLength: number) => {
  const division = Math.ceil((index + 1) / groupLength);
  return !(division % 2);
};

const SmartViewRow: React.FC<ISmartViewRowProps> = ({
  dataSource,
  channel,
  groupLength,
}) => {
  return (
    <div className="d-flex w-100">
      <div
        className="col-3 border-end border-bottom d-flex align-items-center text-nowrap"
        style={{ minHeight: "80px" }}
      >
        {channel?.name}
      </div>
      <div className="col-9 p-0 d-flex w-100">
        {dataSource &&
          dataSource?.length > 0 &&
          dataSource.map((item: any, index: number) => {
            return (
              <SmartViewCell
                key={`_${index}`}
                data={item?.values?.[channel?.name as string]}
                className={isHighlight(index, groupLength) ? "bg-gray-100" : ""}
              />
            );
          })}
      </div>
    </div>
  );
};

export const SmartViewCell: React.FC<any> = ({ data, className }) => {
  const { value, isIncrease, percentDiff } = data ?? {};
  return (
    <div
      className={`${className} w-100 col border-bottom border-end d-flex flex-column align-items-center justify-content-center text-xs`}
      style={{ minWidth: "50px" }}
    >
      <span>{isNumber(value) ? moneyFormatter.format(value) : value}</span>
      {percentDiff && (
        <>
          {isIncrease ? (
            <span className="text-green-500"> (+{percentDiff}%)</span>
          ) : (
            <span className="text-red-500"> (-{percentDiff}%)</span>
          )}
        </>
      )}
    </div>
  );
};

const DateItem: React.FC<any> = ({ date, className, displayType, label }) => {
  return (
    <div
      className={`${className} w-100 col border-bottom border-end d-flex flex-column align-items-center justify-content-center`}
      style={{ minWidth: "50px" }}
    >
      <span className="text-sm">{date.label}</span>
      <span className="text-xs text-gray-400">{date.subLabel}</span>
    </div>
  );
};

export default CompareTimelineView;

import classNames from "classnames";
import QRCode from "react-qr-code";

const QrCodeView = ({
  secretCode,
  name,
  className,
  size = 100,
}: {
  secretCode: string;
  name: string;
  className?: string;
  size?: number;
}) => {
  if (!secretCode || !name) return <div />;
  const content = `otpauth://totp/ReportInfinityX.com-${name}?secret=${secretCode}`;
  return (
    <QRCode
      value={content}
      size={size}
      className={classNames("mt-3", className)}
    />
  );
};

export default QrCodeView;

import React from "react";
import { useTranslation } from "react-i18next";
import { Path } from "../Paths";
import Indicator from "./Indicator";
import { useLocalStorage } from "react-use";
import { IAdmin } from "../../../../interfaces/common";
import { ADMIN_AUTH_KEY } from "../../../../constants/common";
import { useNavigate } from "react-router";

function ButtonSetting() {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [admin, setAdmin, remove] = useLocalStorage<IAdmin>(ADMIN_AUTH_KEY);

  const onLogout = () => {
    remove();
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  const buttonSetting = <i className="material-icons">settings</i>;

  const dropDownSetting = (
    <div className="app-layout__dropdown-setting">
      <button
        className="btn text-sm whitespace-nowrap"
        type="button"
        onClick={onLogout}
      >
        {t(`logout`)}
      </button>
    </div>
  );

  return <Indicator icon={buttonSetting} dropdown={dropDownSetting} />;
}

export default ButtonSetting;

import { IAdmin } from "../../../../interfaces/common";
import {
  Card,
  Icon,
  IRowsKey,
  ViewRowInterchange,
} from "d-react-components";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { userUserInfo } from "../UserDetail";
import { AdminStatus } from "../../../../constants/admin";
import { UserSensitiveDataDrawer } from "./UserSensitiveDataDrawer";

const UserSensitiveData = () => {
  const [{ user, loading, refetch }] = userUserInfo();
  const [openSensitiveDrawer, setOpenSensitiveDrawer] = useState(false);
  const { t } = useTranslation();

  const SENSITIVE_INFO_KEYS: IRowsKey<IAdmin>[] = useMemo(
    () => [
      {
        id: "phoneStatus",
        label: t("user:smsOTPVerification"),
        renderContent: ({ data }) =>
          data === AdminStatus.ENABLED
            ? t("dynamic:enabled")
            : t("dynamic:disabled"),
      },
      {
        id: "phoneNumber",
        label: t("user:phoneNumber"),
        renderContent: ({ data }) => (
          <>
            {data}{" "}
            {data && <Icon name="check_circle" className="text-green-500" />}
          </>
        ),
      },
    ],
    [t]
  );

  return (
    <div>
      <Card
        title={t("user:sensitiveDataAccess")}
        className="mb-3"
        customRight={
          <span
            className="text-red-500 cursor-pointer"
            onClick={() => setOpenSensitiveDrawer(true)}
          >
            {t("common:edit")}
          </span>
        }
      >
        <ViewRowInterchange keyList={SENSITIVE_INFO_KEYS} dataSource={user} />
        <GoogleReCaptchaProvider
          reCaptchaKey={
            process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string
          }
        >
          {openSensitiveDrawer && (
            <UserSensitiveDataDrawer
              open={openSensitiveDrawer}
              onClose={() => setOpenSensitiveDrawer(false)}
              onSave={() => {
                setOpenSensitiveDrawer(false);
                refetch();
              }}
            />
          )}
        </GoogleReCaptchaProvider>
      </Card>
    </div>
  );
};

export default UserSensitiveData;

import {
  Summary,
} from "d-react-components";
import { IColumnsProps } from "d-react-components";
import { isEmpty } from "lodash";
import React from "react";

const { Row, Cell } = Summary;

const TableSummary = ({
  columns,
  data,
}: {
  columns: IColumnsProps;
  data: { [key: string]: any };
}) => {
  if (isEmpty(data)) {
    return null;
  }
  return (
    <Summary fixed="top">
      <Row>
        {columns?.map((column: any, index) => (
          <Cell key={index} index={index}>
            {column.render
              ? column.render(data?.[column.dataIndex], data)
              : data?.[column.dataIndex] ?? ""}
          </Cell>
        ))}
      </Row>
    </Summary>
  );
};

export default TableSummary;

import {
  AwesomeTableComponent,
  Button,
  IColumnsProps,
  InputTextSearch,
  Notifications,
} from "d-react-components";
import { orderBy } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useRemoveUserChannelMutation } from "../../../../network/hooks";

const UserChannelRemove = ({
  onClose,
  channels,
}: {
  onClose: () => void;
  channels: any[];
}) => {
  const { userId } = useParams();
  const [tableQuery, setTableQuery] = useState("");
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const tableRef = useRef<any>(null);
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const [removeChannels] = useRemoveUserChannelMutation();

  const tableColumns: IColumnsProps = useMemo(
    () => [
      {
        title: t("common:name"),
        dataIndex: "name",
        align: "left",
        width: 60,
        render: (data, item) => data,
      },
      {
        title: t("common:domain"),
        dataIndex: "domain",
        align: "left",
        width: 75,
        render: (data: any, item: any, index) => data,
      },
    ],
    [t]
  );

  const handleSubmit = async () => {
    try {
      removeChannels({
        variables: {
          input: {
            id: userId as string,
            channels: selectedRows,
          },
        },
      }).then(
        (resp) => {
          Notifications.showSuccess(
            t("notification:removeChannelSuccessfully")
          );
          onClose && onClose();
        },
        (err) => {
          Notifications.showError(err);
        }
      );
    } catch (err) {
      Notifications.showError(err);
    }
  };

  const userChannels = useMemo(
    () =>
      orderBy(
        channels?.filter((channel) =>
          tableQuery
            ? channel.name.toLowerCase().includes(tableQuery.toLowerCase())
            : true
        ) ?? [],
        "name"
      ),
    [channels, tableQuery]
  );

  return (
    <div>
      <InputTextSearch
        className="mb-3"
        onChange={(e: any) => setQuery(e?.target?.value)}
        value={query}
        onSubmit={() => {
          setTableQuery(query);
        }}
      />
      {/* @ts-ignore */}
      <AwesomeTableComponent
        dataSource={userChannels}
        columns={tableColumns}
        ref={tableRef}
        showSelectColumn={false}
        selectingRows={selectedRows}
        rowSelection={{
          onChange: (value) => setSelectedRows(value),
          selectedRowKeys: selectedRows,
          preserveSelectedRowKeys: true,
        }}
      />
      <div>
        <div className="flex align-items-end justify-content-end">
          <Button
            title={t("common:submit")}
            className="w-full mb-2 text-sm"
            onClick={() => handleSubmit()}
            color="primary"
          >
            {t("common:save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserChannelRemove;

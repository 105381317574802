import { isEmpty } from "lodash";
import { useContext, useEffect, useRef } from "react";
import { Navigate, Route, RouteProps } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { ADMIN_AUTH_KEY } from "../../../constants/common";
import AppContext from "../AppContext/AppContext";
import MenuBar from "./shared/MenuBar";
import NavBar from "./shared/NavBar";
import AdminContext from "../AppContext/AdminContext";
import { useGetMyInfoLazyQuery } from "../../../network/hooks";
// import Navbar from "./Layout/Navbar";

interface ProtectedRouteProps {
  permission?: string;
}

export function ProtectedRoute({
  children,
  permission,
}: ProtectedRouteProps & RouteProps) {
  const ref = useRef(null);
  const [isAuthenticated, setAdmin] = useLocalStorage(ADMIN_AUTH_KEY);
  const { setMainContentRef } = useContext(AppContext);
  const [getMyInfo] = useGetMyInfoLazyQuery();

  useEffect(() => {
    setMainContentRef(ref);
  }, [ref.current]);

  useEffect(() => {
    if (isAuthenticated) { 
      getMyInfo().then((resp) => {
        setAdmin({
          ...isAuthenticated,
          ...resp?.data?.profileForAdmin
        })
      });
    }
  }, []);

  const component = !isEmpty(isAuthenticated) ? (
    <AdminContext.Provider value={{}}>
      <MenuBar />
      <div className="active" id="app-layout__content" ref={ref}>
        <NavBar />
        <div className="p-3">{children}</div>
      </div>
    </AdminContext.Provider>
  ) : (
    <Navigate replace to="/login" />
  );
  return component;
}

export default ProtectedRoute;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`../public/locales/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    })
  )
  .init({
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    defaultNS: "common",
    ns: [
      "common",
      "report",
      "customer",
      "dynamic",
      "notification",
      "menu",
      // "channel",
      "user",
    ],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false, //   <---- this will do the magic
    },
  });

export default i18n;

import {
  Select as DSelect,
  SelectProps,
} from "d-react-components";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export const TranslatedSelect: React.FC<Partial<SelectProps>> = ({
  dataSource,
  ...props
}) => {
  const { t } = useTranslation();
  const options = useMemo(() => {
    return dataSource?.map((item) => ({
      ...item,
      label: t(item.label),
    }));
  }, [t]);
  
  return <DSelect {...props} dataSource={options} />;
};

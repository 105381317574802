import {
  AwesomeTableComponent,
  IColumnsProps,
  Progress,
} from "d-react-components";
import React, { useContext, useMemo, useRef, useState } from "react";
import { REPORT_TYPE } from "../../constants/report";
import ReportHeader, {
  FilterDataProps,
} from "../common/report/header/ReportHeader";
import ReportCtx from "../common/AppContext/ReportContext";
import ReportContext from "./ReportContext";
import { useTranslation } from "react-i18next";
import { useExportProvincePerformanceLazyQuery } from "../../network/hooks";
import { SORT_DIRECTION } from "../../constants/common";
import { isEmpty, isObject, isString, orderBy, sumBy } from "lodash";
import { exportToXLS } from "../../utils/file";
import { moneyFormatter } from "../../utils/money";
import Item from "antd/lib/list/Item";
import { Channel } from "../../interfaces/common";
import TableSummary from "../common/Table/TableSummary";

const LocationPerformanceContent = () => {
  const [sortBy, setSortBy] = useState<any>();
  const { filterData, channelList } = useContext(ReportCtx);
  const tableRef = useRef<any>(null);
  const { t } = useTranslation();
  const [exportReport, { data }] = useExportProvincePerformanceLazyQuery();

  const getPayload = () => {
    return {
      // orderSource: filterData?.orderSource,
      channels: filterData?.channels?.length
        ? filterData.channels
        : channelList.map((channel: Channel) => channel.refId),
      start: filterData?.currentRange?.[0].startOf("d").toDate(),
      end: filterData?.currentRange?.[1].endOf("d").toDate(),
      provinces: filterData.provinces?.length
        ? filterData.provinces?.map((item: any) => item.refId)
        : [],
    };
  };

  const onChangeFilter = (filterData: FilterDataProps) => {
    // if (!filterData?.orderSource?.length) {
    //   return Promise.resolve([]);
    // }
    const payload = getPayload();
    Progress.show(
      {
        method: exportReport,
        params: [
          {
            variables: {
              args: {
                ...payload,
                ...(sortBy?.order
                  ? {
                      sort: {
                        sortBy: sortBy.field,
                        sortDirection:
                          sortBy.order === SORT_DIRECTION.ASC ? 1 : -1,
                      },
                    }
                  : {}),
              },
            },
          },
        ],
      },
      (resp: any) => {}
    );
  };

  const handleExportData = () => {
    const sortBy = tableRef.current?.state?.sorter;
    const mappedData = data?.exportProvincePerformance
      ? orderBy(
          data.exportProvincePerformance,
          sortBy?.field ?? "province.name",
          sortBy?.order === SORT_DIRECTION.DESC ? "desc" : "asc"
        ).map((item: any, index: number) => ({
          No: `${index + 1}`,
          Name: item?.province?.name,
          "Delivery Quantity": item?.countCompleted,
          "Total Sales Amount": item?.salePrice,
          "Total Sales Amount (Net)": item?.salePriceNet,
        }))
      : [];
    mappedData.push({
      No: "",
      Name: "",
      "Delivery Quantity": sumBy(mappedData, "Delivery Quantity"),
      "Total Sales Amount": sumBy(mappedData, "Total Sales Amount"),
      "Total Sales Amount (Net)": sumBy(mappedData, "Total Sales Amount (Net)"),
    });
    exportToXLS(mappedData, "export");
  };

  const sumData = useMemo(() => {
    const tableData = data?.exportProvincePerformance;
    return isEmpty(tableData)
      ? {}
      : {
          province: t("sum"),
          countCompleted: sumBy(tableData, "countCompleted"),
          salePrice: sumBy(tableData, "salePrice"),
          salePriceNet: sumBy(tableData, "salePriceNet"),
        };
  }, [data?.exportProvincePerformance]);

  const tableColumns: IColumnsProps = useMemo(
    () => [
      {
        title: t("name"),
        dataIndex: "province",
        align: "left",
        width: 200,
        render: (province) => {
          return isString(province) ? province : province?.name ?? "N/A";
        },
        sorter: (a, b) => {
          return a?.countCancelled - b?.countCancelled;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
      },
      {
        title: t("deliveryQuantity"),
        dataIndex: "countCompleted",
        align: "left",
        width: 150,
        sorter: (a, b) => {
          return a?.countCompleted - b?.countCompleted;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value) => {
          return moneyFormatter.format(value);
        },
      },
      {
        title: t("deliveryQuantity%"),
        dataIndex: "countCompleted",
        align: "left",
        width: 150,
        render: (value) => {
          return `${
            sumData?.countCompleted
              ? Number((value / sumData?.countCompleted) * 100).toFixed(2)
              : 0
          }%`;
        },
      },
      {
        title: t("totalSaleAmount"),
        dataIndex: "salePrice",
        align: "left",
        width: 150,
        sorter: (a, b) => {
          return a?.salePrice - b?.salePrice;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        defaultSortOrder: SORT_DIRECTION.DESC,
        render: (value) => {
          return `${moneyFormatter.format(value)}`;
        },
      },
      {
        title: t("totalSaleAmount%"),
        dataIndex: "salePrice",
        align: "left",
        width: 150,
        render: (value) => {
          return `${
            sumData?.salePrice
              ? Number((value / sumData?.salePrice) * 100).toFixed(2)
              : 0
          }%`;
        },
      },
      {
        title: t("totalSaleAmountNet"),
        dataIndex: "salePriceNet",
        align: "left",
        width: 160,
        sorter: (a, b) => {
          return a?.count - b?.count;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value) => {
          return `${moneyFormatter.format(value)}`;
        },
      },
      {
        title: t("totalSaleAmountNet%"),
        dataIndex: "salePriceNet",
        align: "left",
        width: 160,
        render: (value) => {
          return `${
            sumData?.salePriceNet
              ? Number((value / sumData?.salePriceNet) * 100).toFixed(2)
              : 0
          }%`;
        },
      },
      // {
      //   title: t("avgOrderValue"),
      //   dataIndex: "salePrice",
      //   align: "left",
      //   width: 200,
      //   sorter: (a, b) => {
      //     return (
      //       a?.salePrice / (a?.quantity || 1) -
      //       b?.salePrice / (b?.quantity || 1)
      //     );
      //   },
      //   sortDirections: [
      //     SORT_DIRECTION.ASC,
      //     SORT_DIRECTION.DESC,
      //     SORT_DIRECTION.ASC,
      //   ],
      //   render: (value, item) => {
      //     return moneyFormatter.format(value / (item?.quantity || 1));
      //   },
      // },
      // {
      //   title: t("avgOrderValueNet"),
      //   dataIndex: "salePriceNet",
      //   align: "left",
      //   width: 200,
      //   sorter: (a, b) => {
      //     return (
      //       a?.salePriceNet / (a?.quantity || 1) -
      //       b?.salePriceNet / (b?.quantity || 1)
      //     );
      //   },
      //   sortDirections: [
      //     SORT_DIRECTION.ASC,
      //     SORT_DIRECTION.DESC,
      //     SORT_DIRECTION.ASC,
      //   ],
      //   render: (value, item) => {
      //     return moneyFormatter.format(value / (item?.quantity || 1));
      //   },
      // },
    ],
    [t, sumData]
  );

  return (
    <div>
      <ReportHeader
        onChangeFilter={onChangeFilter}
        handleExportData={handleExportData}
        reportType={REPORT_TYPE.PROVINCE_PERFORMANCE}
      />
      <AwesomeTableComponent
        columns={tableColumns}
        ref={tableRef}
        dataSource={data?.exportProvincePerformance as any}
        summary={() => <TableSummary data={sumData} columns={tableColumns} />}
      />
    </div>
  );
};

const LocationPerformance = () => {
  return (
    <ReportContext>
      <LocationPerformanceContent />
    </ReportContext>
  );
};
export default LocationPerformance;

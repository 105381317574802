import { Button, InputText, Notifications } from "d-react-components";
import { useFormik } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useUpdateUserByAdminMutation } from "../../../../network/hooks";
import { userUserInfo } from "../UserDetail";

interface ForgotPasswordValues {
  password?: string;
  passwordConfirm?: string;
}

export const CustomerChangePassword = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const [{ user, loading }] = userUserInfo();
  const [updateUser] = useUpdateUserByAdminMutation();

  const FormSchema = useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string().min(6).required(t(`common:fieldRequired`)),
        passwordConfirm: Yup.string()
          .oneOf([Yup.ref("password"), null], t(`common:passwordMustMatch`))
          .required(t(`common:fieldRequired`)),
      }),
    [t]
  );

  const resetPasswordForm = useFormik<ForgotPasswordValues>({
    initialValues: {},
    validationSchema: FormSchema,
    onSubmit: async () => {
      try {
        updateUser({
          variables: {
            input: {
              password: values.password,
              id: user?.id as string,
            },
          },
        }).then(
          (resp) => {
            Notifications.showSuccess(
              t("notification:resetPasswordSuccessful")
            );
            onClose && onClose();
          },
          (err) => {
            Notifications.showError(err);
          }
        );
      } catch (err) {
        Notifications.showError(err);
      }
    },
    enableReinitialize: false,
    validateOnChange: false,
  });
  const { values, errors, handleSubmit, setFieldValue } = resetPasswordForm;

  return (
    <div>
      <div className="mb-3">
        <InputText
          label={t("customer:newPassword")}
          placeholder={t("common:pleaseInput")}
          value={values.password}
          error={errors.password}
          onChange={(e) =>
            setFieldValue && setFieldValue("password", e.target.value)
          }
          type="password"
        />
      </div>
      <div className="mb-3">
        <InputText
          label={t("customer:passwordConfirm")}
          placeholder={t("common:pleaseInput")}
          value={values.passwordConfirm}
          error={errors.passwordConfirm}
          onChange={(e) =>
            setFieldValue && setFieldValue("passwordConfirm", e.target.value)
          }
          type="password"
        />
      </div>
      <div className="flex align-items-end justify-content-end">
        <Button
          title={t("common:send")}
          className="w-full mb-2 text-sm"
          onClick={() => handleSubmit()}
          color="primary"
        >
          {t("common:send")}
        </Button>
      </div>
    </div>
  );
};

export default CustomerChangePassword;

export const Path = {
  ROOT: "/",
  AUTH: "/auth",
  AUTH_LOGIN: "/login",
  AUTH_FORGOT_PASS: "/auth/forgot-password",
  REPORT_TOTAL_SALE: "/report/total-sales",
  REPORT_CHANNEL_PERFORMANCE: "/report/channel-performance",
  CART_RULE_PERFORMANCE: "/report/cart-rule-performance",
  REPORT_STORE_PERFORMANCE: "/report/store-performance",
  REPORT_PRODUCT_PERFORMANCE: "/report/product-performance",
  REPORT_STAFF_PERFORMANCE: "/report/staff-performance",
  REPORT_PROVINCE_PERFORMANCE: "/report/province-performance",
  USERS: "/users",
  NEW_USER: "/user/new",
  UPDATE_USER: "/user/update/:userId",
  DETAIL_USER: "/user/detail/:userId",
  SETTINGS_ROLE: "/settings/role",
  ROLE_CREATE: "/settings/role/create",
  ROLE_DETAIL: "/settings/role/detail/:roleId",
};

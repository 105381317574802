import dayjs, { Dayjs } from "dayjs";
import moment, { Moment, MomentInput } from "moment";
/// <reference path="./node_modules/dayjs/plugin/isSameOrBefore.d.ts" />
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import utc from "dayjs/plugin/utc";
dayjs.extend(isSameOrBefore);
dayjs.extend(quarterOfYear);
dayjs.extend(utc);

export const groupDateToWeeks = (date1: Moment, date2: Moment) => {
  const weeksArray = [];
  let startDate = moment(date1).startOf("d");
  const endDate = moment(date2).startOf("d");
  while (startDate < endDate) {
    const endOfWeek = moment(startDate).add(7, "d");
    weeksArray.push([startDate, endOfWeek < endDate ? endOfWeek : endDate]);
    startDate = moment(endOfWeek);
  }
  return weeksArray;
};

export const groupDateToMonths = (date1: Moment, date2: Moment) => {
  const monthsArray = [];
  let startDate = moment(date1).startOf("d");
  const endDate = moment(date2).startOf("d");
  while (startDate.isSameOrBefore(endDate)) {
    monthsArray.push(startDate.startOf("month"));
    startDate = moment(startDate).add(1, "month");
  }
  return monthsArray;
};

export const convertToLocalTime = (serverTime: string) => {
  return moment(serverTime).local().format('YYYY-MM-DDTHH:mm:ss.SSS');
};

export const convertToLocalTimeDayjs = (serverTime: string) => {
  return dayjs(serverTime).local().format("YYYY-MM-DDTHH:mm:ss.SSS");
};

export const groupDateToMonthsAlt = (date1: Dayjs | Date, date2: Dayjs | Date) => {
  const monthsArray = [];
  let startDate = dayjs(date1).startOf("d");
  const endDate = dayjs(date2).startOf("d");
  while (startDate.isSameOrBefore(endDate)) {
    monthsArray.push(startDate.startOf("month"));
    startDate = dayjs(startDate).add(1, "month");
  }
  return monthsArray;
};

export const groupDateToWeeksAlt = (date1: Dayjs | Date, date2: Dayjs | Date) => {
  const weeksArray = [];
  let startDate = dayjs(date1).startOf("d");
  const endDate = dayjs(date2).startOf("d");
  while (startDate < endDate) {
    const endOfWeek = dayjs(startDate).add(7, "d");
    weeksArray.push([startDate, endOfWeek < endDate ? endOfWeek : endDate]);
    startDate = dayjs(endOfWeek);
  }
  return weeksArray;
};

export const getListOfQuarter = (date: Dayjs) => {
  const quartersArray = [
    dayjs(date).subtract(3, "quarter"),
    dayjs(date).subtract(2, "quarter"),
    dayjs(date).subtract(1, "quarter"),
    dayjs(date),
  ];
  return quartersArray;
};

export function convertRangeMonthToArray(date1: MomentInput, date2: MomentInput) {
  let start = moment(date1);
  const end = moment(date2);
  const dates = [];
  while (start <= end) {
    dates.push(start.toString());
    start = start.add(1, "month");
  }
  return dates;
}

export function convertRangeQuarterToArray(
  date1: MomentInput,
  date2: MomentInput
) {
  let start = moment(date1);
  const end = moment(date2);
  const dates = [];
  while (start <= end) {
    dates.push(start.toString());
    start = start.add(1, "quarter");
  }
  return dates;
}

export function convertRangeYearToArray(
  date1: MomentInput,
  date2: MomentInput,
) {
  let start = moment(date1);
  const end = moment(date2);
  const dates = [];
  while (start.isSameOrBefore(end, "year")) {
    dates.push(start.toString());
    start = start.add(1, "year");
  }
  return dates;
}
import React, { useMemo } from "react";
import {
  Card,
  IRowsKey,
  TimeUtils,
  ViewRowInterchange,
} from "d-react-components";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { IAdmin } from "../../../../interfaces/common";
import Avatar from "../../../common/Avatar/Avatar";

interface UserProps {
  user: IAdmin;
  loading: boolean;
}

export const UserSummaryView = ({ user, loading }: UserProps) => {
  // const [{ customer, loading }] = userUserInfo();
  const { t } = useTranslation();

  const USER_GENERAL_KEY: IRowsKey<IAdmin>[] = useMemo(
    () => [
      {
        id: "userId",
        label: t("customer:id"),
        renderContent: ({ data, item }) => data,
      },
      {
        id: "companyId",
        label: t("user:companyId"),
        renderContent: ({ data }) => data || "N/A",
      },
      {
        id: "nickName",
        label: t("customer:nickName"),
        renderContent: ({ data }) => data ?? "N/A",
      },
      {
        id: "accountAccess",
        label: t("user:accountAccess"),
        renderContent: ({ data }) =>
          data ? t("dynamic:enabled") : t("dynamic:disabled"),
      },
      {
        id: "hasTwoFaAuthentication",
        label: t("user:authentication"),
        renderContent: ({ data }) => (data ? t("common:yes") : t("common:no")),
      },
      {
        id: "createdAt",
        label: t("common:createdAt"),
        renderContent: ({ data }) =>
          data ? TimeUtils.convertMiliToDate(data) : "N/A",
      },
    ],
    [t]
  );

  return (
    <div>
      {user && (
        <Card className="mt-3">
          <div className="d-flex align-items-center flex-column w-100 py-3">
            <Avatar
              avatar={user?.avatar}
              name={{
                firstName: user.fullName,
                lastName: user.fullName,
              }}
            />
            <div className="text-large-bold mt-3">{user.fullName}</div>
          </div>
          <div className="w-100 pt-3 ">
            <ViewRowInterchange
              dataSource={user}
              keyList={USER_GENERAL_KEY as any}
              variant="dashed"
              classNameLabel="text-primary font-weight-bold"
              classNameContent="text-end"
            />
          </div>
        </Card>
      )}
      {loading && <Skeleton count={5} />}
    </div>
  );
};

export default UserSummaryView;

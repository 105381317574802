import moment, { Moment } from "moment";
import React, {
  ElementRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  DAYS_CHANGE,
  DAYS_RANGE,
  FILTER_TYPE,
} from "../../../../constants/report";
import SmartViewHeader from "./SmartViewHeader";
import SmartViewRow from "./SmartViewRow";
import { INCREMENT_UNIT } from "../../../../constants/common";

export interface TimeLineSmartViewProps {
  [key: string]: any;
  data: any[];
  filterBy: FILTER_TYPE;
  channels?: any[];
  headerTitle?: string;
}

const TimeLineSmartView: React.FC<TimeLineSmartViewProps> = ({
  data,
  channels,
  filterBy,
  headerTitle,
}) => {
  //current range to display
  const [currentRange, setCurrentRange] = useState<[Moment, Moment]>([
    moment(),
    moment(),
  ]);
  const unit: moment.unitOfTime.DurationConstructor = INCREMENT_UNIT[
    filterBy
  ] as moment.unitOfTime.DurationConstructor;

  useEffect(() => {
    if (!data.length) {
      return;
    }
    const initialTimeRange: any[] = data.slice(DAYS_RANGE*-1);
    if (filterBy === FILTER_TYPE.BY_WEEK) {
      setCurrentRange([
        initialTimeRange?.[0]?.date?.[0],
        initialTimeRange?.[initialTimeRange.length - 1]?.date?.[1],
      ]);
    } else {
      setCurrentRange([
        initialTimeRange?.[0]?.date,
        initialTimeRange?.[initialTimeRange.length - 1]?.date,
      ]);
    }
  }, [data]);

  //get the data of the current range
  const currentData = useMemo(() => {
    if (filterBy === FILTER_TYPE.BY_WEEK) {
      const startIndex = data.findIndex((item) =>
        moment(item.date?.[0]).isSame(currentRange?.[0], "d")
      );
      const endIndex = data.findIndex((item) =>
        moment(item.date?.[1]).isSame(currentRange?.[1], "d")
      );
      return data.slice(startIndex, endIndex + 1);
    }
    if (filterBy === FILTER_TYPE.BY_MONTH) {
      const startIndex = data.findIndex((item) =>
        moment(item.date).isSame(currentRange?.[0], "m")
      );
      const endIndex = data.findIndex((item) =>
        moment(item.date).isSame(currentRange?.[1], "m")
      );
      return data.slice(startIndex, endIndex + 1);
    } else {
      const startIndex = data.findIndex(
        (item) => item?.date?.valueOf() === currentRange[0]?.valueOf()
      );
      const endIndex = data.findIndex(
        (item) => item?.date?.valueOf() === currentRange[1]?.valueOf()
      );
      return data.slice(startIndex, endIndex + 1);
    }
  }, [currentRange, data, filterBy]);

  const isDisabledLeft = useMemo(() => {
    if (filterBy === FILTER_TYPE.BY_WEEK) {
      return data[0]?.date?.[0]?.valueOf() === currentRange[0]?.valueOf();
    } else {
      return data[0]?.date?.valueOf() === currentRange[0]?.valueOf();
    }
  }, [data, currentRange, filterBy]);

  const isDisabledRight = useMemo(() => {
    if (filterBy === FILTER_TYPE.BY_WEEK) {
      return (
        data[data.length - 1]?.date?.[1]?.valueOf() ===
        currentRange[1]?.valueOf()
      );
    } else {
      return (
        data[data.length - 1]?.date?.valueOf() === currentRange[1]?.valueOf()
      );
    }
  }, [data, currentRange, filterBy]);

  const handleDecreaseTimeline = () => {
    const timelineBeginning =
      filterBy === FILTER_TYPE.BY_WEEK
        ? moment(data[0]?.date?.[0])
        : moment(data[0]?.date);
    const newStart = moment(currentRange[0]).subtract(DAYS_CHANGE - 1, unit);
    if (newStart < timelineBeginning) {
      const newEnd = moment(timelineBeginning).add(DAYS_RANGE - 1, unit);
      setCurrentRange([timelineBeginning, newEnd]);
    } else {
      const newEnd = moment(newStart).add(DAYS_RANGE - 1, unit);
      setCurrentRange([newStart, newEnd]);
    }
  };

  const handleIncreaseTimeline = () => {
    const timelineEnding =
      filterBy === FILTER_TYPE.BY_WEEK
        ? moment(data[data.length - 1]?.date?.[1])
        : moment(data[data.length - 1]?.date);
    const newEnd = moment(currentRange[1]).add(DAYS_CHANGE - 1, unit);
    if (newEnd > timelineEnding) {
      if (filterBy === FILTER_TYPE.BY_WEEK) {
        const timelineEndingIndex = data.findIndex((item) =>
          moment(item.date?.[1]).isSame(timelineEnding, "d")
        );
        const newStartIndex = timelineEndingIndex - DAYS_RANGE - 1;
        setCurrentRange([data[newStartIndex]?.date?.[0], timelineEnding]);
        return;
      }
      const newStart = moment(timelineEnding).subtract(DAYS_RANGE - 1, unit);
      setCurrentRange([newStart, timelineEnding]);
    } else {
      const newStart = moment(newEnd).subtract(DAYS_RANGE - 1, unit);
      setCurrentRange([newStart, newEnd]);
    }
  };

  return (
    <div className="timeline-smart-view__container bg-white border-start w-100 border-top overflow-y">
      <SmartViewHeader
        timeRange={currentRange}
        currentData={currentData}
        disabledLeft={isDisabledLeft}
        disabledRight={isDisabledRight}
        onClickLeft={handleDecreaseTimeline}
        onClickRight={handleIncreaseTimeline}
        filterBy={filterBy}
        headerTitle={headerTitle}
      />
      {channels?.map((item, index) => {
        return (
          <SmartViewRow
            key={`${item?.name}`}
            dataSource={currentData}
            channel={item}
          />
        );
      })}
    </div>
  );
};

export default TimeLineSmartView;
